/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import { Popup } from 'react-map-gl'
import { connect, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Rnd } from 'react-rnd'
import { fromJS } from 'immutable'

// components
import TabsV2 from '../../components/TabsV2/TabsV2'
import TabViewSettings from './TabViewSettings/TabViewSettings'
import PopupHeader from './PopupHeader/PopupHeader'
import AttributeMeasureButtons from './AttributeMeasureButtons'
import PopupFooter from './PopupFooter/PopupFooter'

import DocumentAttributeTab from './TabTemplate/DocumentTab/DocumentAttributeTab'
import AttributeTab from './TabTemplate/AttributeTab/AttributeTab'

// utilities
import { apis } from '../../config/apiConfig'
import { apiFetch } from '../../utilities/api'
import MakeDataConfigComponent, {
  getCommonLayerConfigUsingLayerId,
  getLayerConfigUsingLayerId,
} from '../../utilities/dataConfig'
import { updateMapStyle, createPopup, setOverPanel } from '../../actions/index'
import AsyncFetch from '../../utilities/AsyncFetch'

// scss files
import scss from './MapPopup.scss'
import toolScss from '../Tools/Tools.scss'

const MapPopup = ({
  mapStyle,
  dataConfig,
  popupInfo,
  setClickedFeature,
  closePopup,
  disableMap,
  enableMap,
  componentOnTop,
  setComponentOnTop,
}) => {
  const [enlargeWindow, setEnlargeWindow] = useState(false)
  const [index, setIndex] = useState(0)
  const [dataConfigLayer, setDataConfigLayer] = useState({})
  const [edit, setEdit] = useState(false)
  const [featureProperties, setFeatureProperties] = useState(
    popupInfo.features[index].properties || null
  )
  const [openSettings] = useState(false)
  const [fetchingSettings] = useState(false)
  const [isDockLockActive, setIsDockActive] = useState(false)
  const popupSwitch = async i => {
    setIndex(i)
  }

  const [layerId, setLayerId] = useState(
    popupInfo.features[index].layer.id || 0
  )
  const [layerProperties, setLayerProperties] = useState({})

  const [isOnTool, setIsOnTool] = useState(false)
  const [isDragging, setIsDragging] = useState(false)

  const [popupSettingsUi] = useState()
  // eslint-disable-next-line no-use-before-define
  const [popupConfig, setPopupConfig] = useState(dataConfig)

  const [popupHeaderLabel, setPopupHeaderLabel] = useState()
  const [ui, setUi] = useState(null)

  // Attributes features and tabs
  const [attributesTab, setAttributesTabs] = useState([])
  const [featureAttributes, setFeatureAttributes] = useState([])
  // Document features and tabs
  const [documentTab, setDocumentTabs] = useState([])
  const [featureDocumentAttributes, setFeatureDocumentAttributes] = useState([])

  // Tabs
  // Document features and tabs
  const [tabs, setTabs] = useState([])

  /**
   * PAGINATION FOR BOTH PAGES
   */
  const [tabsPerPage, setTabsPerPage] = useState(4)
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  /**
   *  PAGINATION FOR ATTRIBUTES
   */
  const [attributeTabCurrentPageNumber, setAttributeTabCurrentPageNumber] =
    useState(1)
  const [totalAttributeTabPageNumber, setTotalAttributeTabPageNumber] =
    useState(1)

  /*
   * PAGINATION FOR DOCUMENTS
   */
  const [documentTabCurrentPageNumber, setDocumentTabCurrentPageNumber] =
    useState(1)
  const [totalDocumentTabPageNumber, setTotalDocumentTabPageNumber] =
    useState(1)

  const [displayPopupSettings, setDisplayPopUpSettings] = useState(false)
  const dispatch = useDispatch()

  // State of the tab
  const [urlObjects, setUrlObjects] = useState(null)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(null)

  // Detect opened tab
  const [isAttributeWindow, setIsAttributeWindow] = useState(true)
  const [isDocumentWindow, setIsDocumentWindow] = useState(false)

  const [isCommonLayer, setIsCommonLayer] = useState(false)

  useEffect(() => {
    if (
      popupInfo &&
      popupInfo.features[index] &&
      popupInfo.features[index].layer &&
      popupInfo.features[index].layer.metadata &&
      popupInfo.features[index].layer.metadata.commonLayer
    ) {
      setIsCommonLayer(true)
    }
  }, [popupInfo])

  const getLayerProperties = async () => {
    const layerPropertiesVal = {}
    if (layerId === 'geocode_results') {
      layerPropertiesVal[layerId] = [
        {
          key: 'location',
          name: 'location',
        },
      ]
      setLayerProperties(layerProperties)
    } else if (layerId === 'coordiantes_results') {
      layerPropertiesVal[layerId] = [
        {
          key: 'latitude',
          name: 'latitude',
          type: 'text',
        },
        {
          key: 'longitude',
          name: 'longitude',
          type: 'text',
        },
      ]
      setLayerProperties(layerProperties)
    } else if (layerId === 'geospatial_results_fill') {
      layerPropertiesVal[layerId] = [
        {
          key: 'area',
          name: 'area',
          type: 'text',
        },
      ]
      setLayerProperties(layerProperties)
    } else {
      if (layerPropertiesVal[layerId]) return

      const method = 'POST'
      const url = apis.apiDatabase.uri + 'layer/properties/get'
      const bodyParams = {
        layerID: layerId,
        unique: false,
      }
      try {
        await apiFetch(url, method, bodyParams, result => {
          layerPropertiesVal[layerId] = result.data
          setLayerProperties(layerPropertiesVal)
        })
      } catch (error) {
        console.log('MapPopup API CALL ERROR:', error)
      }
    }
  }
  const divideTabs = tabsValues => {
    const tabsVal = Object.values(tabsValues)

    setTabs(tabsValues)

    // To handle the backward compatible tabs, we group anything not assigned into normal attributes
    const myAttributeTab = tabsVal.filter(
      tab =>
        tab &&
        (!tab.category || tab.category.toLowerCase() === 'attributes') &&
        tab.title &&
        tab.title !== 'Unassigned'
    )

    // Only what has the documents category is added here
    const myDocumentTab = tabsVal.filter(
      tab => tab && tab.category && tab.category.toLowerCase() === 'documents'
    )

    setAttributesTabs(myAttributeTab)
    setDocumentTabs(myDocumentTab)
  }

  const getDataConfigLayer = () => {
    let dataConfigLayerVal
    if (
      popupInfo.features[index].layer.metadata &&
      popupInfo.features[index].layer.metadata.commonLayer
    ) {
      dataConfigLayerVal = getCommonLayerConfigUsingLayerId(dataConfig, layerId)
    } else {
      dataConfigLayerVal = getLayerConfigUsingLayerId(dataConfig, layerId)
    }

    setDataConfigLayer(dataConfigLayerVal)
  }

  const getPopupConfig = () => {
    // eslint-disable-next-line no-shadow
    let tabs
    let { properties } = popupInfo.features[index]
    let configLayer = null

    if (
      popupInfo.features[index].layer.metadata &&
      popupInfo.features[index].layer.metadata.commonLayer
    ) {
      configLayer = getCommonLayerConfigUsingLayerId(
        dataConfig,
        popupInfo.features[index].layer.id
      )
    } else {
      configLayer = getLayerConfigUsingLayerId(
        dataConfig,
        popupInfo.features[index].layer.id
      )
    }

    let defaultConfig = {}

    const { popup } = configLayer.layersArray[0].layer.metadata

    if (popup && popup.tabs) {
      ;({ tabs } = popup)
    }
    if (popup && popup.properties) {
      // eslint-disable-next-line prefer-destructuring
      properties = popup.properties // properties config are for common layers only
    }

    if (configLayer && configLayer.toc && configLayer.toc.type === 'user') {
      // if user layer use toc label

      defaultConfig = { label: configLayer.toc.label, tabs }
    } else if (popupInfo.features[index].layer.metadata.label) {
      defaultConfig = {
        label: popupInfo.features[index].layer.metadata.label,
        tabs,
        properties,
      }
    } else {
      defaultConfig = {
        label: popupInfo.features[index].layer.id,
        tabs,
        properties,
      }
    }

    return defaultConfig
  }

  const getTabs = () => {
    const newDataConfig = getPopupConfig()
    if (!newDataConfig || !newDataConfig.tabs) return

    const tabsValues = Object.values(newDataConfig.tabs)

    setTabs(tabsValues)

    divideTabs(tabsValues)
  }

  const makePopupEditable = () => {
    setOverPanel(true)
    setEdit(true)
  }

  const cancelEdits = () => {
    setOverPanel(false)
    setEdit(false)
  }

  const handleToolDragStart = () => {
    setIsDragging(true)
    if (!isOnTool) {
      disableMap()
    }
  }

  const handleToolDragStop = () => {
    setIsDragging(false)
    if (!isOnTool) {
      enableMap()
    }
  }

  const handleEnterTool = () => {
    setIsOnTool(true)
    if (!isDragging) {
      disableMap()
    }
  }

  const handleLeaveTool = () => {
    setIsOnTool(false)
    if (!isDragging) {
      enableMap()
    }
  }

  /**
   * Saves the feature properties as a whole
   */
  const saveEdits = () => {
    const featureId = popupInfo.features[index].properties.mamid

    const json = {
      type: 'Feature',
      properties: featureProperties,
      geometry: {},
    }
    const method = 'POST'
    const url = apis.apiDatabase.uri + 'layer/geojson/update'
    const body = {
      layerID: layerId,
      featureID: featureId,
      json,
    }
    setUrlObjects([{ url, body, method }])
  }

  const AttributeMeasureButtonsUI = () => {
    return (
      <AttributeMeasureButtons
        closePopup={closePopup}
        mamId={featureProperties.mamid}
        featureProperties={featureProperties}
        config={popupConfig}
        active
        layerId={layerId}
        layerProperties={layerProperties}
        popupInfo={popupInfo}
        index={index}
        dataConfigLayer={dataConfigLayer}
        fetchingSettings={fetchingSettings}
        edit={edit}
        makePopupEditable={makePopupEditable}
        cancelEdits={cancelEdits}
        openSettings={openSettings}
      />
    )
  }

  const handleDocumentLinkChanges = (typeDocumentKey, awsPath, fileName) => {
    const AllProperties = layerProperties[layerId]

    let newValue
    if (awsPath && fileName) {
      awsPath = awsPath.substr(0, awsPath.length - fileName.length)

      newValue = `${awsPath}|${fileName}`
    } else newValue = ''

    const documentChangeProperty = AllProperties.find(
      property => property.key === typeDocumentKey
    )

    documentChangeProperty.value = newValue

    featureProperties[documentChangeProperty.key] = documentChangeProperty.value
    setFeatureProperties(featureProperties)

    saveEdits()
  }

  const generateUi = () => {
    return (
      <div
        className={[
          isDockLockActive ? scss['feature-docked'] + ' feature-docked' : '',
          enlargeWindow ? scss.enlargeWindowStyle : '',
          scss.toolExpanded,
          scss.toolHeader,
          'handle',
        ].join('')}
      >
        {popupSettingsUi}
        <Popup
          tipSize={5}
          anchor='top'
          longitude={popupInfo.lngLat[0]}
          latitude={popupInfo.lngLat[1]}
          captureScroll
          captureClick
          closeOnClick={false}
          closeButton={false}
          className={[
            scss['feature-container'],
            enlargeWindow ? scss.enlargeWindowStyle : '',
          ].join(' ')}
          onMouseEnter={handleEnterTool}
          onMouseLeave={handleLeaveTool}
        >
          <PopupHeader
            closePopup={closePopup}
            mamId={featureProperties.mamid}
            featureProperties={featureProperties}
            label={popupHeaderLabel}
            active={isDockLockActive}
            setActive={setIsDockActive}
            layerId={layerId}
            layerProperties={layerProperties}
            popupInfo={popupInfo}
            index={index}
            fetchingSettings={fetchingSettings}
            edit={edit}
            popupSwitch={popupSwitch}
            makePopupEditable={makePopupEditable}
            cancelEdits={cancelEdits}
            openSettings={openSettings}
            enlargeWindow={enlargeWindow}
            setEnlargeWindow={setEnlargeWindow}
          />

          {/* LEFT TAB CONTROLLER */}
          <div
            className={[
              scss['feature-tabs'],
              enlargeWindow ? scss.enlargeWindowStyle : '',
            ].join(' ')}
          >
            <TabsV2
              customClass='leftTabs'
              setIsAttributeWindow={setIsAttributeWindow}
              setIsDocumentWindow={setIsDocumentWindow}
            >
              <div
                icon='poll-h'
                label='Attributes'
                style={{ height: 'fit-content' }}
              >
                <div className={scss['feature-tabs-header']}>
                  <span>Feature Attributes</span>
                  {AttributeMeasureButtonsUI()}
                </div>
                <AttributeTab
                  tabs={attributesTab}
                  tabAttribute={featureAttributes}
                  tabsPerPage={tabsPerPage}
                  currentPage={attributeTabCurrentPageNumber}
                  activeTabIndex={activeTabIndex}
                  setActiveTabIndex={setActiveTabIndex}
                  layerId={layerId}
                  // eslint-disable-next-line no-use-before-define
                  handleAllChanges={saveEdits}
                  featureProperties={featureProperties}
                  setFeatureProperties={setFeatureProperties}
                  enlargeWindow={enlargeWindow}
                  setTotalAttributeTabPageNumber={
                    setTotalAttributeTabPageNumber
                  }
                  isCommonLayer={isCommonLayer}
                />
                <PopupFooter
                  totalTabPageNumber={totalAttributeTabPageNumber}
                  tabsPerPage={tabsPerPage}
                  currentPage={attributeTabCurrentPageNumber}
                  setCurrentPage={setAttributeTabCurrentPageNumber}
                  setActiveTabIndex={setActiveTabIndex}
                  setDisplayPopUpSettings={setDisplayPopUpSettings}
                  isCommonLayer={isCommonLayer}
                />
              </div>
              {isCommonLayer ? (
                ''
              ) : (
                <div icon='file' label='Documents'>
                  <div className={scss['feature-tabs-header']}>
                    <span>Document Attributes</span>
                    {AttributeMeasureButtonsUI()}
                  </div>
                  <DocumentAttributeTab
                    tabs={documentTab}
                    tabAttribute={featureDocumentAttributes}
                    tabsPerPage={tabsPerPage}
                    currentPage={documentTabCurrentPageNumber}
                    activeTabIndex={activeTabIndex}
                    setActiveTabIndex={setActiveTabIndex}
                    layerId={layerId}
                    // eslint-disable-next-line no-use-before-define
                    handleAllChanges={handleDocumentLinkChanges}
                    featureProperties={featureProperties}
                    setFeatureProperties={setFeatureProperties}
                    enlargeWindow={enlargeWindow}
                  />
                  <PopupFooter
                    totalTabPageNumber={totalDocumentTabPageNumber}
                    tabsPerPage={tabsPerPage}
                    currentPage={documentTabCurrentPageNumber}
                    setCurrentPage={setDocumentTabCurrentPageNumber}
                    setActiveTabIndex={setActiveTabIndex}
                    setDisplayPopUpSettings={setDisplayPopUpSettings}
                    isCommonLayer={isCommonLayer}
                  />
                </div>
              )}
            </TabsV2>
          </div>
        </Popup>
        {displayPopupSettings && popupConfig && layerProperties && layerId && (
          <TabViewSettings
            onClose={() => {
              setDisplayPopUpSettings(false)
            }}
            layerProperties={layerProperties[layerId]}
            layerId={layerId}
            mapStyle={mapStyle}
            config={getPopupConfig()}
            featureProperties={featureProperties}
            tabs={tabs}
            isDocumentWindow={isDocumentWindow}
            isAttributeWindow={isAttributeWindow}
            featureDocumentAttributes={featureDocumentAttributes}
            featureAttributes={featureAttributes}
            mapviewSetTabs={setTabs}
            updateDataConfig={() => {
              setMountMakeDataConfig(true)
            }}
          />
        )}
      </div>
    )
  }

  useEffect(() => {
    const layerIdVal = popupInfo.features[index].layer.id
    setLayerId(layerIdVal)
    setClickedFeature(popupInfo.features[index])
  }, [index])

  useEffect(() => {
    getDataConfigLayer()
    const initialFetch = async () => {
      setPopupHeaderLabel(getPopupConfig().label || 'N/A')
      await getLayerProperties()
      setFeatureProperties(popupInfo.features[index].properties)
    }
    initialFetch()
  }, [index, layerId])

  useEffect(() => {
    setMountMakeDataConfig(false)
  }, [mountMakeDataConfig])

  /**
   * We divide the properties as soon as the await function above finishes loading
   */
  useEffect(() => {
    if (layerId && layerProperties && layerProperties[layerId]) {
      let myDocumentAttributesOnly = layerProperties[layerId]
      let myNormalAttributesOnly = layerProperties[layerId]
      if (Array.isArray(myDocumentAttributesOnly)) {
        myDocumentAttributesOnly = myDocumentAttributesOnly.filter(
          item => item.type === 'document (Feature)'
        )
        if (myDocumentAttributesOnly && myDocumentAttributesOnly.length > 0) {
          setFeatureDocumentAttributes(myDocumentAttributesOnly)
        } else {
          setFeatureDocumentAttributes([])
        }
      }

      if (Array.isArray(myNormalAttributesOnly)) {
        myNormalAttributesOnly = myNormalAttributesOnly.filter(
          item =>
            item.type !== 'document (Feature)' &&
            item.type !== 'document (Layer)'
        )
        if (myNormalAttributesOnly && myNormalAttributesOnly.length > 0) {
          setFeatureAttributes(myNormalAttributesOnly)
        } else {
          setFeatureAttributes([])
        }
      }
    }
  }, [layerId, layerProperties])

  useEffect(() => {
    if (enlargeWindow) setTabsPerPage(8)
    else setTabsPerPage(4)
  }, [enlargeWindow])

  useEffect(() => {
    setUi(generateUi())
  }, [
    isDockLockActive,
    attributeTabCurrentPageNumber,
    documentTabCurrentPageNumber,
    activeTabIndex,
    displayPopupSettings,
    enlargeWindow,
    featureProperties,
    urlObjects,
    featureAttributes,
    featureDocumentAttributes,
  ])
  /**
   * dataConfigLayer is the last one to load, so we generate UI here.
   */
  useEffect(() => {
    if (
      dataConfigLayer &&
      dataConfigLayer.toc &&
      dataConfigLayer.toc.userLayer &&
      featureProperties
    ) {
      getTabs()
      setUi(generateUi())
    }
  }, [
    dataConfigLayer,
    featureAttributes,
    featureDocumentAttributes,
    tabsPerPage,
  ])

  const saveFinished = () => {
    const style = mapStyle.toJS()
    const sourceId = style.layers
      .filter(layer => layer.id === layerId)
      .map(layer => layer.source)
    setUrlObjects(null)
    style.sources[sourceId[0]].tiles = style.sources[sourceId[0]].tiles.map(
      tileUrl => {
        if (tileUrl.indexOf('?') === -1) {
          return tileUrl + `?dt=${Date.now()}`
        }
        const url = tileUrl.replace(/&dt.*$/, '')
        return url + `&dt=${Date.now()}`
      }
    )
    dispatch(updateMapStyle(fromJS(style)))
  }

  const fetchFinished = () => {
    saveFinished()
  }

  const dataConfigUpdated = () => {
    const newDataConfig = getPopupConfig()
    if (!newDataConfig || !newDataConfig.tabs) return
    const tabsValues = Object.values(newDataConfig.tabs)

    divideTabs(tabsValues)

    getLayerProperties()
    setFeatureProperties(popupInfo.features[index].properties)
  }

  useEffect(() => {
    if (tabsPerPage && tabsPerPage > 0) {
      if (attributesTab && attributesTab.length)
        setTotalAttributeTabPageNumber(
          Math.ceil(attributesTab.length / tabsPerPage)
        )

      if (documentTab && documentTab.length) {
        setTotalDocumentTabPageNumber(
          Math.ceil(documentTab.length / tabsPerPage)
        )
      }
    }
  }, [index])

  return (
    <>
      {urlObjects && (
        <AsyncFetch fetchObjects={urlObjects} fetchFinished={fetchFinished} />
      )}
      {mountMakeDataConfig && (
        <MakeDataConfigComponent onFinish={dataConfigUpdated} />
      )}
      {isDockLockActive ? (
        <Rnd
          className={[
            toolScss.panelDraggableContainer,
            scss['feature-draggable'],
          ].join(' ')}
          key={featureProperties.mamid + '-feature-draggable'}
          style={{
            zIndex: componentOnTop === 'feature-popup' ? 1000 : 2,
          }}
          dragHandleClassName='handle'
          bounds='#mapContainer'
          scale={1}
          onClick={() => setComponentOnTop('feature-popup')}
          onDragStart={handleToolDragStart}
          onResizeStart={handleToolDragStart}
          onDrag={() => {}}
          onDragStop={handleToolDragStop}
          onResizeStop={handleToolDragStop}
          default={{
            x: 10,
            y: 10,
            height: 375,
            width: 400,
          }}
          minHeight={375}
          minWidth={400}
        >
          <div
            className={[
              isDockLockActive
                ? scss['feature-docked'] + ' feature-docked'
                : '',
              enlargeWindow ? scss.enlargeWindowStyle : '',
            ].join(' ')}
          >
            {ui}
          </div>
        </Rnd>
      ) : (
        ui
      )}
    </>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMapStyle,
      setOverPanel,
      createPopup,
    },
    dispatch
  )
}

function mapStateToProps(state) {
  return {
    user: state.user,
    viewport: state.viewport,
    mapStyle: state.mapStyle,
    toolConfig: state.toolConfig,
    doubleClickZoom: state.doubleClickZoom,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MapPopup)
