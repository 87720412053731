import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ParentGroupSelect from '../../ParentGroupSelect/ParentGroupSelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AsyncFetch from '../../../../utilities/AsyncFetch'
import { apis } from '../../../../config/apiConfig'

import MakeDataConfigComponent from '../../../../utilities/dataConfig'
import ShareModal from '../../ShareModal/ShareModal'

import toolScss from '../../../../_tools.scss'
import appScss from '../../../App/App.scss'

import { getLayerConfigUsingTocId } from '../../../../utilities/dataConfig'

import organizationLayerGroup from '../../../../assets/icons/organizationLayerGroup.svg'
import sharedLayerGroup from '../../../../assets/icons/sharedLayerGroup.svg'
import userLayerGroup from '../../../../assets/icons/userLayerGroup.svg'
import logoSimplified from '../../../../assets/logo/logo-simplified.svg'

import GroupDelete from './GroupDelete'
import { Tooltip } from 'react-tooltip'

export default function GroupInfo({ closeTool, config, mountKey, dataConfig }) {
  const toolConfig = useSelector(state => state.toolConfig)
  const dispatch = useDispatch()

  const groupId = config.group.id
  const sharedType = config.group.type
  const canEdit = config.group.canEdit
  const canDelete = config.group.canDelete

  const [parentGroupSelectKey, setParentGroupSelectKey] = useState(1)

  const label = config.group.label || 'No Label Available'
  const id = config.group.groupId

  const [fetchObjects, setFetchObjects] = useState(null)
  const [groupDeleteVisible, setGroupDeleteVisible] = useState(false)
  const [groupLabel, setLayerLabel] = useState(config.group.label)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)
  const [groupDescription, setLayerDescription] = useState(
    config.group.description
  )
  const [shareGroupVisible, setShareGroup] = useState(false)

  const openGroupDelete = layerDeleteVisible =>
    setGroupDeleteVisible(layerDeleteVisible)

  const openShareGroup = shareGroupVisible => setShareGroup(shareGroupVisible)

  const buildFetchParams = id => {
    const groupId = config.group.id
    let method
    let url
    let body = {}

    if (id === 'archive') {
      method = 'POST'
      url = apis['apiDatabase'].uri + 'layers/user/delete'
      body = {
        layerID: groupId,
      }
    } else if (id === 'save') {
      method = 'POST'
      url = apis['apiDatabase'].uri + 'layer/group/update'
      body = {
        groupID: groupId,
        label: groupLabel,
        description: groupDescription,
      }
    }

    setFetchObjects([{ url, method, body }])
  }

  const fetchFinished = result => {
    // archive response
    setMountMakeDataConfig(true)
    closeTool(config.name)
    setFetchObjects(null)
  }

  const doArchive = () => {
    buildFetchParams('archive')
  }

  const handleOnClose = () => {
    closeTool()
    setGroupDeleteVisible(false)
  }

  const handleOnFinish = dataConfig => {
    updateToolConfig(dataConfig)
  }

  let groupStyle = { width: '20px' }
  let groupShare = ''

  if (sharedType === 'user') {
    groupShare = userLayerGroup
    groupStyle = { width: '20px', marginLeft: '-6px' }
  } else if (sharedType === 'shared') {
    groupShare = sharedLayerGroup
  } else if (sharedType === 'org') {
    groupShare = organizationLayerGroup
    groupStyle = { width: '20px', marginLeft: '-3px' }
  } else if (sharedType === 'mam') {
    groupShare = logoSimplified
    groupStyle = { width: '20px', marginBottom: '5px' }
  } else {
    groupShare = logoSimplified
    groupStyle = { width: '20px', marginBottom: '5px' }
  }

  let groupIdForDisplay = groupId.split('_')
  groupIdForDisplay = groupIdForDisplay[groupIdForDisplay.length - 1]

  const updateToolConfig = dataConfig => {
    // the data for the layer has changed (a new description or layer name)
    // the purpose of this function is to update app state data config with the new layer information
    // and remount the component to preserve the changes
    const updatedLayer =
      config && getLayerConfigUsingTocId(dataConfig, config.group.id)

    // add the newly updated layer to the toolConfig
    if (updatedLayer) {
      toolConfig.forEach(config => {
        if (config.name === 'LayerSettings') {
          config.layer = updatedLayer
        }
      })
      dispatch(setToolConfig(toolConfig))
      // force the component to remount by updating the mount key
      mountKey++
      dispatch(mountLayerSettings(mountKey))
    }
  }

  useEffect(
    function () {
      setMountMakeDataConfig(false)
    },
    [mountMakeDataConfig]
  )

  useEffect(() => {
    // group has changed, update parentGroupSelectKey to force remount
    setParentGroupSelectKey(parentGroupSelectKey + 1)
  }, [config.group.groupId])

  const getGroupInfo = () => {
    const parentGroupSelect = getParentGroupSelect()

    return (
      <>
        {fetchObjects && (
          <AsyncFetch
            fetchObjects={fetchObjects}
            fetchFinished={fetchFinished}
          />
        )}
        {mountMakeDataConfig && (
          <MakeDataConfigComponent onFinish={handleOnFinish} />
        )}
        {groupDeleteVisible && (
          <GroupDelete
            onClose={handleOnClose}
            groupLabel={label}
            groupId={id}
            groupConfig={config}
          />
        )}
        {shareGroupVisible && (
          <ShareModal
            onClose={() => openShareGroup(false)}
            itemId={id}
            dataType={'group'}
            dataConfig={dataConfig}
          />
        )}

        <div className={toolScss.toolIcons}>
          <div className={toolScss.toolIconLeftRow}>
            {canDelete && (
              <div
                className={[toolScss.toolIconBox, toolScss.toolTrashIcon].join(
                  ' '
                )}
                onClick={() => openGroupDelete(true)}
                data-tooltip-content='Delete'
                data-tooltip-id='delete'
                data-effect='solid'
                data-arrow-color='rgba(0, 0, 0, 0.68)'
              >
                <FontAwesomeIcon icon={['fas', 'trash']} size='1x' />
                <div className={appScss.reactToolTip}>
                  <Tooltip id='delete' getContent={dataTip => `${dataTip}`} />
                </div>
              </div>
            )}
            <div
              className={[toolScss.toolIconBox, toolScss.toolArchiveIcon].join(
                ' '
              )}
              onClick={() => doArchive()}
              data-tooltip-content='Archive'
              data-tooltip-id='archive'
              data-effect='solid'
              data-arrow-color='rgba(0, 0, 0, 0.68)'
            >
              <FontAwesomeIcon icon={['fas', 'archive']} size='1x' />
              <div className={appScss.reactToolTip}>
                <Tooltip id='archive' getContent={dataTip => `${dataTip}`} />
              </div>
            </div>
          </div>

          <div className={toolScss.toolIconRightRow}>
            <div
              className={[
                toolScss.toolIconBox,
                toolScss.toolCloneIcon,
                toolScss[!canEdit ? 'inactive' : ''],
              ].join(' ')}
              onClick={canEdit ? () => openShareGroup(true) : null}
              data-tooltip-content='Share'
              data-tooltip-id='share'
              data-effect='solid'
              data-arrow-color='rgba(0, 0, 0, 0.68)'
            >
              <FontAwesomeIcon icon={['fa', 'share-alt']} size='1x' />
              <div className={appScss.reactToolTip}>
                <Tooltip id='share' getContent={dataTip => `${dataTip}`} />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const getParentGroupSelect = () => {
    return <ParentGroupSelect key={parentGroupSelectKey} config={config} />
  }

  let ui = getGroupInfo()
  return ui
}
