import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import scss from '../ProfileStyling/ProfileModal.scss'
import modalScss from '../../CSSModules/Modal.scss'
import appScss from '../../App/App.scss'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

import AsyncFetch from '../../../utilities/AsyncFetch'
import { refreshDetails } from '../../../utilities/user'
import * as utils from '../../../utilities/util'

import { Alert } from '../../../actions'

import { apis } from '../../../config/apiConfig'
import {
  MuiFormControlTextMultiline,
  MuiFormControlText,
  MuiButtonGroup,
} from '../../../components/MaterialUi/Tool'

import { MuiButton } from '../../../components/MaterialUi/Dialog'

import { loadStripe } from '@stripe/stripe-js'
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { getStripePlanPrice } from '../../../utilities/util'

const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh')

export const CheckoutForm = () => {
  const stripeHook = useStripe()
  const elements = useElements()

  const handleSubmit = async event => {
    // Block native form submission.
    event.preventDefault()

    if (!stripeHook || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement)

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripeHook.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })

    // TODO: Do something with the data here...
  }

  return (
    <form onSubmit={handleSubmit}>
      <CardElement
        options={{
          style: {
            base: {
              fontFamily: '"Montserrat", "Gilroy-Light", sans-serif',
              fontSize: '16px',
              color: '#1a2937',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#9e2146',
            },
          },
        }}
      >
        <link
          href='https://fonts.googleapis.com/css2?family=Montserrat&display=swap'
          rel='stylesheet'
        ></link>
      </CardElement>
      <button
        className={appScss.altBlueButton}
        type='submit'
        disabled={!stripeHook}
      >
        Pay
      </button>
    </form>
  )
}

function SimpleDialog({
  customerID,
  subscription,
  profilePlan,
  visible,
  organizationImg,
  organizationName,
  updateProfile,
}) {
  const [open, setOpen] = useState(true)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [fetching, setFetching] = useState(false)
  const [updateFetching, setUpdateFetching] = useState(false)
  const [updateFetchObjects, setUpdateFetchObjects] = useState(null)

  //Save Button Enable
  const [enableSave, setEnableSave] = useState(false)

  //Fields and their States
  const [plan, setPlan] = useState(profilePlan)
  const [currentPlanPrice, setCurrentPlanPrice] = useState(
    plan.tiers
      ? getStripePlanPrice(plan, plan.quantity).toFixed(2)
      : parseFloat(((plan.amount / 100) * plan.quantity).toFixed(2))
  )
  const [planUnitPrice, setPlanUnitPrice] = useState(null)
  const [userInputValue, setUserInputValue] = useState(plan.quantity)
  const [checkoutFormVisible, setCheckoutFormVisible] = useState(false)
  const [actionsVisible, setActionsVisible] = useState(true)

  const [planSubscription, setPlanSubscription] = useState(null)

  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  // const buildFetchParams = () => {
  //   const method = 'POST'
  //   let url = apis['apiDatabase'].uri + 'user/update'
  //   let body = {
  //     plan: planState,
  //   }

  //   setFetchObjects([{ url, method, body }])
  //   setFetching(true)
  // }

  const fetchFinished = result => {
    refreshDetails(user, newUser => {
      dispatch({ type: 'REFRESH', payload: newUser })
      setFetching(false)
      handleOnClose()
    })

    setFetchObjects(null)
  }

  const handleSave = () => {
    buildFetchParams()
  }

  const onPlanChange = e => {
    setFirstName(e.target.value)
    setEnableSave(true)
  }

  const handleCancel = () => {
    setOpen(false)
    visible(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    visible(false)
  }

  const handleClick = () => {
    const url = apis.apiDatabase.uri + 'stripe/subscription/update'
    const method = 'POST'
    const body = {
      subscriptionID: subscription.id,
      items: [
        {
          id: planSubscription.id,
          quantity: userInputValue,
        },
      ],
    }

    setUpdateFetching(true)
    setUpdateFetchObjects([{ url, method, body }])
  }

  const updateFetchFinished = results => {
    results.forEach(res => {
      if (utils.verifyResult(res)) {
        console.log('res', res)
        updateProfile(res.data)
      } else {
        if (res.data && res.data.raw.message) {
          dispatch(Alert({ error: res.data.raw.message }))
        }
      }
    })
    setUpdateFetchObjects(null)
    setUpdateFetching(false)
    handleOnClose()
  }

  const handlePlanPricing = (unit = false) => {
    if (plan.tiers) {
      if (unit) {
        return getStripePlanPrice(plan, userInputValue, true)
      } else {
        return getStripePlanPrice(plan, userInputValue)
      }
    } else {
      let result
      if (unit) {
        result = parseFloat((plan.amount / 100).toFixed(2))
      } else {
        result = parseFloat(((plan.amount / 100) * userInputValue).toFixed(2))
      }
      return result
    }
  }

  useEffect(() => {
    const result = handlePlanPricing(true)
    setPlanUnitPrice(result.toFixed(2))
  }, [userInputValue])

  useEffect(() => {
    const targetSub = subscription.items.data.filter(subObj => {
      if (subObj.name.includes('MyAssetMap')) return true
    })
    setPlanSubscription(targetSub[0])
    console.log('planSubscription', targetSub[0])
  }, [])

  const proposedDifference = (userInputValue - plan.quantity).toFixed(2)
  const proposedTotal = handlePlanPricing()
  const proposedPrice = (proposedTotal - currentPlanPrice).toFixed(2)

  return (
    <ThemeProvider theme={mainModalTheme}>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      {updateFetchObjects && (
        <AsyncFetch
          fetchObjects={updateFetchObjects}
          fetchFinished={updateFetchFinished}
        />
      )}
      <Elements stripe={stripePromise}>
        <Dialog
          onClose={handleOnClose}
          aria-labelledby='profile-plan-dialog'
          open={open}
          className={modalScss.lightModal}
          maxWidth='lg'
        >
          <DialogTitle id='export-to-csv'>
            Change plan/number of users{' '}
            <FontAwesomeIcon
              onClick={handleOnClose}
              icon='times'
              size='sm'
              pull='right'
            />
          </DialogTitle>
          <DialogContent className={modalScss.MuiDialogBoxes}>
            <div
              className={[scss.planRow, scss.planJustify, scss.planHeader].join(
                ' '
              )}
            >
              <div className={scss.planRow}>
                <div className={scss.planImage}>
                  <img src={organizationImg}></img>
                </div>
                <div className={scss.planColumn}>
                  <span className={scss.planHeading}>{organizationName}</span>
                  <span className={scss.planSubHeading}>
                    Currently at {plan.quantity} users
                  </span>
                </div>
                <input
                  type='number'
                  min={1}
                  style={{ marginLeft: '72px' }}
                  value={userInputValue}
                  onChange={e => setUserInputValue(e.target.value)}
                  className={scss.planInput}
                />
              </div>

              <div className={scss.planColumn}>
                <span className={scss.planHeading}>${planUnitPrice}</span>
                <span className={scss.planSubHeading}>
                  per user/{plan.interval}
                </span>
              </div>
            </div>
            <div
              className={[
                scss.planJustify,
                scss.planRow,
                scss.planBodyOne,
              ].join(' ')}
            >
              <span className={scss.planHeading}>
                Current Total Per {plan.interval === 'month' ? 'Month' : 'Year'}
              </span>
              <span className={scss.planHeading}>${currentPlanPrice}</span>
            </div>
            <div
              className={[
                scss.planJustify,
                scss.planRow,
                scss.planBodyTwo,
              ].join(' ')}
            >
              <span className={scss.planHeading}>
                {Math.abs(proposedDifference)}{' '}
                {proposedDifference >= 0 ? 'Additional' : 'Less'}{' '}
                {proposedDifference > 1 || proposedDifference < -1
                  ? 'Users'
                  : 'User'}
              </span>
              <span className={scss.planHeading}>
                {proposedPrice > 0
                  ? `+ $${proposedPrice}`
                  : `- $${Math.abs(proposedPrice)}`}
              </span>
            </div>
            <div
              className={[
                scss.planJustify,
                scss.planRow,
                scss.planBodyThree,
              ].join(' ')}
            >
              <span className={scss.planHeading}>
                New Total Per {plan.interval === 'month' ? 'Month' : 'Year'}
              </span>
              <span className={scss.planHeading}>
                ${proposedTotal.toFixed(2)}
              </span>
            </div>
            {checkoutFormVisible && (
              <div className={scss.planPaymentInfo}>
                <label>Payment Method</label>
                <CheckoutForm />
              </div>
            )}
          </DialogContent>
          <DialogActions>
            {actionsVisible && (
              <>
                <button
                  className={appScss.altBlueButton}
                  type='button'
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  className={appScss.altBlueButton}
                  type='button'
                  onClick={handleClick}
                >
                  Accept
                </button>
              </>
            )}
          </DialogActions>
        </Dialog>
      </Elements>
    </ThemeProvider>
  )
}

export default function ProfilePlan(props) {
  return <SimpleDialog {...props} />
}
