import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setToolConfig,
  setDragPan,
  setDoubleClickZoom,
  setScrollZoom,
  mountLayerSettings,
  setOverPanel,
} from '../../actions/index'

const CloseTool = ({ toolName }) => {
  const dispatch = useDispatch()
  const toolConfig = useSelector(state => state.toolConfig)

  const updateToolConfig = useCallback(() => {
    if (!toolConfig) return

    requestAnimationFrame(() => {
      const updatedToolConfig = toolConfig.map(tool => 
        tool.name === toolName 
          ? { ...tool, visible: false, layer: null }
          : tool
      )

      dispatch(setToolConfig(updatedToolConfig))
      dispatch(mountLayerSettings(null))
      dispatch(setDragPan(true))
      dispatch(setDoubleClickZoom(true))
      dispatch(setScrollZoom(true))
      dispatch(setOverPanel(false))
    })
  }, [dispatch, toolName, toolConfig])

  useEffect(() => {
    updateToolConfig()
  }, [updateToolConfig])

  return null
}

export default CloseTool
