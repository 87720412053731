import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { clone } from '../../../../../utilities/geospatial'
import { StyleInput } from '../StyleInput/StyleInput.jsx'
import { getDefaultLevelPaintObj } from '../LayerStyle.jsx'
import StyleByAttribute from './StyleByAttribute'
import appScss from '../../../../App/App.scss'
import scss from './DataLevels.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'react-tooltip'
import Switch from '@material-ui/core/Switch'
import { getLayerStyleLayerObject } from '../../../../../utilities/mapStyle'
import UseDataDrivenStylesMapPopup from './UseDataDrivenStylesConfirmationPopup/UseDataDrivenStylesConfirmationPopup'

const DataLevels = ({
  layerId,
  layerPaintProps,
  outLinePaintProps,
  layerType,
  updateLayer,
  chainedUpdate,
}) => {
  const mapStyle = useSelector(state => state.mapStyle)
  const [isCommonLayer, setIsCommonLayer] = useState(false)
  const [defaultPaint, setDefaultPaint] = useState(null)
  const [mounted, setMounted] = useState(false)
  const [useDataDrivenStyle, setUseDataDrivenStyle] = useState(false)
  const [mountKey, setMountKey] = useState(1)
  const [reset, setReset] = useState(false)
  const [
    displayUseDataDrivenStylesMapPopup,
    setDisplayUseDataDrivenStylesMapPopup,
  ] = useState(false)

  const handleUpdateDefaults = (targetProp, targetValue, id, level) => {
    let defaultObject = clone(
      getDefaultLevelPaintObj(layerPaintProps, outLinePaintProps)
    )

    if (defaultObject.paint[targetProp] !== targetValue) {
      let arr

      defaultObject.paint[targetProp] = targetValue

      let paintProps = {
        ...layerPaintProps,
        ...outLinePaintProps,
      }

      for (const [key, val] of Object.entries(paintProps)) {
        if (key === targetProp) {
          if (Array.isArray(val) && val[0] === 'case') {
            arr = val

            arr[arr.length - 1] = targetValue
            updateLayer(layerId, targetProp, arr, null)
          } else {
            updateLayer(layerId, targetProp, targetValue, null)
          }
        }
      }
    }

    setMountKey(mountKey + 1)
  }

  const handleLayerHardReset = () => {
    let key = mountKey

    const defaultObj = getDefaultLevelPaintObj(
      layerPaintProps,
      outLinePaintProps
    )
    const paintProps = clone(layerPaintProps)

    for (let [key, val] of Object.entries(paintProps)) {
      if (Array.isArray(val)) {
        paintProps[key] = defaultObj.paint[key]
      }
    }
    if (outLinePaintProps) {
      for (let [key, val] of Object.entries(outLinePaintProps)) {
        if (Array.isArray(val)) {
          outLinePaintProps[key] = defaultObj.outlinePaint[key]
        }
      }
    }

    setMountKey(key + 1)
    setReset(true)
    updateLayer(layerId, null, null, paintProps, outLinePaintProps)
  }

  const handleToggleResetStyle = () => {
    if (useDataDrivenStyle) {
      setDisplayUseDataDrivenStylesMapPopup(true)
    }
    setUseDataDrivenStyle(true)
  }

  const handleUpdate = (layerId, prop, value, level, id) => {
    if (level === 'default') {
      handleUpdateDefaults(prop, value, id, level)
    }
  }

  useEffect(() => {
    if (reset) {
      setReset(false)
    }
  }, [reset])

  useEffect(() => {
    const paint = {
      ...layerPaintProps,
      ...outLinePaintProps,
    }

    const style = mapStyle.toJS()
    const layerObject = getLayerStyleLayerObject(style, layerId)
    let commonLayer = false
    if (layerObject)
      layerObject.forEach(obj => {
        if (obj.metadata.commonLayer) {
          commonLayer = true
        }
      })
    setIsCommonLayer(commonLayer)
    let dataDriven = false
    /*
    On Mount check the incoming paint props
    If any of the props are an Array, Data Driven Styling is being used
    Set useDataDrivenStyles 
    */
    for (const key in paint) {
      if (Array.isArray(paint[key])) {
        dataDriven = true
      }
    }
    setUseDataDrivenStyle(dataDriven)
    /*
    Set Default Paint Properties to be used for Fallback values in Expressions
    */

    const defaultPaint = {}
    for (const prop in paint) {
      if (Array.isArray(paint[prop])) {
        const fallback = paint[prop][paint[prop].length - 1]
        defaultPaint[prop] = fallback
      } else {
        defaultPaint[prop] = paint[prop]
      }
    }

    setDefaultPaint(defaultPaint)
    setMounted(true)
  }, [])

  useEffect(() => {
    if (!mounted) return
    if (!useDataDrivenStyle) {
      // check if defaultPaint contains fill and line props
      // if it does breakt these out
      let fillCheck = null
      let lineCheck = null
      for (const prop in defaultPaint) {
        const propType = prop.split('-').shift()
        if (propType === 'fill') {
          if (!fillCheck) fillCheck = {}
          fillCheck[prop] = defaultPaint[prop]
        }
        if (propType === 'line') {
          if (!lineCheck) lineCheck = {}
          lineCheck[prop] = defaultPaint[prop]
        }
      }

      if (fillCheck && lineCheck) {
        updateLayer(layerId, null, null, fillCheck, lineCheck)
      } else {
        updateLayer(layerId, null, null, defaultPaint)
      }
    }
  }, [useDataDrivenStyle])

  const getUILogic = () => {
    const paintProps = clone(layerPaintProps)
    const defObj = clone(getDefaultLevelPaintObj(paintProps, outLinePaintProps))

    return (
      <>
        {displayUseDataDrivenStylesMapPopup ? (
          <UseDataDrivenStylesMapPopup
            setUseDataDrivenStyle={setUseDataDrivenStyle}
            setDisplayUseDataDrivenStylesMapPopup={
              setDisplayUseDataDrivenStylesMapPopup
            }
          />
        ) : null}
        <div>
          <div>
            {layerId.length > 4 &&
            layerId.substring(layerId.length - 4) !== '1062' ? (
              <>
                <label className={scss.attributeDefault}>Default Style:</label>
                <StyleInput
                  layerId={layerId}
                  layerPaintProps={defObj.paint}
                  outLinePaintProps={defObj.outlinePaint}
                  type={layerType}
                  level={'default'}
                  updateLayer={handleUpdate}
                  defaultPaint={defaultPaint}
                  setDefaultPaint={setDefaultPaint}
                />
                <div
                  className={[
                    appScss.reactToolTip,
                    appScss.reactToolTipFont,
                  ].join(' ')}
                >
                  <Tooltip
                    id='numberInput'
                    getContent={dataTip => `${dataTip}`}
                  />
                </div>
              </>
            ) : null}
          </div>
          {!isCommonLayer && (
            <div
              className={[scss.attributeDataTitle, scss.attributeJustify].join(
                ' '
              )}
            >
              <div>
                <label>Use Data-Driven Styles</label>
                <FontAwesomeIcon
                  icon='question-circle'
                  size='1x'
                  data-tooltip-content='This allows for you to style your data based on the values of the attributes on each individual feature.'
                  data-tooltip-id='data-driven'
                  data-effect='solid'
                  data-place='right'
                  data-arrow-color='rgba(0, 0, 0, 0.68)'
                />
                <div className={appScss.reactToolTip}>
                  <Tooltip
                    id='data-driven'
                    getContent={dataTip => `${dataTip}`}
                  />
                </div>
              </div>
              <button
                className={scss.dataDrivenToggle}
                onClick={handleToggleResetStyle}
              >
                <Switch
                  checked={useDataDrivenStyle}
                  name='dataDriveStyleSwitch'
                  inputProps={{
                    'aria-label': 'checkbox with default color',
                  }}
                />
              </button>
            </div>
          )}
          {!isCommonLayer && useDataDrivenStyle ? (
            <StyleByAttribute
              layerId={layerId}
              layerType={layerType}
              defObj={defObj}
              updateLayer={updateLayer}
              chainedUpdate={chainedUpdate}
              layerPaintProps={layerPaintProps}
              outLinePaintProps={outLinePaintProps}
              defaultPaint={defaultPaint}
            />
          ) : null}
        </div>
      </>
    )
  }

  const ui = () => {
    const uiLogic = getUILogic()
    return uiLogic
  }

  return <>{ui()}</>
}

export default DataLevels
