/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'

// utilities
import * as utils from '../../../utilities/util'
import AsyncFetch from '../../../utilities/AsyncFetch'
import { getBBox } from '../../../utilities/geospatial'
import { apis } from '../../../config/apiConfig'

// components
import ZoomToBounds from '../../../components/ZoomToBounds/ZoomToBounds'

// scss files
import scss from './PopupHeader.scss'
import modalScss from '../../CSSModules/Modal.scss'

const PopupHeader = ({
  mamId,
  featureProperties,
  closePopup,
  label,
  popupInfo,
  index,
  layerId,
  active,
  layerProperties,
  setActive,
  enlargeWindow,
  setEnlargeWindow,
  popupSwitch,
}) => {
  const dispatch = useDispatch()
  const title = popupInfo.features[index].layer.metadata.label
  const currentFeature = popupInfo.features[index]
  const options = popupInfo.features
  const [showDropdown, setShowDropdown] = useState(
    popupInfo.features.length > 1
  )
  const viewport = useSelector(state => state.viewport)
  let geomType = ''
  if (typeof popupInfo.features[index] !== 'undefined')
    geomType = popupInfo.features[index].geometry.type

  const [measureType, setMeasureType] = useState(null)
  const [activeMeasureType, setActiveMeasureType] = useState([])
  const [measurementFeature, setMeasurementFeature] = useState(null)
  const [urlObjects, setUrlObjects] = useState(null)
  const [fetchingMeasurements, setFetchingMeasurements] = useState(false)
  const [fetchingBounds, setFetchingBounds] = useState(null)
  const [fetchingDocuments, setFetchingDocuments] = useState(null)
  const [zoomToIndex, setZoomIndex] = useState(null)
  const [zoomToBoundsKey, setZoomToBoundsKey] = useState(null)
  const [zoomToBounds, setZoomToBounds] = useState(null)
  const [documentFeature, setDocumentFeature] = useState(null)
  const [fetchObjects, setFetchObjects] = useState(null)

  const buildFetchParams = () => {
    const featureId = popupInfo.features[index].properties.mamid
    const method = 'POST'
    const url = apis.apiDatabase.uri + 'layer/geojson/get'
    const body = {
      layerID: layerId,
      featureID: featureId,
    }

    setFetchingBounds(true)
    setFetchObjects([{ url, method, body }])
  }

  const apiFinished = results => {
    return results.map(result => {
      if (result.success && result.data.features[0]) {
        doZoomToBounds(result.data.features[0])
      }

      setFetchingBounds(false)
      setFetchObjects(null)
    })
  }

  const zoomToPopupFeature = () => {
    buildFetchParams()
  }

  const doZoomToBounds = feature => {
    if (!feature.geometry.type === 'Polygon') return
    let newZoomIndex = zoomToIndex || 1
    const bounds = getBBox(feature)
    const formattedBounds = [
      [bounds[0], bounds[1]],
      [bounds[2], bounds[3]],
    ]
    const zoomToBoundsKey = JSON.stringify(formattedBounds) + newZoomIndex
    const zoomToBounds = formattedBounds
    newZoomIndex++
    setZoomIndex(newZoomIndex)
    setZoomToBounds(zoomToBounds)
    setZoomToBoundsKey(zoomToBoundsKey)
  }

  const getMeasurement = mType => {
    setMeasureType(mType)
    setActiveMeasureType(utils.toggleVal(activeMeasureType, mType[0]))

    const featureId = popupInfo.features[index].properties.mamid
    const method = 'POST'
    const url = apis.apiDatabase.uri + 'layer/geojson/get'
    const body = {
      layerID: layerId,
      featureID: featureId,
    }
    setFetchingMeasurements(true)
    setUrlObjects([{ url, body, method }])
  }

  const fetchFinished = results => {
    if (fetchingMeasurements) {
      measurementFetchFinished(results)
    }
  }

  const measurementFetchFinished = results => {
    return results.map(result => {
      setMeasurementFeature(result.data.features[0])
      setFetchingMeasurements(false)
      setUrlObjects(null)
    })
  }

  const openDocuments = () => {
    const featureId = popupInfo.features[index].properties.mamid
    setDocumentFeature(featureId)
  }

  // Dock or Float Button
  const handleDockPopup = () => {
    if (active) setActive(false)
    else setActive(true)
  }

  const dockButton = (
    <div>
      <button
        className={[
          scss['feature-header-corner'],
          active ? scss['feature-header-corner-active'] : '',
        ].join(' ')}
        onClick={handleDockPopup}
        data-tooltip-content={active ? 'Undock' : 'Dock'}
        data-tooltip-id='popupDocked'
        data-effect='solid'
        data-arrow-color='rgba(0, 0, 0, 0)'
        data-place='bottom'
        style={{ background: 'transparent' }}
      >
        <FontAwesomeIcon icon='border-style' />
      </button>
      <div className={scss.reactToolTip}>
        <Tooltip id='popupDocked' getContent={dataTip => `${dataTip}`} />
      </div>
    </div>
  )

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={apiFinished} />
      )}
      <ZoomToBounds
        key={zoomToBoundsKey}
        viewport={viewport}
        bounds={zoomToBounds}
      />
      <div className={[scss['feature-header'], 'handle'].join(' ')}>
        <div
          className={scss['feature-header-left']}
          style={{ alignItems: 'center', height: '100%' }}
        >
          {dockButton}

          <div className={scss['feature-header-title']}>
            <DropdownMenu
              options={options}
              showDropdown={showDropdown}
              selectedValue={currentFeature}
              onSelect={(option, index) => {
                popupSwitch(index)
              }}
            />
          </div>
          {featureProperties != null ? (
            <button
              type='button'
              style={{
                background: 'transparent',
                border: 'none',
                height: 'fit-content',
              }}
            >
              <FontAwesomeIcon
                icon='info-circle'
                className={scss['feature-header-info']}
                data-tooltip-content={['mamid: ', mamId].join(' ')}
                data-tooltip-id='mamid'
                data-effect='solid'
                data-arrow-color='rgba(0, 0, 0, 0.68)'
              />
              <div className={scss.reactToolTip}>
                <Tooltip id='mamid' getContent={dataTip => `${dataTip}`} />
              </div>
            </button>
          ) : (
            ''
          )}
        </div>
        <button
          type='button'
          title='Change window size'
          onClick={() => {
            setEnlargeWindow(!enlargeWindow)
          }}
          className={scss.enlargeWindowButton}
        >
          <FontAwesomeIcon icon={['far', 'square-full']} />
        </button>
        <button
          onClick={closePopup}
          className={[modalScss.modalX, scss['feature-header-close']].join(' ')}
        >
          <FontAwesomeIcon icon='times' />
        </button>
      </div>
      {urlObjects && (
        <AsyncFetch fetchObjects={urlObjects} fetchFinished={fetchFinished} />
      )}
    </>
  )
}
export default PopupHeader

const DropdownMenu = ({ options, selectedValue, onSelect, showDropdown }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen(!isOpen)
  const ref = useRef(null)

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return (
    <div
      ref={ref}
      style={{
        position: 'relative',
        height: '100%',
        width: '200px',
        overflow: 'visible',
      }}
    >
      <div
        onClick={toggleOpen}
        data-tooltip-content={selectedValue.layer.metadata.label}
        data-tooltip-id='feature-title'
        data-effect='solid'
        data-arrow-color='rgba(0, 0, 0, 0.68)'
        style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <span style={{ paddingLeft: '6pt' }}>
          {selectedValue.layer.metadata.label}{' '}
          {showDropdown && (
            <FontAwesomeIcon
              icon={isOpen ? faAngleUp : faAngleDown}
              style={{ marginLeft: '5px' }}
            />
          )}{' '}
        </span>
        <div className={scss.reactToolTip}>
          <Tooltip id='feature-title' getContent={dataTip => `${dataTip}`} />
        </div>
      </div>
      {isOpen && (
        <ul
          style={{
            position: 'absolute',
            backgroundColor: '#1a2937',
            border: '1px solid #ccc',
            width: '100%',
            listStyle: 'none',
            padding: 0,
            margin: 0,
            zIndex: 1,
          }}
        >
          {options
            .map((option, index) => [option, index])
            .filter(
              ([option, index]) =>
                JSON.stringify(option) !== JSON.stringify(selectedValue)
            )
            .map(([option, index]) => (
              <li
                key={index}
                style={{
                  padding: '10px',
                  cursor: 'pointer',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                onClick={() => {
                  toggleOpen()
                  onSelect(option, index)
                }}
              >
                {option.layer.metadata.label}
              </li>
            ))}
        </ul>
      )}
    </div>
  )
}
