/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'react-tooltip'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import ManageDocuments from './ManageDocuments/ManageDocuments'
import ManageAttributes from './ManageAttributes/ManageAttributes'
import profileScss from '../../Profile/Profile.scss'
import tabScss from '../../MapPopup/TabTemplate/TabTemplate.scss'
import LayerSettingsV2 from './LayerSettingsV2.scss'

// import 'react-tabs/style/react-tabs.css'
const AttributeSettings = ({ config }) => {
  return (
    <Tabs className={LayerSettingsV2.attributeTabs}>
      <TabList>
        <Tab selectedClassName={LayerSettingsV2.activeTab}>
          Feature Attributes
        </Tab>
        <Tab selectedClassName={LayerSettingsV2.activeTab}>
          Document Attributes
        </Tab>
      </TabList>
      <TabPanel>
        <ManageAttributes config={config} />
      </TabPanel>
      <TabPanel>
        <ManageDocuments config={config} />
      </TabPanel>
    </Tabs>
  )
}

export default AttributeSettings
