import React from 'react'

import loadingScreen from '../../assets/logo/MAM---Loading-Globe_Compressed.gif'

export const LoadingScreen = () => {
  const containerStyle = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    paddingTop: 210,
    backgroundColor: '#1a2937',
  }

  const loadingStyle = {
    textAlign: 'center',
    fontSize: '18px',
    color: '#fff',
  }

  return (
    <div style={containerStyle}>
      <img src={loadingScreen} alt='spinning-globe' />
      <div style={loadingStyle}>Loading...</div>
    </div>
  )
}

export default LoadingScreen
