/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-key */
import { useTable, useSortBy, useRowSelect } from 'react-table'
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import scss from './Table.scss'
import ContextMenu from '../ContextMenu/ContextMenu'
import * as DocumentBrowserUtil from '../DocumentBrowserV2Util'
import RenamePopup from '../ContextMenu/RenamePopup/RenamePopup.jsx'
import BulkMenu from './BulkMenu/BulkMenu'

const getIcon = fileName => {
  fileName = String(fileName).toUpperCase()

  // if not . then it is a folder
  if (!fileName.includes('.'))
    return (
      <FontAwesomeIcon
        icon='folder'
        style={{
          color: 'rgb(92, 141, 163)',
          marginLeft: '30px',
        }}
      />
    )

  let fileExtension = fileName.split('.')
  fileExtension = fileExtension[fileExtension.length - 1]

  if (fileExtension === 'PDF') {
    return (
      <FontAwesomeIcon
        icon='file-pdf'
        style={{
          color: 'white',
          marginLeft: '30px',
        }}
      />
    )
  }

  if (
    [
      'JPEG',
      'JPG',
      'PNG',
      'GIF',
      'TIFF',
      'EPS',
      'INDD',
      'RAW',
      'BIMP',
      'TIF',
      'TIFF',
      'PSD',
    ].includes(fileExtension)
  ) {
    return (
      <FontAwesomeIcon
        icon='file-image'
        style={{
          color: 'white',
          marginLeft: '30px',
        }}
      />
    )
  }

  return (
    <FontAwesomeIcon
      icon='file'
      style={{
        color: 'white',
        marginLeft: '30px',
      }}
    />
  )
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type='checkbox' ref={resolvedRef} {...rest} />
      </>
    )
  }
)

// Link mode changes the style of the rows
const getRowStyle = (row, linkMode) => {
  let rowName
  if (row && row.values && row.values.name) rowName = row.values.name

  let isFolder = true

  // Only files contains a .
  if (rowName.includes('.')) isFolder = false

  if (linkMode) {
    if (!isFolder)
      return (
        <>
          <FontAwesomeIcon
            icon='link'
            style={{
              color: 'white',
              marginLeft: '10px',
            }}
            className={scss.linkStyle}
          />
          {getIcon(rowName)}
        </>
      )
    return getIcon(rowName)
  }

  return (
    <>
      <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />{' '}
      {getIcon(rowName)}
    </>
  )
}

// Use the state and functions returned from useTable to build your UI
const Table = ({
  columns,
  data,
  setCwd,
  cwd,
  setShowSearchResults,
  setTriggerRefresh,
  setGenerateNewFileTree,
  linkMode = false,
  typeDocumentKey,
  handleDocumentChange,
  onClose,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    toggleRowSelected,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
    useBlockLayout,
    useResizeColumns,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              {linkMode ? null : (
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              )}
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '35px',
                }}
              >
                {getRowStyle(row, linkMode)}
              </span>
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  const firstPageRows = rows.slice(0, 2000)

  const rowSelectedStyle = (rowId, selectedRowIdsVal) => {
    let style = { background: 'inherit' }
    selectedRowIdsVal = Object.keys(selectedRowIdsVal)
    if (selectedRowIdsVal.length === 0) {
      return style
    }
    if (selectedRowIdsVal.includes(rowId)) {
      style = { background: '#c8e5ff38' }
      return style
    }
    return style
  }

  const addRowIdToSelected = (rowId, selectedRowIdsVal) => {
    selectedRowIdsVal = Object.keys(selectedRowIdsVal)

    if (selectedRowIdsVal.includes(rowId)) {
      toggleRowSelected(rowId, false)
    } else {
      toggleRowSelected(rowId, true)
    }
  }

  const changeCWD = row => {
    let path
    if (
      row &&
      row.original &&
      row.original.key &&
      row.original.key.length > row.values.name.length
    )
      path = cwd + '/' + row.original.key
    else {
      path = cwd + '/' + row.values.name
    }

    if (path.includes('//')) path = path.replace('//', '/')
    setShowSearchResults(false)
    setCwd(path)
  }

  const [contextMenuXPos, setContextMenuX] = useState(0)
  const [contextMenuYPos, setContextMenuY] = useState(0)
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false)
  const [currentRow, setCurrentRow] = useState()
  const [isRenamePopupVisible, setRenamePopupVisible] = useState(false)
  const [isBulkMenuVisible, setIsBulkMenuVisible] = useState(false)
  const [numberOfClicks, setNumberOfClicks] = useState(0)

  useEffect(() => {
    if (
      (selectedFlatRows && selectedFlatRows.length > 0) ||
      numberOfClicks === 1
    ) {
      setIsBulkMenuVisible(true)
    } else {
      setIsBulkMenuVisible(false)
    }

    setNumberOfClicks(0)
  })

  const showContextMenu = (event, row) => {
    event.preventDefault()
    setContextMenuX(event.clientX)
    setContextMenuY(event.clientY)
    setIsContextMenuVisible(true)
    setCurrentRow(row)
  }

  window.addEventListener('click', () => {
    if (!isContextMenuVisible) {
      setIsContextMenuVisible(false)
    }
  })

  const handleClick = (e, row) => {
    setNumberOfClicks(e.detail)
    switch (e.detail) {
      case 2:
        if (row.values.name && row.values.name.includes('.')) {
          DocumentBrowserUtil.openFileOnAnotherWindow(row.original.awsPath)
        } else {
          changeCWD(row)
        }
        break
      case 1:
        if (linkMode) {
          if (row.values.name && row.values.name.includes('.')) {
            handleDocumentChange(
              typeDocumentKey,
              row.original.awsPath,
              row.values.name
            )
            onClose()
          }
        } else {
          addRowIdToSelected(row.id, selectedRowIds)
        }
        break
      default:
    }
  }

  // Render the UI for your table
  return (
    <>
      {isBulkMenuVisible ? (
        <BulkMenu
          rows={selectedFlatRows}
          setTriggerRefresh={setTriggerRefresh}
          cwd={cwd}
        />
      ) : null}
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon
                          className='fa-lg sort-icon'
                          icon='caret-down'
                          style={{
                            color: 'white',
                            marginLeft: '5px',
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className='fa-lg sort-icon'
                          icon='caret-up'
                          style={{
                            color: 'white',
                            marginLeft: '5px',
                          }}
                        />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map(row => {
            prepareRow(row)
            return (
              <tr
                title={linkMode ? 'Click to link ' + row.values.name : null}
                {...row.getRowProps()}
                onClick={e => handleClick(e, row)}
                onContextMenu={event => {
                  showContextMenu(event, row)
                }}
                style={rowSelectedStyle(row.id, selectedRowIds)}
                className={scss.Row}
                key={DocumentBrowserUtil.makeUniqueIDNumbersOnly(15)}
              >
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '200px',
                        cursor: 'pointer',
                      }}
                      key={DocumentBrowserUtil.makeUniqueIDNumbersOnly(15)}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {isContextMenuVisible ? (
        <ContextMenu
          x={contextMenuXPos}
          y={contextMenuYPos}
          row={currentRow}
          setTriggerRefresh={setTriggerRefresh}
          setGenerateNewFileTree={setGenerateNewFileTree}
          setIsContextMenuVisible={setIsContextMenuVisible}
          setRenamePopupVisible={setRenamePopupVisible}
        />
      ) : null}
      <div>
        {isRenamePopupVisible ? (
          <RenamePopup
            cwd={cwd}
            row={currentRow}
            setTriggerRefresh={setTriggerRefresh}
            setRenamePopupVisible={setRenamePopupVisible}
          />
        ) : null}
      </div>
    </>
  )
}

export default Table
