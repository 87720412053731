/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import isEqual from 'react-fast-compare'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'react-tooltip'
import { apiFetch } from '../../../../../utilities/api'
import { apis } from '../../../../../config/apiConfig'
import { Alert } from '../../../../../actions'

// scss files
import scss from '../DocumentRow/DocumentRow.scss'
import appScss from '../../../../App/App.scss'
import 'react-datepicker/dist/react-datepicker.css'

const AddDocument = ({
  config,
  getDropDownOptionList,
  cancelEdit,
  getProperties,
  finishUpdate,
}) => {
  const [error, setError] = useState(null)
  const [saving, setSaving] = useState(false)
  const [propertyName, setPropertyName] = useState('New Document Attribute')
  const [propertyType, setPropertyType] = useState('document')
  const [propertyDefault, setPropertyDefault] = useState('')
  const [documentDropdown, setDocumentDropdown] = useState('Feature')
  const dispatch = useDispatch()

  const createProperty = () => {
    const layerId = config.layer.layersArray[0].layer.id
    const method = 'POST'
    const url = apis.apiDatabase.uri + 'layer/properties/add'

    const bodyParams = {
      layerID: layerId,
      name: propertyName,
      value: [],
    }

    if (documentDropdown === 'Feature' || documentDropdown === 'feature')
      bodyParams.type = 'document (Feature)'
    else if (documentDropdown === 'Layer' || documentDropdown === 'layer')
      bodyParams.type = 'document (Layer)'

    apiFetch(url, method, bodyParams, result => {
      if (result && result.data && result.success === false) {
        result.data = JSON.parse(result.data)
        dispatch(
          Alert({
            error:
              'Reserved words cannot be used to create Document Attribute: ' +
              result.data,
          })
        )
      }
      getProperties()
      finishUpdate()
    })
  }

  const getDefaultEditor = () => {
    return (
      <div className={scss.globalCustomSelect}>
        <select
          id='property-default'
          value={documentDropdown}
          onChange={e => setDocumentDropdown(e.target.value)}
        >
          <option key='1' value='Feature'>
            Feature
          </option>
          <option key='2' value='Layer'>
            Layer
          </option>
        </select>
        <FontAwesomeIcon
          icon='chevron-down'
          size='1x'
          pull='right'
          className={scss.globalCustomSelectIcon}
        />
      </div>
    )
  }

  useEffect(() => {
    if (propertyType === 'date' && propertyDefault && propertyDefault !== '') {
      const newDate = new Date(propertyDefault)
      setPropertyDefault(newDate)
    }
    console.log('Initial Default', propertyDefault)
  }, [])

  const dropDownOptions =
    propertyType === 'dropdown' ? getDropDownOptionList() : null
  const defaultEditor = getDefaultEditor()

  const [showCreateButton, setShowCreateButton] = useState(true)
  useEffect(() => {
    const propertyNameCheck = propertyName.split(' ').join('')
    if (propertyNameCheck) {
      setShowCreateButton(true)
    } else {
      setShowCreateButton(false)
    }
  })

  const handleFocus = e => {
    e.currentTarget.select()
  }

  return (
    <div className={[scss.flexColumn, scss.layerPropertiesEdit].join(' ')}>
      <span className={scss.layerPropertiesEditText}>
        New Document Attribute
      </span>
      <div
        className={[scss.flexColumn, scss.layerPropertiesEditInputs].join(' ')}
      >
        <label htmlFor='property-name' className={scss.globalLabel}>
          Name
          <input
            id='property-name'
            name='name'
            value={propertyName}
            onChange={e => {
              let propertyNameInput = e.target.value
              // The back-end creates scripts with this name so we restrict it to normal characters
              propertyNameInput = propertyNameInput.replace(/[^\w\s]/gi, '')
              setPropertyName(propertyNameInput)
            }}
            onFocus={handleFocus}
            autoFocus
          />
        </label>
        {/* <label
          htmlFor='property-type'
          className={scss.globalLabel}
          style={{ marginBottom: 0 }}
        >
          Type
          <div className={scss.globalCustomSelect}>
            <select
              id='property-type'
              value={propertyType}
              onChange={e => setPropertyType(e.target.value)}
            >
              {fieldTypes.map(property => (
                <option key={property.value} value={property.value}>
                  {property.label}
                </option>
              ))}
            </select>
            <FontAwesomeIcon
              icon='chevron-down'
              size='1x'
              pull='right'
              className={scss.globalCustomSelectIcon}
            />
          </div>
          {dropDownOptions}
        </label> */}

        {/* Specific Inputs for Dates and Currency */}
        {
          <label htmlFor='property-default' className={scss.globalLabel}>
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '2px 0',
                }}
              >
                Level
                <FontAwesomeIcon
                  icon='question-circle'
                  size='1x'
                  data-tooltip-content='The Level at which the Document Attribute will be accessible. Feature Level Document Attributes will only be accessible at each Feature Level. Layer Level Document Attributes will only be accessible at the Layer Level.'
                  data-tooltip-id='data-driven'
                  data-effect='solid'
                  data-place='right'
                  data-arrow-color='rgba(0, 0, 0, 0.68)'
                  style={{ fontSize: '12px', padding: '0 5px' }}
                />
                <div className={appScss.reactToolTip}>
                  <Tooltip
                    id='data-driven'
                    getContent={dataTip => `${dataTip}`}
                  />
                </div>
              </div>
            </>
            {defaultEditor}
          </label>
        }

        {/* Cancel and Save Buttons */}
        <div
          className={[scss.flexRow, scss.flexCenter, scss.flexJustify].join(
            ' '
          )}
        >
          <button
            type='button'
            className={appScss.altBlueButton}
            onClick={cancelEdit}
          >
            Cancel
          </button>
          {showCreateButton ? (
            <button
              type='button'
              className={appScss.blueButton}
              onClick={createProperty}
            >
              Create
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default AddDocument
