import React from 'react'
import { useDispatch } from 'react-redux'
import { updateMapStyle } from '../../../actions/index'
import { fromJS } from '../../../../node_modules/immutable'
import clearSelectionIcon from '../../../assets/icons/map/clear-selection.svg'
import { SELECTED_FEATURES } from '../../../data/Layers/Auxiliary/SelectedFeatures'
import * as mapUtils from '../../../utilities/mapStyle'
import scss from '../../Map/Map.scss'

const ClearSelection = React.memo(
  ({ mapStyle, setClickedFeature, closePopup }) => {
    const dispatch = useDispatch()

    const emptySelectSources = style => {
      SELECTED_FEATURES.sourcesArray.forEach(measureSource => {
        let source = mapUtils.getSource(style, measureSource.id)
        source = mapUtils.emptySource(source)
        style.sources[measureSource.id] = source
      })
      return style
    }

    const clearSelection = () => {
      // set clicekd feature in map state to null
      setClickedFeature(null)
      // close popup
      closePopup()
      // get all tocLayers and set visibility to none
      let style = mapStyle.toJS()
      let clickedFeatureLayer = style.layers.filter(
        layer => layer.id === 'clicked_feature'
      )
      clickedFeatureLayer[0].source = 'clicked_feature'
      if (clickedFeatureLayer[0]['source-layer']) {
        delete clickedFeatureLayer[0]['source-layer']
      }
      style = emptySelectSources(style)
      // TODO :: Clear Visible Popup
      dispatch(updateMapStyle(fromJS(style)))
    }
    return (
      <button
        className={scss['map-button']}
        onClick={clearSelection}
        title='Clear Selection'
        data-tooltip-content='Clear Clicked Feature'
        data-tooltip-id='clear-selection'
        data-effect='solid'
        data-arrow-color='rgba(0, 0, 0, 0.68)'
        data-place='left'
        style={{
          borderTop: 'solid 0.5px #314d68',
          borderRadius: '0 0 10px 10px',
        }}
        type='button'
      >
        <img src={clearSelectionIcon} alt='Clear Selection' />
      </button>
    )
  }
)

export default ClearSelection
