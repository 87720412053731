import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { NavigationControl } from 'react-map-gl'
import {
  setDragPan,
  setDoubleClickZoom,
  setScrollZoom,
  setOverPanel,
  updateMapStyle,
  Alert,
} from '../../../actions/index'
import { Tooltip } from 'react-tooltip'
import appScss from '../../App/App.scss'
import scss from './MapControls.scss'

import Home from '../../MapControls/Home/Home'
import UserLocation from '../../MapControls/UserLocation/UserLocation'
import FeatureSelect from '../../MapControls/FeatureSelect/FeatureSelect'
import FeatureSelectBtn from '../../MapControls/FeatureSelect/FeatureSelectBtn'
import DrawBtn from '../../MapControls/Draw/DrawBtn'
import MeasureBtn from '../../MapControls/MeasureControl/MeasureBtn'
import Measure from '../../MapControls/MeasureControl/Measure'
import SearchBtn from '../../MapControls/Search/SearchBtn'
import Search from '../../MapControls/Search/Search'
import ClearMap from '../../MapControls/ClearMap/ClearMap'
import ClearSelection from '../../MapControls/ClearSelection/ClearSelection'
import FeedbackButton from '../../MapControls/Feedback/FeedbackButton'
import FeedbackDialog from '../../MapControls/Feedback/FeedbackDialog'

import { fromJS } from 'immutable'

const RightSideControls = React.memo(
  ({
    onViewportChange,
    setClickedFeature,
    closePopup,
    selectedFeatures,
    setSelectedFeatures,
  }) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const viewport = useSelector(state => state.viewport)
    const mapRef = useSelector(state => state.mapRef)
    const mapStyle = useSelector(state => state.mapStyle)
    const dataConfig = useSelector(state => state.updateDataConfig)

    const [searchVisible, setSearchVisible] = useState(false)
    const [measureVisible, setMeasureVisible] = useState(false)
    const [featureSelectVisible, setFeatureSelectVisible] = useState(false)
    const [feedbackVisible, setFeedbackVisible] = useState(false)

    const disableMap = () => {
      dispatch(setOverPanel(true))
      dispatch(setScrollZoom(false))
      dispatch(setDragPan(false))
      dispatch(setDoubleClickZoom(false))
    }

    const enableMap = () => {
      dispatch(setOverPanel(false))
      dispatch(setScrollZoom(true))
      dispatch(setDragPan(true))
      dispatch(setDoubleClickZoom(true))
    }

    const toggleFeedbackVisible = () => {
      setFeedbackVisible(!feedbackVisible)
    }
    const selectFeaturesOnScreen = targetLayer => {
      if (!targetLayer) return

      const map = mapRef.getMap()
      const features = map.queryRenderedFeatures({
        layers: targetLayer.layerIdList,
      })

      if (!features.length)
        dispatch(
          Alert({
            info: 'No features were found on the selected layer on the current viewport.',
          })
        )

      setSelectedFeatures(features)
    }

    const clearSelectedFeatures = () => {
      let style = mapStyle.toJS()
      style.sources.selected_features.data.features = []
      dispatch(updateMapStyle(fromJS(style)))
      setSelectedFeatures([])
    }

    return (
      <>
        <div
          style={{ height: viewport.height - 22 }}
          className={scss.topRightControl}
          onMouseEnter={disableMap}
          onMouseLeave={enableMap}
        >
          <div
            className={[scss['map-search-button'], scss['map-button']].join(
              ' '
            )}
          >
            <SearchBtn toggleSearch={() => setSearchVisible(!searchVisible)} />
          </div>
          <div className={appScss.reactToolTip}>
            <Tooltip id='search' getContent={dataTip => `${dataTip}`} />
          </div>
          <div
            className={[
              scss['map-button-double'],
              scss['map-nav-buttons'],
            ].join(' ')}
          >
            <Home
              mapID={user.mapID}
              mapStyle={mapStyle}
              dataConfig={dataConfig}
              enableMap={enableMap}
            />
            <UserLocation />
          </div>
          <div className={appScss.reactToolTip}>
            <Tooltip id='user-location' getContent={dataTip => `${dataTip}`} />
          </div>
          <div className={appScss.reactToolTip}>
            <Tooltip id='map-control' getContent={dataTip => `${dataTip}`} />
          </div>
          <div className={scss['map-zoom-north-button']}>
            <NavigationControl onViewportChange={onViewportChange} />
          </div>
          <div
            className={[
              scss['map-clear-button'],
              scss['map-button-double'],
            ].join(' ')}
            onMouseEnter={disableMap}
            onMouseLeave={enableMap}
          >
            <ClearMap
              dataConfig={dataConfig}
              setClickedFeature={setClickedFeature}
              closePopup={closePopup}
            />
            <ClearSelection
              mapStyle={mapStyle}
              dataConfig={dataConfig}
              setClickedFeature={setClickedFeature}
              closePopup={closePopup}
            />
          </div>
          <div className={appScss.reactToolTip}>
            <Tooltip id='clear-map' getContent={dataTip => `${dataTip}`} />
          </div>
          <div className={appScss.reactToolTip}>
            <Tooltip
              id='clear-selection'
              getContent={dataTip => `${dataTip}`}
            />
          </div>
          <div className={scss['map-top-right-tools']}>
            <div
              className={[scss['map-feature-button'], scss['map-button']].join(
                ' '
              )}
              onMouseEnter={disableMap}
              onMouseLeave={enableMap}
            >
              <FeatureSelectBtn
                toggleFeatureSelect={() =>
                  setFeatureSelectVisible(!featureSelectVisible)
                }
              />
            </div>
            <div
              className={[scss['map-draw-button'], scss['map-button']].join(
                ' '
              )}
              onMouseEnter={disableMap}
              onMouseLeave={enableMap}
            >
              <DrawBtn />
            </div>
            <div
              className={[scss['map-measure-button'], scss['map-button']].join(
                ' '
              )}
              onMouseEnter={disableMap}
              onMouseLeave={enableMap}
            >
              <MeasureBtn
                toggleMeasure={() => setMeasureVisible(!measureVisible)}
              />
            </div>
          </div>
          <div
            className={[scss['map-ticket-button'], scss['map-button']].join(
              ' '
            )}
            onMouseEnter={disableMap}
            onMouseLeave={enableMap}
            style={{ border: 'none' }}
          >
            <FeedbackButton toggleFeedbackVisible={toggleFeedbackVisible} />
          </div>
        </div>
        {
          <FeedbackDialog
            feedbackVisible={feedbackVisible}
            toggleFeedbackVisible={toggleFeedbackVisible}
          />
        }
        {searchVisible && (
          <Search disableMap={disableMap} enableMap={enableMap} />
        )}
        {measureVisible && (
          <Measure disableMap={disableMap} enableMap={enableMap} />
        )}
        {featureSelectVisible && (
          <FeatureSelect
            mapStyle={mapStyle}
            dataConfig={dataConfig}
            selectedFeatures={selectedFeatures}
            selectFeaturesOnScreen={selectFeaturesOnScreen}
            clearSelectedFeatures={clearSelectedFeatures}
            enableMap={enableMap}
            disableMap={disableMap}
          />
        )}
      </>
    )
  }
)

export default RightSideControls
