import { useState } from 'react';
import * as utils from '../../util';

export const useCheckboxList = (initialState = {}) => {
  const [checkboxList, setCheckboxList] = useState(initialState);
  const [checkboxCount, setCheckboxCount] = useState(0);

  const toggleCheckbox = (id, data) => {
    const newVal = utils.toggleVal(checkboxList, id, data);
    setCheckboxList(newVal);
    setCheckboxCount(Object.keys(newVal).length);
  };

  const toggleGroupCheckboxes = (groupId, items, checked) => {
    let newVal = { ...checkboxList };
    
    items.forEach(item => {
      const checkboxId = `${groupId}_${item.id}`;
      if (checked) {
        newVal = utils.addVal(newVal, checkboxId, { group: groupId, item });
      } else {
        newVal = utils.unVal(newVal, checkboxId);
      }
    });

    setCheckboxList(newVal);
    setCheckboxCount(Object.keys(newVal).length);
  };

  const isChecked = (id) => {
    return id in checkboxList;
  };

  const getGroupCheckedCount = (groupId) => {
    if (!groupId) return checkboxCount;

    return Object.keys(checkboxList).filter(key => 
      key.startsWith(`${groupId}_`)
    ).length;
  };

  const resetCheckboxes = () => {
    setCheckboxList({});
    setCheckboxCount(0);
  };

  return {
    checkboxList,
    checkboxCount,
    toggleCheckbox,
    toggleGroupCheckboxes,
    isChecked,
    getGroupCheckedCount,
    resetCheckboxes
  };
}; 