import React, { useState, useEffect } from 'react'
import { BlockPicker } from 'react-color'
import { Tooltip } from 'react-tooltip'
import scss from './StyleInput.scss'
import appScss from '../../../../App/App.scss'
import colorPickerScss from '../../../../CSSModules/ColorPicker.scss'
import myAssetMapColors from '../../../../../utilities/componentConstants/myAssetMapColors.js'

const ColorInput = (colorValue, callback, id = 'fill', type) => {
  const [showPicker, setShowPicker] = useState(false)
  const [value, setValue] = useState(colorValue)

  useEffect(() => {
    callback(id, value)
  }, [value])

  const handleColorPicker = () => {
    setShowPicker(!showPicker)
  }

  const handleColorChange = color => {
    const val = color.hex
    setValue(val)
  }

  let newID
  if (id == 'outline') {
    newID = 'Border'
  } else newID = 'Fill'

  const input = (
    <div>
      <div
        className={scss.stylesColumn}
        style={newID == 'Border' ? { marginTop: '-18px' } : null}
      >
        {newID == 'Border' ? (
          <div className={scss.colorSelectorBorder}></div>
        ) : null}

        <input
          onClick={handleColorPicker} // <--- Open/hide on click
          style={{ backgroundColor: value }}
          className={scss.colorSelector}
          data-tooltip-content={type == 'line' ? 'Line' : newID}
          data-tooltip-id='color'
          data-effect='solid'
          data-arrow-color='rgba(0, 0, 0, 0)'
        />
        <div
          className={[appScss.reactToolTip, appScss.reactToolTipFont].join(' ')}
        >
          <Tooltip id='color' getContent={dataTip => `${dataTip}`} />
        </div>
      </div>

      {showPicker ? (
        <div
          className={scss.stylesColumn}
          style={{ position: 'fixed', top: 120, left: 100, zIndex: 1000 }}
        >
          <div className={colorPickerScss.colorPickerPopover}>
            <div
              className={colorPickerScss.colorPickerCover}
              style={{ backgroundColor: '#00000050', borderRadius: '9px' }}
              onClick={handleColorPicker}
            />
            <BlockPicker
              colors={myAssetMapColors}
              triangle='hide'
              type='color'
              color={value}
              onChangeComplete={color => handleColorChange(color)}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
  return input
}

export default ColorInput
