import { library } from '@fortawesome/fontawesome-svg-core'

// Font Awesome Icons
import {
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faArchive,
  faArrowsAlt,
  faArrowUp,
  faArrowRight,
  faArrowDown,
  faArrowLeft,
  faArrowFromTop,
  faArrowFromBottom,
  faBars,
  faBan,
  faBookmark,
  faBorderStyle,
  faBorderStyleAlt,
  faBringForward,
  faBullseyePointer,
  faCaretUp,
  faCaretDown,
  faCaretRight,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDoubleDown,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDoubleUp,
  faCircle,
  faClone,
  faCog,
  faDrawPolygon,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExpandAlt,
  faEye,
  faEdit,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileImage,
  faFilePdf,
  faFilePlus,
  faFileUpload,
  faFolder,
  faFolderUpload,
  faFolderTree,
  faFolderOpen,
  faGlobeAmericas,
  faHome,
  faHistory,
  faInfoCircle,
  faInfo,
  faKey,
  faLayerGroup,
  faExternalLink,
  faLink,
  faUnlink,
  faList,
  faListAlt,
  faListUl,
  faLocation,
  faMap,
  faMapMarker,
  faMapMarkerAlt,
  faMapMarkedAlt,
  faMinus,
  faMinusCircle,
  faMousePointer,
  faObjectGroup,
  faPalette,
  faPencil,
  faPencilAlt,
  faPenNib,
  faPrint,
  faProjectDiagram,
  faPlane,
  faPlus,
  faPlusCircle,
  faPollH,
  faQuestionCircle,
  faQuestion,
  faRuler,
  faRulerVertical,
  faRulerTriangle,
  faRulerCombined,
  faSave,
  faSearch,
  faSearchLocation,
  faSendBackward,
  faShareAlt,
  faSignInAlt,
  faStar,
  faSortDown,
  faSortUp,
  faStickyNote,
  faSync,
  faSyncAlt,
  faStop,
  faStopCircle,
  faSpinner,
  faTable,
  faTag,
  faTimes,
  faTimesCircle,
  faTimesOctagon,
  faTrash,
  faTrashAlt,
  faUndoAlt,
  faUser,
  faUserPlus,
  faUpload,
  faWrench,
  faDotCircle,
} from '@fortawesome/pro-solid-svg-icons'
import {
  faAngleDoubleLeft as farAngleDoubleLeft,
  faAngleDoubleRight as farAngleDoubleRight,
  faArrowRight as farArrowRight,
  faArrowFromTop as farArrowFromTop,
  faArrowFromBottom as farArrowFromBottom,
  faEllipsisH as farEllipsisH,
  faFilter as farFilter,
  faObjectGroup as farObjectGroup,
  faSearch as farSearch,
  faShareAlt as farShareAlt,
  faLink as farLink,
  faSquareFull as farSquareFull,
  faListTree,
} from '@fortawesome/pro-regular-svg-icons'

import {
  faAngleUp as falAngleUp,
  faAngleDown as falAngleDown,
  faBullseyePointer as falBullseyePointer,
  faSync as falSync,
  faTimes as falTimes,
  faCircle as falCircle,
  faDrawPolygon as falDrawPolygon,
  faArrowsAlt as falArrowsAlt,
  faHorizontalRule as falHorizontalRule,
  faText as falText,
  faMapPin as falMapPin,
  faChevronDown as falChevronDown,
  faChevronLeft as falChevronLeft,
  faChevronDoubleDown as falChevronDoubleDown,
  faChevronDoubleLeft as falChevronDoubleLeft,
  faChevronDoubleRight as falChevronDoubleRight,
  faChevronDoubleUp as falChevronDoubleUp,
  faCheck as falCheck,
  faClone as falClone,
  faExpandAlt as falExpandAlt,
  faEdit as falEdit,
  faTrash as falTrash,
  faTrashAlt as falTrashAlt,
  faMapMarkerExclamation as falMapMarkerExclamation,
  faRuler as falRuler,
  faListUl as falListUl,
  faListAlt as falListAlt,
  faEmptySet as falEmptySet,
  faMinus as falMinus,
  faMap as falMap,
  faCopy as falCopy,
  faAlignCenter as falAlignCenter,
  faAlignRight as falAlignRight,
  faAlignLeft as falAlignLeft,
  faDownload as falDownload,
  faPaintBrush as falPaintBrush,
  faPlusSquare as falPlusSquare,
  faPlus as falPlus,
  faArrowsV as falArrowsV,
  faArrowsH as falArrowsH,
  faUpload as falUpload,
  faCloudUploadAlt as falCloudUploadAlt,
  faLayerGroup as falLayerGroup,
  faLink as falLink,
  faCog as falCog,
  faSearch as falSearch,
  faSearchLocation as falSearchLocation,
  faShareAlt as falShareAlt,
  faSortDown as falSortDown,
  faSortUp as falSortUp,
  faChevronRight as falChevronRight,
  faMapMarker as falMapMarker,
  faStickyNote as falStickyNote,
  faStop as falStop,
  faStopCircle as falStopCircle,
  faTable as falTable,
  faPlane as falPlane,
  faGlobe as falGlobe,
  faFilter as falFilter,
  faFolderPlus as falFolderPlus,
  faFileUpload as falFileUpload,
  faFileEdit as falFileEdit,
  faFileDownload as falFileDownload,
  faFileTimes as falFileTimes,
  faFolder as falFolder,
  faFolderUpload as falFolderUpload,
  faFile as falFile,
  faFilePlus as falFilePlus,
  faFileCheck as falFileCheck,
  faCabinetFiling as falCabinetFiling,
  faMousePointer as falMousePointer,
  faBorderLeft as falBorderLeft,
  faBorderNone as falBorderNone,
  faTag as falTag,
} from '@fortawesome/pro-light-svg-icons'

export const configureFontAwesome = () => {
  library.add(
    faArchive,
    faAngleUp,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faArrowsAlt,
    faArrowUp,
    faArrowRight,
    faArrowDown,
    faArrowLeft,
    faArrowFromTop,
    faArrowFromBottom,
    faBars,
    faBan,
    faBookmark,
    faBorderStyle,
    faBorderStyleAlt,
    faBringForward,
    faBullseyePointer,
    faCaretUp,
    faCaretDown,
    faCaretRight,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChevronDoubleDown,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faChevronDoubleUp,
    faCircle,
    faCheckCircle,
    faClone,
    faCog,
    faDrawPolygon,
    faDownload,
    faEllipsisH,
    faEllipsisV,
    faExclamation,
    faExclamationCircle,
    faExclamationTriangle,
    faExpandAlt,
    faEdit,
    faEye,
    faEyeSlash,
    faFile,
    faFileAlt,
    faFileImage,
    faFilePdf,
    faFilePlus,
    faFolder,
    faFolderUpload,
    faFolderTree,
    faFolderOpen,
    faFileAlt,
    faFileUpload,
    faGlobeAmericas,
    faHome,
    faHistory,
    faInfoCircle,
    faInfo,
    faListTree,
    faKey,
    faLayerGroup,
    faExternalLink,
    faLink,
    faUnlink,
    faList,
    faListAlt,
    faListUl,
    faLocation,
    faMap,
    faMapMarker,
    faMapMarkerAlt,
    faMapMarkedAlt,
    faMinus,
    faMinusCircle,
    faMousePointer,
    faObjectGroup,
    faPalette,
    faPencil,
    faPencilAlt,
    faPenNib,
    faPrint,
    faProjectDiagram,
    faPlane,
    faPlus,
    faPlusCircle,
    faPollH,
    faQuestionCircle,
    faQuestion,
    faRuler,
    faRulerVertical,
    faRulerTriangle,
    faRulerCombined,
    faSave,
    faSearch,
    faSearchLocation,
    faSendBackward,
    faShareAlt,
    faSignInAlt,
    faSortDown,
    faSortUp,
    faStar,
    faStickyNote,
    faStop,
    faStopCircle,
    faSpinner,
    faSync,
    faSyncAlt,
    faTable,
    faTag,
    faTimes,
    faTimesCircle,
    faTimesOctagon,
    faTrash,
    faTrashAlt,
    faUndoAlt,
    faUser,
    faUserPlus,
    faUpload,
    faWrench,
    falAngleUp,
    falAngleDown,
    falArrowsAlt,
    falArrowsH,
    falBullseyePointer,
    falDrawPolygon,
    falChevronDoubleDown,
    falChevronDoubleLeft,
    falChevronDoubleRight,
    falChevronDoubleUp,
    falCircle,
    falExpandAlt,
    falSync,
    falHorizontalRule,
    falText,
    falMapPin,
    falTimes,
    falChevronDown,
    falChevronLeft,
    falCheck,
    falTrash,
    falTrashAlt,
    falMapMarkerExclamation,
    falRuler,
    falListUl,
    falEmptySet,
    falEdit,
    falMinus,
    falMap,
    falListAlt,
    falCopy,
    falClone,
    falAlignCenter,
    falAlignRight,
    falAlignLeft,
    falDownload,
    falPaintBrush,
    falPlusSquare,
    falPlus,
    falArrowsV,
    falArrowsH,
    falCloudUploadAlt,
    falUpload,
    falTable,
    falLayerGroup,
    falLink,
    falCog,
    falSearch,
    falSearchLocation,
    falChevronRight,
    falMapMarker,
    falShareAlt,
    falSortDown,
    falSortUp,
    falStickyNote,
    falStop,
    falStopCircle,
    falPlane,
    falGlobe,
    falFilter,
    falFolderPlus,
    falFileUpload,
    falFileEdit,
    falFileDownload,
    falFileTimes,
    falFolder,
    falFolderUpload,
    falFile,
    falFilePlus,
    falFileCheck,
    falCabinetFiling,
    farAngleDoubleLeft,
    farAngleDoubleRight,
    farFilter,
    farLink,
    farArrowRight,
    falMousePointer,
    farArrowFromTop,
    farArrowFromBottom,
    farEllipsisH,
    farFilter,
    farObjectGroup,
    farSearch,
    farSquareFull,
    farShareAlt,
    falBorderLeft,
    falBorderNone,
    falTag,
    faDotCircle
  )
}
