import React, { useEffect, useState, useTransition, useCallback } from 'react'
import logoSrc from '../../assets/logo/drk-trim-main.svg'
import styles from './ErrorBoundary.scss'
import Loading from './Loading'
import PropTypes from 'prop-types'

function ErrorFallback({ error, refreshPage, resetError }) {
  const [seconds, setSeconds] = useState(5)
  const [isPending, startTransition] = useTransition()

  // Handle countdown timer
  useEffect(() => {
    if (refreshPage) {
      const interval = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1)
      }, 1000)
      return () => clearInterval(interval)
    }
    return () => {}
  }, [refreshPage])

  // Handle page refresh
  useEffect(() => {
    if (seconds === 0) {
      startTransition(() => {
        if (resetError) {
          resetError()
        } else {
          window.location.reload()
        }
      })
    }
  }, [seconds, resetError])

  // Manual retry handler
  const handleRetry = useCallback(() => {
    startTransition(() => {
      if (resetError) {
        resetError()
      } else {
        window.location.reload()
      }
    })
  }, [resetError])

  // Copy error details to clipboard
  const copyErrorDetails = useCallback(() => {
    const errorDetails = `
      Error: ${error?.message || 'No message available'}
      Time: ${new Date().toISOString()}
      URL: ${window.location.href}
      User Agent: ${navigator.userAgent}
    `
    navigator.clipboard.writeText(errorDetails)
      .then(() => alert('Error details copied to clipboard'))
      .catch(err => console.error('Failed to copy error details:', err))
  }, [error])

  if (isPending) {
    return <Loading />
  }

  return (
    <div className={styles.fullPageContainer}>
      <div className={styles.container}>
        <div className={styles.box}>
          <img
            src={logoSrc}
            alt='My Asset Map Logo'
            style={{ marginBottom: '54px' }}
          />
          <h1>Something went wrong!</h1>
          <p>
            <span>It looks like something went wrong.</span>
          </p>
          <p>
            We have saved your work, and will attempt to refresh the page to
            resolve the issue.
          </p>
          <p>
            If you continue to see this message, contact MyAssetMap support at{' '}
            <a href="mailto:support@myassetmap.com">support@myassetmap.com</a>
          </p>
          {refreshPage && (
            <p>
              We will automatically{' '}
              <button 
                onClick={handleRetry}
                className={styles.retryButton}
              >
                refresh this page
              </button>{' '}
              in <span id='refresh-timer'>{seconds}</span> second
              {seconds > 1 ? 's' : ''}.
            </p>
          )}

          <hr />
          
          <div className={styles.errorDetails}>
            <p>
              Error: <em>{error ? error.message : 'No message available'}</em>
            </p>
            <button 
              onClick={copyErrorDetails}
              className={styles.copyButton}
            >
              Copy Error Details
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

// Add prop types
ErrorFallback.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
    // Add other error properties you expect
  }),
  refreshPage: PropTypes.bool,
  resetError: PropTypes.func,
}

ErrorFallback.defaultProps = {
  refreshPage: true,
  error: null,
  resetError: null,
}

export default ErrorFallback
