import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setMapCursor } from '../../../../../actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SaveFeatures from './SaveFeatures'

const Save = ({ geometry, id, saveCount, setSaveCount, drawMode }) => {
  const dispatch = useDispatch()
  const [mode, setMode] = useState('')

  const handleClick = () => {
    setMode('SAVE_FEATURES')
    dispatch(setMapCursor('wait'))
  }

  const finishSave = () => {
    setMode('')
    if (drawMode === 'NEW') {
      dispatch(setMapCursor('crosshair'))
    }
    if (drawMode === 'EDIT') {
      dispatch(setMapCursor('crosshair'))
    }
    if (drawMode === 'PAUSE') {
      dispatch(setMapCursor('grab'))
    }
    setSaveCount(saveCount + 1)
  }

  return (
    <>
      <div
        style={{
          fontSize: mode === 'SAVE_FEATURES' ? '15px' : '13px',
          color: mode === 'SAVE_FEATURES' ? '#f1cb57' : 'white',
        }}
        onClick={() => handleClick()}
        data-tooltip-content='Save Pending Edits'
      >
        <FontAwesomeIcon
          icon={mode === 'SAVE_FEATURES' ? 'spinner' : ['fal', 'check']}
          size='1x'
          spin={mode === 'SAVE_FEATURES' ? true : false}
        />
      </div>
      {mode === 'SAVE_FEATURES' ? (
        <SaveFeatures id={id} geometry={geometry} finishSave={finishSave} />
      ) : null}
    </>
  )
}

export default Save
