import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { validLatitude, validLongitude } from '../../../../utilities/validation'
import ZoomToPoint from '../../../../components/ZoomToPoint/ZoomToPoint'
import {
  createPointFeature,
  addFeatureToSource,
  getSource,
  emptySource,
} from '../../../../utilities/mapStyle'
import { updateMapStyle } from '../../../../actions/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import scss from './Coordinate.scss'
import { fromJS } from 'immutable'

const Coordinate = ({ mapStyle, viewport, updateMapStyle }) => {
  const [results, setResults] = useState([])
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [searching, setSearching] = useState(false)
  const [zoomToPointKey, setZoomToPointKey] = useState(null)
  const [zoomToCoordinates, setZoomToCoordinates] = useState(null)
  const [newZoom, setNewZoom] = useState(null)

  useEffect(() => {
    let style = mapStyle.toJS()
    let source = getSource(style, 'coordiantes_results')
    const initialResults = source.data.features.map(
      feature => feature.geometry.coordinates
    )
    setResults(initialResults)
  }, [mapStyle])

  const handleChange = useCallback(e => {
    let value = e.target.value
    let valid = true
    if (e.target.name === 'latitude') {
      valid = validLatitude(e.target.value)
      if (valid) setLatitude(value)
    } else {
      valid = validLongitude(e.target.value)
      if (e.target.value === '-') {
        valid = true
      }
      if (valid) {
        if (value > 0) value = value * -1
        setLongitude(value)
      }
    }
  }, [])

  const doSearch = useCallback(() => {
    if (latitude === '' || longitude === '') return
    const lat = parseFloat(latitude)
    const lng = parseFloat(longitude)
    const coordinates = [lng, lat]
    setSearching(true)
    doZoomToPoint(coordinates)
    setResults(prevResults => [...prevResults, coordinates])
    setSearching(false)
    setLatitude('')
    setLongitude('')
    updateCoordinateLayer([...results, coordinates])
  }, [latitude, longitude, results])

  const doZoomToPoint = useCallback(coordinates => {
    setZoomToPointKey(prevKey => (prevKey || 0) + 1)
    setZoomToCoordinates(coordinates)
    setNewZoom(14)
  }, [])

  const updateCoordinateLayer = useCallback(
    updatedResults => {
      let style = mapStyle.toJS()
      let source = getSource(style, 'coordiantes_results')
      source = emptySource(source)
      updatedResults.forEach(coordinates => {
        const properties = {
          id: coordinates[0] + coordinates[1],
          latitude: coordinates[0],
          longitude: coordinates[1],
        }
        let feature = createPointFeature(coordinates, properties)
        source = addFeatureToSource(source, feature)
      })
      style.sources['coordiantes_results'] = source
      updateMapStyle(fromJS(style))
    },
    [mapStyle, updateMapStyle]
  )

  const deleteCoordinate = useCallback(
    index => {
      setResults(prevResults => {
        const newResults = [...prevResults]
        newResults.splice(index, 1)
        updateCoordinateLayer(newResults)
        return newResults
      })
    },
    [updateCoordinateLayer]
  )

  const latStyle = {
    border: 'none',
    borderRight: 'solid 1px',
    outline: 'none',
    borderRadius: '4px 0px 0px 4px',
    width: '100%',
    height: '40px',
    padding: '0 12px',
    fontSize: '16px',
    letterSpacing: '-0.43px',
    color: '#fff',
  }

  const lngStyle = {
    border: 'none',
    borderRight: 'solid 1px',
    outline: 'none',
    borderRadius: '0px',
    width: '100%',
    height: '40px',
    padding: '0 12px',
    fontSize: '16px',
    letterSpacing: '-0.43px',
    color: '#fff',
  }

  const resultList = results.map((result, index) => {
    const item = `Latitude: ${result[1]} Longitude: ${result[0]}`
    return (
      <div key={index}>
        <span
          className={scss.coordinateSpan}
          onClick={() => doZoomToPoint(result)}
        >
          <FontAwesomeIcon icon={['fal', 'map-pin']} size='1x' />
          {item}
        </span>
        <div>
          <button className={scss.btn} onClick={() => deleteCoordinate(index)}>
            <FontAwesomeIcon icon={['fal', 'trash-alt']} size='1x' />
          </button>
        </div>
      </div>
    )
  })

  const searchIcon = searching ? (
    <FontAwesomeIcon icon={'spinner'} size='1x' spin />
  ) : (
    <FontAwesomeIcon icon={['fal', 'map-marker']} size='1x' />
  )

  return (
    <div>
      <ZoomToPoint
        key={zoomToPointKey}
        viewport={viewport}
        coordinates={zoomToCoordinates}
        zoom={newZoom}
      />

      <div className={scss.coordinateWrapper}>
        <div className={scss.addOnGroup}>
          <input
            autoComplete='off'
            name='latitude'
            value={latitude}
            onChange={handleChange}
            style={latStyle}
            placeholder='Enter Latitude'
          />
          <input
            autoComplete='off'
            name='longitude'
            value={longitude}
            onChange={handleChange}
            style={lngStyle}
            placeholder='Enter Longitude'
          />
          <button onClick={doSearch} className={scss.coordinateSearchBtn}>
            {' '}
            {searchIcon}
          </button>
        </div>
      </div>
      {resultList.length > 0 ? (
        <div className={scss.resultContainer}>
          <div className={scss.resultList}>{resultList}</div>
        </div>
      ) : null}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    mapStyle: state.mapStyle,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMapStyle: updateMapStyle,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Coordinate)
