import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ThemeProvider } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { MuiFormControlText } from '../../../../../components/MaterialUi/Tool'
import mainModalTheme from '../../../../../utilities/componentConstants/mainModalTheme'

import modalScss from '../../../../CSSModules/Modal.scss'
import appScss from '../../../../App/App.scss'

const SaveLayout = ({ modalOpen, closeModal, doSaveLayout }) => {
  const [layoutName, setLayoutName] = useState('')
  const [doingSave, setDoingSave] = useState(false)

  const handleInputChange = e => {
    setLayoutName(e.target.value)
  }

  const validateLayoutName = name => name !== ''

  const handleSave = () => {
    setDoingSave(true)
    doSaveLayout(layoutName)
  }

  const validLayoutName = validateLayoutName(layoutName)

  return (
    <ThemeProvider theme={mainModalTheme}>
      <Dialog onClose={closeModal} open={modalOpen}>
        <DialogTitle id='save-layout-dialog'>
          Save Layout
          <FontAwesomeIcon
            onClick={closeModal}
            icon='times'
            size='sm'
            pull='right'
          />
        </DialogTitle>

        <DialogContent
          className={[
            modalScss.MuiDialogBoxes,
            appScss.flexColumn,
            modalScss.fieldWidths,
            modalScss.addUserModal,
          ].join(' ')}
        >
          <label htmlFor='layoutName' className={modalScss.label}></label>

          <div>
            <MuiFormControlText
              disabled={false}
              label='Layout Name:'
              placeholder='Layout Name'
              onChange={handleInputChange}
              onSubmit={doingSave ? null : handleSave}
              value={layoutName}
            />
          </div>
        </DialogContent>

        <DialogActions>
          <button
            className={modalScss.modalMainButton}
            onClick={doingSave ? null : handleSave}
            style={{ width: '100%', placeContent: 'center' }}
            disabled={!validLayoutName}
          >
            {doingSave ? (
              <FontAwesomeIcon icon='spinner' size='1x' fixedWidth spin />
            ) : (
              'Save'
            )}
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default SaveLayout
