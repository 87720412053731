/* eslint-disable camelcase */

import React, { useEffect } from 'react'

import {
  // editor
  locale_en_gb,
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,

  // plugins
  setPlugins,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_finetune,
  plugin_finetune_locale_en_gb,
  plugin_finetune_defaults,
  plugin_filter,
  plugin_filter_locale_en_gb,
  plugin_filter_defaults,
  plugin_annotate,
  plugin_annotate_locale_en_gb,
  markup_editor_defaults,
  markup_editor_locale_en_gb,
} from '@pqina/pintura'

import { PinturaEditorModal } from '@pqina/react-pintura'

setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate)

const editorDefaults = {
  utils: ['crop', 'finetune', 'filter', 'annotate'],
  imageReader: createDefaultImageReader(),
  imageWriter: createDefaultImageWriter(),
  shapePreprocessor: createDefaultShapePreprocessor(),
  ...plugin_finetune_defaults,
  ...plugin_filter_defaults,
  ...markup_editor_defaults,
  locale: {
    ...locale_en_gb,
    ...plugin_crop_locale_en_gb,
    ...plugin_finetune_locale_en_gb,
    ...plugin_filter_locale_en_gb,
    ...plugin_annotate_locale_en_gb,
    ...markup_editor_locale_en_gb,
  },
}

const ImageEditor = ({ image, onCancel, onFinish }) => {
  useEffect(() => {
    console.log(image)
  }, [image])

  console.log(image)
  return (
    <PinturaEditorModal
      {...editorDefaults}
      imageCropAspectRatio={1}
      src={image}
      // onLoad={res => console.log('load modal image', res)}
      onHide={() => onCancel()}
      onProcess={({ dest }) => onFinish(dest)}
    />
  )
}

export default ImageEditor
