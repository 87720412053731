import React, { 
  useState, 
  useEffect, 
  useCallback, 
  Suspense,
  useTransition 
} from 'react'
import LogRocket from 'logrocket'
import ErrorFallback from './ErrorFallback.jsx'
import Loading from './Loading'

const ErrorBoundary = ({ refreshPage = true, fallback, children }) => {
  const [hasError, setHasError] = useState(false)
  const [error, setError] = useState(null)
  const [seconds, setSeconds] = useState(5)
  const [isPending, startTransition] = useTransition()
  const [errorLogged, setErrorLogged] = useState(false)

  useEffect(() => {
    let interval
    if (hasError && refreshPage) {
      interval = setInterval(() => {
        setSeconds(prevSeconds => {
          if (prevSeconds === 1) {
            clearInterval(interval)
            window.location.reload() // Refresh the page
          }
          return prevSeconds - 1
        })
      }, 1000)
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [hasError, refreshPage])

  // Use startTransition for non-urgent UI updates
  const handleError = useCallback((error, info) => {
    try {
      const details = info.componentStack.trim().split('\n')
      const errorDetails = {
        code: error.number,
        file: error.fileName,
        line: error.lineNumber,
        message: error.message.toString() + ' ' + details.shift(),
        details,
        timestamp: new Date().toISOString(),
        url: window.location.href,
        userAgent: navigator.userAgent
      }

      // Wrap non-urgent state updates in startTransition
      startTransition(() => {
        setError(errorDetails)
        setHasError(true)
      })

      // Only log to LogRocket if this error hasn't been logged yet
      if (!errorLogged) {
        LogRocket.captureException(error, {
          extra: errorDetails
        })
        setErrorLogged(true)
      }

      // Immediate logging operations (don't need startTransition)
      console.group('Fatal Error Details')
      console.error('Error:', error)
      console.error('Component Stack:', info.componentStack)
      console.error('Additional Details:', errorDetails)
      console.groupEnd()

    } catch (loggingError) {
      console.error('Error while handling error:', loggingError)
    }
  }, [errorLogged])

  // Show loading state during transition
  if (isPending) {
    return <Loading />
  }

  if (hasError) {
    return (
      <Suspense fallback={<Loading />}>
        {fallback || (
          <ErrorFallback 
            refreshPage={refreshPage} 
            error={error}
            seconds={seconds} 
          />
        )}
      </Suspense>
    )
  }

  return (
    <ErrorBoundaryWrapper onError={handleError}>
      <Suspense fallback={<Loading />}>
        {children}
      </Suspense>
    </ErrorBoundaryWrapper>
  )
}

class ErrorBoundaryWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = { 
      hasError: false,
      error: null,
      errorInfo: null
    }
  }

  static getDerivedStateFromError(error) {
    return { 
      hasError: true,
      error 
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      errorInfo
    })
    this.props.onError(error, errorInfo)
  }

  componentWillUnmount() {
    if (this.state.hasError) {
      console.log('ErrorBoundary unmounting, cleaning up...')
    }
  }

  render() {
    if (this.state.hasError) {
      return null // Parent will handle error UI
    }
    return this.props.children
  }
}

export default ErrorBoundary
