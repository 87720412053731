import React, { useState, useEffect, useRef } from 'react'
import mapboxgl from 'mapbox-gl'

// mapboxgl.accessToken = {mapboxApiAccessToken};

const DefaultMapView = () => {
  const [mapState, setMapState] = useState({
    lng: 5,
    lat: 34,
    zoom: 2,
  })

  const mapContainer = useRef(null)
  const map = useRef(null)

  useEffect(() => {
    if (map.current) return // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [mapState.lng, mapState.lat],
      zoom: mapState.zoom,
    })

    map.current.on('move', () => {
      setMapState({
        lng: map.current.getCenter().lng.toFixed(4),
        lat: map.current.getCenter().lat.toFixed(4),
        zoom: map.current.getZoom().toFixed(2),
      })
    })

    return () => map.current.remove()
  }, [])

  return (
    <div>
      <div className='sidebarStyle'>
        <div>
          Longitude: {mapState.lng} | Latitude: {mapState.lat} | Zoom:{' '}
          {mapState.zoom}
        </div>
      </div>
      <div ref={mapContainer} className='mapContainer' />
    </div>
  )
}

export default DefaultMapView
