/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'react-tooltip'
import { useSelector } from 'react-redux'
import { apis } from '../../../../config/apiConfig'
import AsyncFetch from '../../../../utilities/AsyncFetch'
import LabelStyle from './LabelStyle/LabelStyle'
import appScss from '../../../App/App.scss'
import scss from './LayerLabel.scss'
import PropertySelector from './LabelStyle/PropertySelector'
import SaveLabels from './LabelStyle/SaveLabels'

const LayerLabel = React.memo(({ config, setDisplayManageAttributesMenu }) => {
  const [fetchObjects, setFetchObjects] = useState(null)
  const [propertyList, setPropertyList] = useState(null)

  const [propertyCount, setPropertyCount] = useState([1])
  const [selectedProperty, setSelectedProperty] = useState({
    key: 'none',
    value: 'none',
    name: 'Disable Labels',
  })
  const [selectedZoom, setSelectedZoom] = useState(15)

  const [displaySaveButtonOnMenu, setDisplaySaveButtonOnMenu] = useState(false)
  const mapStyle = useSelector(state => state.mapStyle)
  const style = mapStyle.toJS()

  const [isCommonLayer, setIsCommonLayer] = useState(false)

  const getProperties = () => {
    const layerId = config.layer.layersArray[0].layer.id
    const method = 'POST'
    const url = apis.apiDatabase.uri + 'layer/properties/get'
    const body = {
      layerID: layerId,
      includeIntrinsicGeom: true,
    }
    setFetchObjects([{ url, body, method }])
  }

  const symbolLayerId = config.layer.layersArray[0].symbol.id
  const layerId = config.layer.layersArray[0].layer.id
  const layer = style.layers.filter(layer => layer.id === symbolLayerId)

  const fetchFinished = results => {
    if (results && results[0] && results[0].success && results[0].data) {
      const props = results[0].data.map(property => property)
      props.unshift({ key: 'mamid', value: 'mamid', name: 'MAM ID' })
      props.sort((a, b) => (a.key > b.key ? 1 : -1))
      props.unshift({ key: 'none', value: 'none', name: 'Disable Labels' })
      setPropertyList(props)
    }
  }

  useEffect(() => {
    getProperties()
    setDisplayManageAttributesMenu(false)
  }, [])

  useEffect(() => {
    if (
      typeof config.layer.layersArray[0].layer.metadata.commonLayer !==
        'undefined' &&
      config.layer.layersArray[0].layer.metadata.commonLayer
    ) {
      setIsCommonLayer(true)
      setSelectedZoom(7)
    }
  }, [])

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <div
        className={[scss.attributeDataTitle, scss.attributeJustify].join(' ')}
        style={{ marginTop: '0' }}
      >
        <div className={scss.container}>
          <span>Layer Labels</span>
          <FontAwesomeIcon
            icon='question-circle'
            size='1x'
            data-tooltip-content={`Labels will be dynamically created based upon the value of the selected property.  
            Labels are visible when zoomed in past the selected Zoom`}
            data-tooltip-id='data-driven'
            data-effect='solid'
            data-place='right'
            data-arrow-color='rgba(0, 0, 0, 0.68)'
          />
          <div className={appScss.reactToolTip}>
            <Tooltip id='data-driven' getContent={dataTip => `${dataTip}`} />
          </div>
          {propertyList ? (
            <PropertySelector
              config={config}
              propertyList={propertyList}
              selectedZoom={selectedZoom}
              setSelectedZoom={setSelectedZoom}
              selectedProperty={selectedProperty}
              setSelectedProperty={setSelectedProperty}
              setDisplaySaveButtonOnMenu={setDisplaySaveButtonOnMenu}
            />
          ) : (
            <div>
              <FontAwesomeIcon icon='spinner' size='1x' spin />
            </div>
          )}
        </div>
      </div>
      {selectedProperty.key !== 'none' && !isCommonLayer ? (
        <LabelStyle
          config={config}
          selectedZoom={selectedZoom}
          setDisplaySaveButtonOnMenu={setDisplaySaveButtonOnMenu}
        />
      ) : null}

      {displaySaveButtonOnMenu && !isCommonLayer ? (
        <SaveLabels
          config={config}
          layerId={layerId}
          layer={layer[0]}
          setDisplaySaveButtonOnMenu={setDisplaySaveButtonOnMenu}
        />
      ) : null}
    </>
  )
})

export default LayerLabel
