import React from 'react'
import { useSelector } from 'react-redux'
import {
  getExpressionType,
  getZoomBasedValue,
  getDataBasedValue,
  getSwatchLabel,
} from './Utilities'
import { hexToRgba } from '../../../../utilities/formatters'
import scss from './LegendSwatch.scss'
import appScss from '../../../App/App.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'react-tooltip'

const CircleSwatch = ({
  layer,
  singleItemTitles,
  hideLayerHeader = null,
  zoomLabel,
}) => {
  const viewport = useSelector(state => state.viewport)
  const getProperty = circleProp => {
    if (Array.isArray(circleProp)) {
      const expressionType = getExpressionType(circleProp)
      if (expressionType === 'zoomBased') {
        return getZoomBasedValue(circleProp, viewport)
      }
      if (expressionType === 'dataBased') {
        return getDataBasedValue(circleProp)
      }
    } else {
      return circleProp
    }
  }

  const getCircleStyle = (radius, fillColor, width, opacity) => {
    const outlineRgbaColor = hexToRgba('#ffffff', 1)
    if (!fillColor) {
      fillColor = '#ffffff'
    }
    const fillRgbaColor = hexToRgba(fillColor, opacity)
    let borderColor = outlineRgbaColor
    let borderWidth = 1
    let border = `${borderWidth}px solid ${borderColor}`
    return {
      width: width,
      height: width,
      border: border,
      borderRadius: '50%',
      backgroundColor: fillRgbaColor,
    }
  }

  const getCircleSwatch = (radius, color, width, height, opacity, label) => {
    let expressionValues = []
    let radiusObject = {}
    let colorObject = {}
    let widthObject = {}
    let heightObject = {}
    let opacityObject = {}
    if (Array.isArray(radius)) {
      radius.forEach(arr => {
        radiusObject[arr[0]] = arr[1]
      })
      let radiusValues = radius.map(arr => arr[0])
      expressionValues = [...expressionValues, ...radiusValues]
    }
    if (Array.isArray(color)) {
      color.forEach(arr => {
        colorObject[arr[0]] = arr[1]
      })
      let colorValues = color.map(arr => arr[0])
      expressionValues = [...expressionValues, ...colorValues]
    }
    if (Array.isArray(width)) {
      width.forEach(arr => {
        widthObject[arr[0]] = arr[1]
      })
      let widthValues = width.map(arr => arr[0])
      expressionValues = [...expressionValues, ...widthValues]
    }
    if (Array.isArray(opacity)) {
      opacity.forEach(arr => {
        opacityObject[arr[0]] = arr[1]
      })
      let opacityValues = opacity.map(arr => arr[0])
      expressionValues = [...expressionValues, ...opacityValues]
    }

    let uniqueExpressionValues = [...new Set(expressionValues)]

    // If the expression contains the default value and the list has more than one element,
    // we push the default element to the beginning of the list
    if (
      uniqueExpressionValues.includes('default') &&
      uniqueExpressionValues.length &&
      uniqueExpressionValues.length > 1
    ) {
      const currentDefaultIndex = uniqueExpressionValues.indexOf('default')

      const arrayMove = (arr, oldIndex, newIndex) => {
        if (newIndex >= arr.length) {
          let k = newIndex - arr.length + 1
          // eslint-disable-next-line no-plusplus
          while (k--) {
            arr.push(undefined)
          }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
        return arr
      }

      uniqueExpressionValues = arrayMove(
        uniqueExpressionValues,
        currentDefaultIndex,
        0
      )
    }

    let swatchItems = []
    if (uniqueExpressionValues.length) {
      // the legend contains databased style
      const items = uniqueExpressionValues.map(value => {
        let itemRadius =
          radiusObject[value] || radiusObject['default'] || radius
        let itemColor = colorObject[value] || colorObject['default'] || color
        let itemWidth = widthObject[value] || widthObject['default'] || width
        let itemOpacity =
          opacityObject[value] || opacityObject['default'] || opacity
        const style = getCircleStyle(
          itemRadius,
          itemColor,
          itemWidth,
          itemOpacity
        )
        return (
          <li key={value}>
            <div className={scss.expressionItem}>
              <div>{value === 'default' ? 'Default' : value}</div>
              {itemOpacity !== 'default' && itemOpacity < 0.15 ? (
                <>
                  <div className={scss.expressionItemCaution}>
                    <FontAwesomeIcon
                      icon='exclamation-triangle'
                      size='1x'
                      data-tooltip-content={
                        'Fill is below 15% opacity, style may not be visible'
                      }
                      data-tooltip-id='opacity-warning'
                      data-effect='solid'
                      data-arrow-color='rgba(0, 0, 0, 0.68)'
                    />
                    <div
                      className={scss.expressionItemCircle}
                      style={style}
                    ></div>
                    <div className={appScss.reactToolTip}>
                      <Tooltip
                        id='opacity-warning'
                        getContent={dataTip => `${dataTip}`}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className={scss.expressionItemCircle} style={style}></div>
              )}
            </div>
          </li>
        )
      })
      swatchItems.push(
        <ul key={uniqueExpressionValues.join(',')} className={scss.swatchList}>
          {hideLayerHeader ? null : (
            <div className={scss.expressionItemTitle}>
              <span>{label}</span>
              <span>Style</span>
            </div>
          )}
          {items}
        </ul>
      )
    } else {
      const style = getCircleStyle(radius, color, width, opacity, height)

      let defaultOpacity = 1
      if ((!opacity || opacity < 0.15) && !color) defaultOpacity = undefined

      if (singleItemTitles) {
        swatchItems.push(
          <ul key={layer.id + '_legendItem'} className={scss.swatchList}>
            <li>
              <div className={scss.expressionItem}>
                {/* <div> {singleItemTitles && label} </div> */}
                <div> {singleItemTitles && zoomLabel} </div>
                {!defaultOpacity ? (
                  <>
                    <div className={scss.expressionItemCaution}>
                      <FontAwesomeIcon
                        icon='exclamation-triangle'
                        size='1x'
                        data-tooltip-content={
                          'Fill is below 15% opacity, style may not be visible'
                        }
                        data-tooltip-id='opacity-warning'
                        data-effect='solid'
                        data-arrow-color='rgba(0, 0, 0, 0.68)'
                      />
                      <div
                        className={scss.expressionItemCircle}
                        style={style}
                      ></div>
                    </div>
                  </>
                ) : (
                  <div
                    className={scss.expressionItemCircle}
                    style={style}
                  ></div>
                )}
              </div>
            </li>
          </ul>
        )
      } else {
        swatchItems.push(
          <ul key={layer.id + '_legendItem'} className={scss.swatchList}>
            <li>
              <div className={scss.expressionItem}>
                {/* <div>Standard Style</div> */}
                {/* <div>{label}</div> */}
                <div>{zoomLabel}</div>
                {!defaultOpacity ? (
                  <>
                    <div className={scss.expressionItemCaution}>
                      <FontAwesomeIcon
                        icon='exclamation-triangle'
                        size='1x'
                        data-tooltip-content={
                          'Fill is below 15% opacity, style may not be visible'
                        }
                        data-tooltip-id='opacity-warning'
                        data-effect='solid'
                        data-arrow-color='rgba(0, 0, 0, 0.68)'
                      />
                      <div
                        className={scss.expressionItemCircle}
                        style={style}
                      ></div>
                      <div className={appScss.reactToolTip}>
                        <Tooltip
                          id='opacity-warning'
                          getContent={dataTip => `${dataTip}`}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    className={scss.expressionItemCircle}
                    style={style}
                  ></div>
                )}
              </div>
            </li>
          </ul>
        )
      }
    }

    return swatchItems
  }
  let label = getSwatchLabel(layer)
  let radius = getProperty(layer.paint['circle-radius'])
  let color = getProperty(layer.paint['circle-color'])
  let width = getProperty(layer.paint['circle-width'])
  let opacity = getProperty(layer.paint['circle-opacity'])
  let swatch = getCircleSwatch(radius, color, opacity, label)

  return <>{swatch}</>
}

export default CircleSwatch
