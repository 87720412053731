/* eslint-disable react/jsx-filename-extension */
import React, { useState, memo, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Tooltip } from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AsyncFetch from '../../utilities/AsyncFetch'
import Measure from '../Tools/FeatureMeasure/Measure'
import { apis } from '../../config/apiConfig'
import { getBBox } from '../../utilities/geospatial'
import ZoomToBounds from '../../components/ZoomToBounds/ZoomToBounds'
import scss from './PopupHeader.scss'
import appScss from '../App/App.scss'

import * as utils from '../../utilities/util'
import { buildMeasureId, checkForFeature } from '../Tools/FeatureMeasure/Utils'

const AttributeMeasureButtons = ({
  mamId,
  featureProperties,
  closePopup,
  popupInfo,
  index,
  layerId,
  dataConfigLayer,
  active,
  layerProperties,
}) => {
  const viewport = useSelector(state => state.viewport)
  const mapStyle = useSelector(state => state.mapStyle)

  let geomType = ''
  if (typeof popupInfo.features[index] !== 'undefined')
    geomType = popupInfo.features[index].geometry.type

  const [measureType, setMeasureType] = useState(null)
  const [activeMeasureType, setActiveMeasureType] = useState([])
  const [measurementFeature, setMeasurementFeature] = useState(null)
  const [urlObjects, setUrlObjects] = useState(null)
  const [fetchingMeasurements, setFetchingMeasurements] = useState(false)
  const [fetchingBounds, setFetchingBounds] = useState(null)
  const [zoomToIndex, setZoomIndex] = useState(null)
  const [zoomToBoundsKey, setZoomToBoundsKey] = useState(null)
  const [zoomToBounds, setZoomToBounds] = useState(null)
  const [fetchObjects, setFetchObjects] = useState(null)

  const buildFetchParams = () => {
    const featureId = popupInfo.features[index].properties.mamid
    const method = 'POST'
    const url = apis.apiDatabase.uri + 'layer/geojson/get'
    const body = {
      layerID: layerId,
      featureID: featureId,
    }

    setFetchingBounds(true)
    setFetchObjects([{ url, method, body }])
  }

  const zoomToPopupFeature = () => {
    buildFetchParams()
  }

  const doZoomToBounds = feature => {
    if (!feature.geometry.type === 'Polygon') return
    let newZoomIndex = zoomToIndex || 1
    const bounds = getBBox(feature)
    const formattedBounds = [
      [bounds[0], bounds[1]],
      [bounds[2], bounds[3]],
    ]
    const zoomToBoundsKeyJson = JSON.stringify(formattedBounds) + newZoomIndex
    newZoomIndex += 1
    setZoomIndex(newZoomIndex)
    setZoomToBounds(formattedBounds)
    setZoomToBoundsKey(zoomToBoundsKeyJson)
  }

  const apiFinished = results => {
    results.forEach(result => {
      if (result.success && result.data.features[0]) {
        doZoomToBounds(result.data.features[0])
      }

      setFetchingBounds(false)
      setFetchObjects(null)
    })
  }

  const getMeasurement = mType => {
    setMeasureType(mType)
    setActiveMeasureType(utils.toggleVal(activeMeasureType, mType[0]))

    const featureId = popupInfo.features[index].properties.mamid
    const method = 'POST'
    const url = apis.apiDatabase.uri + 'layer/geojson/get'
    const body = {
      layerID: layerId,
      featureID: featureId,
    }
    setFetchingMeasurements(true)
    setUrlObjects([{ url, body, method }])
  }

  const measurementFetchFinished = results => {
    results.forEach(result => {
      setMeasurementFeature(result.data.features[0])
      setFetchingMeasurements(false)
      setUrlObjects(null)
    })
  }

  const fetchFinished = results => {
    if (fetchingMeasurements) {
      measurementFetchFinished(results)
    }
  }

  const measureTypeList = [
    'coordinates',
    'lineSegments',
    'lineLength',
    'areaSegments',
    'area',
  ]

  useEffect(() => {
    const activeList = measureTypeList
      .map(mType => {
        const enabled = checkForFeature(
          mapStyle,
          buildMeasureId(mamId, layerId, mType)
        )
        return enabled.length ? mType : null
      })
      .filter(item => item !== null)
    setActiveMeasureType(activeList)
  }, [mapStyle])

  const loadingSpinner = (
    <button type='button' onClick={null}>
      <FontAwesomeIcon icon='spinner' size='1x' spin />
    </button>
  )

  const buildTooltip = tooltipID => {
    return (
      <div className={appScss.reactToolTip}>
        <Tooltip id={tooltipID} getContent={dataTip => `${dataTip}`} />
      </div>
    )
  }

  const buildButton = ({
    id: buttonID,
    name: buttonName,
    icon: buttonIcon,
  }) => {
    return (
      <>
        <button
          type='button'
          className={activeMeasureType.includes(buttonID) ? scss.active : ''}
          onClick={() => getMeasurement([buttonID])}
          data-tooltip-content={buttonName}
          data-tooltip-id={buttonID}
          data-effect='solid'
          data-arrow-color='rgba(0, 0, 0, 0.68)'
        >
          <FontAwesomeIcon icon={buttonIcon} size='1x' />
        </button>
        {buildTooltip(buttonID)}
      </>
    )
  }

  const userPopupButtons =
    dataConfigLayer && dataConfigLayer.toc && dataConfigLayer.toc.userLayer ? (
      <div className={scss.measurementButtonRow}>
        {geomType === 'Point'
          ? fetchingMeasurements
            ? loadingSpinner
            : buildButton({
                id: 'coordinates',
                name: 'Coordinates',
                icon: ['fal', 'globe'],
              })
          : null}

        {geomType === 'LineString' ? (
          fetchingMeasurements ? (
            <>
              {loadingSpinner}
              {loadingSpinner}
            </>
          ) : (
            <>
              {buildButton({
                id: 'lineSegments',
                name: 'Line Segments',
                icon: 'ruler-combined',
              })}
              {buildButton({
                id: 'lineLength',
                name: 'Line Length',
                icon: 'ruler',
              })}
            </>
          )
        ) : null}

        {geomType === 'Polygon' ? (
          fetchingMeasurements ? (
            <>
              {loadingSpinner}
              {loadingSpinner}
            </>
          ) : (
            <>
              {buildButton({
                id: 'areaSegments',
                name: 'Lineal Dimensions',
                icon: 'ruler',
              })}
              {buildButton({
                id: 'area',
                name: 'Areal Dimensions',
                icon: 'ruler-combined',
              })}
            </>
          )
        ) : null}

        {fetchingBounds ? (
          loadingSpinner
        ) : (
          <>
            <button
              type='button'
              onClick={zoomToPopupFeature}
              data-tooltip-content='Zoom'
              data-tooltip-id='zoom'
              data-effect='solid'
              data-arrow-color='rgba(0, 0, 0, 0.68)'
            >
              <FontAwesomeIcon icon={['fas', 'arrows-alt']} size='1x' />
            </button>
            <div className={appScss.reactToolTip}>
              <Tooltip id='zoom' getContent={dataTip => `${dataTip}`} />
            </div>
          </>
        )}
      </div>
    ) : null

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={apiFinished} />
      )}
      <ZoomToBounds
        key={zoomToBoundsKey}
        viewport={viewport}
        bounds={zoomToBounds}
      />
      {urlObjects && (
        <AsyncFetch fetchObjects={urlObjects} fetchFinished={fetchFinished} />
      )}
      {measurementFeature && (
        <Measure
          layerId={layerId}
          targetFeature={measurementFeature}
          measureType={measureType}
        />
      )}
      {userPopupButtons}
    </>
  )
}
export default memo(AttributeMeasureButtons)
