import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import { apis } from '../../config/apiConfig'
import scss from './Profile.scss'
import planScss from './ProfileStyling/Plan.scss'
import billingScss from './ProfileStyling/Billing.scss'
import appScss from '../App/App.scss'

import * as utils from '../../utilities/util'

import ProfileImage from './Popups/profileImage'
import ProfileName from './Popups/profileName'
import ProfileEmail from './Popups/profileEmail'
// import ProfileLanguage from "./Popups/profileLanguage";
import ProfileOrganization from './Popups/profileOrganization'
import OrganizationImage from './Popups/organizationImage'
import ProfileManagers from './Popups/profileManagers'
import ProfilePlan from './Popups/profilePlan'
import ProfileAddons from './Popups/profileAddons'
import ProfilePayment from './Popups/profilePayment'
import ProfileBilling from './Popups/profileBilling'
import ProfilePeriod from './Popups/profilePeriod'
import TimeZoneListOptions from './TimeZoneList'
import Moment from 'moment'

import { switchPage, Login as _Login, Logout as _Logout } from '../../actions'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import AsyncFetch from '../../utilities/AsyncFetch'
import ProfileAddress from './Popups/ProfileAddress'

import { Alert } from '../../actions'

export default function Profile() {
  const user = useSelector(state => state.user)

  const dateFormat = user.profile.dateFormat
  const locale = user.profile.locale
  const timezone = user.profile.timezone

  const account = utils.objectKeyVal(user.accountID, user.accounts, 'id')

  // ========================
  // = PROFILE POPUP STATES =
  // ========================
  const [profileImageVisible, setProfileImageVisible] = useState(false)
  const [profileNameVisible, setProfileNameVisible] = useState(false)
  const [profileEmailVisible, setProfileEmailVisible] = useState(false)
  const [profileOrganizationVisible, setProfileOrganizationVisible] =
    useState(false)
  const [organizationImageVisible, setOrganizationImageVisible] =
    useState(false)
  const [addressChangeVisible, setAddressChangeVisible] = useState(false)
  const [shippingAddressChangeVisible, setShippingAddressChangeVisible] =
    useState(false)

  /* ------------------- PROFILE PLANNING AND BILLING STATES ------------------ */

  const [profileAddonsVisible, setProfileAddonsVisible] = useState(false)
  const [profilePlanVisible, setProfilePlanVisible] = useState(false)
  const [profilePaymentVisible, setProfilePaymentVisible] = useState(false)
  const [profilePeriodVisible, setProfilePeriodVisible] = useState(false)
  const [profileBillingVisible, setProfileBillingVisible] = useState(false)
  /* -------------------------------------------------------------------------- */

  // const toggleTimezone = () => {
  //   if(document.getElementById("timezoneToggle").name=="timezoneToggleOff") {
  //     document.getElementById("timezoneToggle").name="timezoneToggleOn";
  //     document.getElementById("timezoneToggle").classList.remove('timezoneButtonOff');
  //     document.getElementById("timezoneToggle").classList.add('timezoneButtonOn');
  //   } else if (document.getElementById("timezoneToggle").name=="timezoneToggleOn") {
  //     document.getElementById("timezoneToggle").name="timezoneToggleOff";
  //     document.getElementById("timezoneToggle").classList.remove('timezoneButtonOn');
  //     document.getElementById("timezoneToggle").classList.add('timezoneButtonOff');
  //   }
  // }

  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [addonsFetchObjects, setAddonsFetchObjects] = useState(null)
  const [addonsFetching, setAddonsFetching] = useState(false)
  const [stripeHasMore, setStripeHasMore] = useState(null)
  // const [addons, setAddons] = useState(null)

  const [stripeFetchObjects, setStripeFetchObjects] = useState(null)
  const [stripeFetching, setStripeFetching] = useState(false)

  const [customer, setCustomer] = useState(null)

  // * STRIPE STATE
  const [subscription, setSubscription] = useState(null)
  const [activeAddons, setActiveAddons] = useState(null)
  const [plan, setPlan] = useState(null)
  const [invoiceFetchObjects, setInvoiceFetchObjects] = useState(null)
  const [invoices, setInvoices] = useState(null)
  const [invoiceFetching, setInvoiceFetching] = useState(false)

  // Fields and their States
  const profile = useSelector(state => state.profile)
  const [settings, setSettings] = useState(dateFormat)
  const [languageState, setLanguage] = useState(locale)
  const [timezoneState, setTimezone] = useState(timezone)
  const [profileImgURL, setProfileImgURL] = useState(
    'https://my-asset-map-data.s3.amazonaws.com/public/client_data/static/images/user.png'
  )
  const [accountImgURL, setAccountImgURL] = useState(
    'https://my-asset-map-data.s3.amazonaws.com/public/client_data/static/images/account.png'
  )
  const dispatch = useDispatch()

  const buildFetchParams = (id, val) => {
    const url = apis.apiDatabase.uri + 'user/update'
    const method = 'POST'
    let body = {}

    if (id === 'dateFormat') {
      body = { dateFormat: val }
    } else if (id === 'language') {
      body = { locale: val }
    } else if (id === 'timezone') {
      body = { timezone: val }
    }

    setFetchObjects([{ url, method, body }])
    setFetching(true)
  }

  const parseSubscriptionObject = sub => {
    const subObject = {
      id: sub.id,
      currentPeriod: {
        start: sub.current_period_start,
        end: sub.current_period_end,
      },
      items: sub.items,
      status: sub.status,
    }

    // expose the nicknames for items
    subObject.items.data.forEach(item => {
      item.name = item.price.nickname
    })

    // add sub metadata
    if (Object.keys(sub.metadata).length) subObject.metadata = sub.metadata

    // add sub trial period if in a trial
    if (sub.status === 'trialing')
      subObject.trial = {
        start: Moment.unix(sub.trial_start).format('MMMM Do YYYY'),
        end: Moment.unix(sub.trial_end).format('MMMM Do YYYY'),
      }
    setSubscription(subObject)
  }

  const parseAddons = data => {
    if (typeof data === 'object') data = Object.values(data)
    const addonsArray = data.filter(obj => obj.items.length)
    setActiveAddons(addonsArray)
  }

  // INITIAL STRIPE & API CALL
  const stripeFinished = results => {
    console.warn('stripe', results)
    results.forEach(res => {
      if (utils.verifyResult(res)) {
        // * success
        if (res.data.data) {
          // ? Stripe data
          if (res.data.data.length === 0) {
            return dispatch(
              Alert({
                error: 'Stripe Billing: No MyAssetMap subscription was found',
              })
            )
          }
          if (res.data.data.length > 1) {
            return dispatch(
              Alert({
                error:
                  'Stripe Billing; More than one MyAssetMap subscription was found for customer',
              })
            )
          }
          const [sub] = res.data.data

          parseSubscriptionObject(sub)
        } else if (res.data.object === 'customer') {
          // ? Customer
          setCustomer(res.data)
        } else {
          // ? Addon data
          const { data } = res
          parseAddons(data)
        }
      } else {
        // !success
        const { message } = res.data.raw
        dispatch(Alert({ error: message }))
      }
    })
    setStripeFetchObjects(null)
    setStripeFetching(false)
    setAddonsFetching(false)
  }

  const invoiceFetchFinished = results => {
    results.forEach(res => {
      if (utils.verifyResult(res)) {
        const invoicesObject = res.data
        if (invoicesObject.has_more) setStripeHasMore(true)
        if (!invoicesObject.has_more) setStripeHasMore(false)

        const oldInvoices = invoices || null
        if (oldInvoices) {
          setInvoices([...oldInvoices, ...invoicesObject.data])
        } else setInvoices(invoicesObject.data)
      }
    })
    setInvoiceFetchObjects(null)
    setInvoiceFetching(false)
  }

  const fetchFinished = results => {
    return results.map(result => {
      setSettings(result)
      setFetchObjects(null)
      setFetching(false)
    })
  }

  const loadInvoices = (lastInvoice = null) => {
    const url = apis.apiDatabase.uri + 'stripe/invoices'
    const method = 'POST'
    let body

    if (lastInvoice) {
      body = { customerID: account.stripeCustomerID, loadAfter: lastInvoice.id }
    } else {
      body = { customerID: account.stripeCustomerID, limit: 3 }
    }
    setInvoiceFetching(true)
    setInvoiceFetchObjects([{ url, method, body }])
  }

  const handlePlanPrice = (plan, planQuantity) => {
    if (plan.tiers) return utils.getStripePlanPrice(plan, planQuantity)

    return parseFloat(((plan.amount / 100) * planQuantity).toFixed(2))
  }

  const onSettingsChange = e => {
    setSettings(e.target.value)
    const dFormat = e.target.value
    buildFetchParams('dateFormat', dFormat)
  }

  // LANGUAGE
  const onLanguageChange = e => {
    setLanguage(e.target.value)
    const language = e.target.value
    buildFetchParams('language', language)
  }

  // TIMEZONE
  const onTimezoneChange = e => {
    setTimezone(e.target.value)
    const tZone = e.target.value
    buildFetchParams('timezone', tZone)
  }

  const getDate = dateString => {
    return Moment.unix(dateString).format('MMMM Do YYYY')
  }

  const getAddons = addons => {
    return addons.map(addon => (
      <div className={planScss.profilePlanAddonsName}>
        {addon.name}
        <br />
        {addon.items.map(item => (
          <>
            <span className={planScss.profilePlanAddonsItemName}>
              {item.name},
            </span>
            <br />
          </>
        ))}
      </div>
    ))
  }

  // dateFormat Function
  const dateFormats = ['MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY-MM-DD', 'YYYY-DD-MM']
  const dateFormatItems = []
  for (var [index, value] of dateFormats.entries())
    dateFormatItems.push(
      <option key={'date_' + index} value={value}>
        {value}
      </option>
    )

  // Language Function
  const languages = { en_US: 'English (US)' }
  const languageItems = []
  for ([index, value] of Object.entries(languages))
    languageItems.push(
      <option key={'language_' + index} value={index}>
        {value}
      </option>
    )

  //Profile Images
  useEffect(() => {
    async function getProfileImage() {
      const imgURL = await utils.getImage(user.profile.profileImg, 'user.png')

      setProfileImgURL(imgURL)
    }
    getProfileImage()
  }, [user])

  //Account Images
  useEffect(() => {
    async function getAccountImage() {
      const imgURL = await utils.getImage(account.profileImg, 'account.png')

      setAccountImgURL(imgURL)
    }
    getAccountImage()

    if (user.permissions.billing) {
      let url, method, body
      const params = []

      url = apis.apiDatabase.uri + 'stripe/subscription'
      method = 'POST'
      body = { customerID: account.stripeCustomerID }
      params.push({ url, method, body })

      url = apis.apiDatabase.uri + 'addons/account'
      method = 'POST'
      body = { accountID: account.id }
      params.push({ url, method, body })

      url = apis.apiDatabase.uri + 'stripe/customer'
      method = 'POST'
      body = { customerID: account.stripeCustomerID }
      params.push({ url, method, body })

      setStripeFetching(true)
      setAddonsFetching(true)
      setStripeFetchObjects(params)
      loadInvoices()
    }
  }, [user])

  useEffect(() => {
    if (subscription) {
      console.info(subscription.items.data)
      const [subPlan] = subscription.items.data.filter(
        item => item.name && item.name.includes('MyAssetMap')
      )
      if (subPlan) {
        subPlan.plan.quantity = subPlan.quantity
        setPlan(subPlan.plan)
      }
    }
  }, [subscription])

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      {stripeFetchObjects && (
        <AsyncFetch
          fetchObjects={stripeFetchObjects}
          fetchFinished={stripeFinished}
        />
      )}
      {invoiceFetchObjects && (
        <AsyncFetch
          fetchObjects={invoiceFetchObjects}
          fetchFinished={invoiceFetchFinished}
        />
      )}
      <Tabs className={scss.userProfile}>
        <h1>Manage Profile & Account</h1>
        <TabList>
          <Tab selectedClassName={scss.activeTab}>Profile</Tab>
          <Tab selectedClassName={scss.activeTab}>Account</Tab>
          {user.permissions.billing && (
            <>
              <Tab selectedClassName={scss.activeTab}>Plan</Tab>
              <Tab selectedClassName={scss.activeTab}>Billing</Tab>
            </>
          )}
        </TabList>

        <TabPanel>
          <div className={scss.mainMap}>
            <div className={scss.profileSection}>
              <h3 className={scss.profileLeft}>Basics</h3>

              <p>
                <span className={scss.profileLeft}>Photo</span>
                <span className={scss.profileRight}></span>

                {profileImageVisible && (
                  <ProfileImage
                    profileImg={user.profile.profileImg}
                    visible={setProfileImageVisible}
                  />
                )}
                <span className={scss.profileRight}>
                  <a onClick={() => setProfileImageVisible(true)}>
                    <img src={profileImgURL} className={scss.profilePic}></img>
                    <span style={{ cursor: 'pointer' }}>Edit</span>
                  </a>
                  <a
                    style={{ cursor: 'pointer' }}
                    className={scss.profileHide}
                    href='#'
                  >
                    Delete
                  </a>
                </span>
              </p>

              {profileNameVisible && (
                <ProfileName
                  firstName={user.profile.firstName}
                  lastName={user.profile.lastName}
                  visible={setProfileNameVisible}
                />
              )}
              <p>
                <span className={scss.profileLeft}>Name</span>
                <span className={scss.profileRight}>
                  <span className={scss.profileNormal}>
                    {user.profile.displayName}
                  </span>{' '}
                  <a
                    style={{ cursor: 'pointer' }}
                    onClick={() => setProfileNameVisible(true)}
                  >
                    Edit
                  </a>
                </span>
              </p>

              {profileEmailVisible && (
                <ProfileEmail
                  emailAddress={user.profile.emailAddress}
                  visible={setProfileEmailVisible}
                />
              )}
              <p>
                <span className={scss.profileLeft}>Email</span>
                <span className={scss.profileRight}>
                  <span className={scss.profileNormal}>
                    {user.profile.emailAddress}
                  </span>
                  <a
                    style={{ cursor: 'pointer' }}
                    onClick={() => setProfileEmailVisible(true)}
                    className={scss.profileHide}
                  >
                    Edit
                  </a>
                </span>
              </p>
            </div>

            <div className={scss.profileSection}>
              <h3 className={scss.profileLeft}>Preferences</h3>

              <p>
                <span className={scss.profileLeft}>Language</span>
                <span className={scss.profileRight}>
                  <select
                    onChange={onLanguageChange}
                    className={[appScss.selectLight, scss.dateFormat].join(' ')}
                    defaultValue={languageState}
                  >
                    {languageItems}
                  </select>
                </span>
              </p>
              <p>
                <span className={scss.profileLeft}>Date Format</span>
                <span className={scss.profileRight}>
                  <select
                    onChange={onSettingsChange}
                    className={[appScss.selectLight, scss.dateFormat].join(' ')}
                    defaultValue={settings}
                  >
                    {dateFormatItems}
                  </select>
                </span>
              </p>
              <div className={scss.profileCheck}>
                <span className={scss.profileLeft}>Time Zone</span>
                <span className={scss.profileRight}>
                  <select
                    onChange={onTimezoneChange}
                    className={[appScss.selectLight, scss.dateFormat].join(' ')}
                    defaultValue={timezoneState}
                  >
                    <TimeZoneListOptions />
                  </select>
                  {/* <span className={scss.timezone}></span>
                      <div className={scss.switchZone}>
                      <form action="">
                      <input type="button" id="timezoneToggle" value="&#9632;" name="timezoneToggleOn" className={scss.timezoneButtonOn} onClick={toggleTimezone}></input>
                      </form>
                      </div> */}
                </span>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel>
          <div className={scss.mainMap}>
            <div className={scss.profileSection}>
              <h3 className={scss.profileLeft}>Basics</h3>

              {organizationImageVisible && (
                <OrganizationImage
                  organizationImg={account.profileImg}
                  visible={setOrganizationImageVisible}
                />
              )}
              <p>
                <span className={scss.profileLeft}>Logo</span>
                <span className={scss.profileRight}></span>
                <span className={scss.profileRight}>
                  <img src={accountImgURL} className={scss.profilePic}></img>
                  <a
                    style={{ cursor: 'pointer' }}
                    onClick={() => setOrganizationImageVisible(true)}
                  >
                    Edit
                  </a>{' '}
                  <a
                    style={{ cursor: 'pointer' }}
                    className={scss.profileHide}
                    href='#'
                  >
                    Delete
                  </a>
                </span>
              </p>

              {profileOrganizationVisible && (
                <ProfileOrganization
                  organizationName={account.name}
                  visible={setProfileOrganizationVisible}
                />
              )}
              <p>
                <span className={scss.profileLeft}>Name</span>
                <span className={scss.profileRight}>
                  <span className={scss.profileNormal}>{account.name}</span>{' '}
                  <a
                    style={{ cursor: 'pointer' }}
                    onClick={() => setProfileOrganizationVisible(true)}
                  >
                    Edit
                  </a>
                </span>
              </p>
            </div>
          </div>
        </TabPanel>

        {user.permissions.billing && (
          <>
            <TabPanel>
              {plan ? (
                <>
                  <div className={planScss.profilePlan}>
                    <div className={planScss.profilePlanInfo}>
                      <div>
                        <div className={planScss.profilePlanCompanyImage}>
                          <img src={accountImgURL}></img>
                        </div>
                        <div>
                          <span className={planScss.profilePlanInfoHeading}>
                            {account.name} - {plan.quantity} Users
                          </span>
                          {/* <p>Your plan renews on {gespanate(planPeriod.end)}.</p> */}
                          <p>
                            ${handlePlanPrice(plan, plan.quantity)} per{' '}
                            {plan.interval}.{' '}
                            {/* <a href='#'>See plan details </a>or */}
                          </p>
                          {/* <p>
                        <a href='#'>Change billing period</a>
                      </p> */}
                        </div>
                      </div>
                      <div>
                        <button
                          onClick={() => setProfilePlanVisible(true)}
                          className={scss.profileButton}
                        >
                          Change Plan
                        </button>
                      </div>
                    </div>
                    {/* COMING SOON
                <div className={planScss.profilePlanUsers}>
                  <div className={planScss.profilePlanUsersLeft}>
                    <span>Current Users</span>
                  </div>
                  <div className={planScss.profilePlanUsersRight}>
                    <a>Assign to Users</a>
                  </div>
                </div> */}
                    <div className={planScss.profilePlanAddons}>
                      <span>Add-ons</span>
                      <div>
                        <div className={planScss.profileAddonsContainer}>
                          <span className={planScss.profilePlanAddonsHeading}>
                            Active:
                          </span>
                          <div className={planScss.profileAddonsList}>
                            {!addonsFetching ? (
                              activeAddons ? (
                                getAddons(activeAddons)
                              ) : null
                            ) : (
                              <tr>
                                <span
                                  colspan='4'
                                  className={[
                                    scss.profileTableLast,
                                    scss.profileTableLinks,
                                    scss.placeholderLoading,
                                  ].join(' ')}
                                >
                                  Loading...
                                </span>
                              </tr>
                            )}
                          </div>
                        </div>
                        <div className={planScss.profileAddonsButton}>
                          <button
                            onClick={() => setProfileAddonsVisible(true)}
                            className={scss.profileButton}
                          >
                            Purchase More
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {profilePlanVisible && (
                    <ProfilePlan
                      customerID={account.stripeCustomerID}
                      subscription={subscription}
                      profilePlan={plan}
                      visible={setProfilePlanVisible}
                      organizationImg={accountImgURL}
                      organizationName={account.name}
                      updateProfile={newSub => parseSubscriptionObject(newSub)}
                    />
                  )}

                  {profileAddonsVisible && (
                    <ProfileAddons
                      profilePlan={plan}
                      customer={customer}
                      subscription={subscription}
                      visible={setProfileAddonsVisible}
                      addons={activeAddons}
                      updateProfile={newData => parseAddons(newData)}
                    />
                  )}
                </>
              ) : (
                <div className={billingScss.noResults}>
                  <FontAwesomeIcon icon={['fal', 'search']} />
                  <FontAwesomeIcon icon={'question'} />
                  <span className={billingScss.noResultsHeading}>
                    An Error Occured
                  </span>
                  <span className={billingScss.noResultsParagraph}>
                    Try refreshing your page
                  </span>
                </div>
              )}
            </TabPanel>
            <TabPanel>
              {customer && invoices ? (
                <div className={billingScss.profileBilling}>
                  {/* Payment Method */}
                  <div
                    className={[
                      billingScss.profileBillingText,
                      billingScss.flexRow,
                      billingScss.flexCenter,
                      billingScss.flexJustify,
                    ].join(' ')}
                  >
                    <span>Payment Method</span>
                    {customer.sources.length && (
                      <span>
                        {customer.sources.data[0].brand} ending in{' '}
                        {customer.sources.data[0].last4}, exp.{' '}
                        {customer.sources.data[0]['exp_month']}/
                        {customer.sources.data[0]['exp_year']}
                      </span>
                    )}
                    {/* COMING SOON
                <a onClick={() => setProfilePaymentVisible(true)}>Update</a> */}
                  </div>

                  {/* Billing Period */}
                  <div
                    className={[
                      billingScss.profileBillingText,
                      billingScss.flexRow,
                      billingScss.flexCenter,
                      billingScss.flexJustify,
                    ].join(' ')}
                  >
                    <span>Billing Period</span>
                    <span>
                      Plan Billed{' '}
                      {plan && plan.interval === 'month'
                        ? 'Monthly'
                        : plan && plan.interval === 'year'
                        ? 'Yearly'
                        : null}
                      {/*COMING SOON
                  <a onClick={() => setProfilePeriodVisible(true)}>Change</a> */}
                    </span>
                  </div>

                  {/* Billing Info */}
                  <div
                    className={[
                      billingScss.profileBillingText,
                      billingScss.flexRow,
                      billingScss.flexCenter,
                      billingScss.flexJustify,
                    ].join(' ')}
                  >
                    <span>Billing Information</span>
                    <div
                      className={[
                        billingScss.flexRow,
                        billingScss.flexCenter,
                      ].join(' ')}
                    >
                      {customer.address && (
                        <span>
                          {customer.address.line1}
                          <br />
                          {customer.address.line2
                            ? customer.address.line2
                            : null}
                          <br />
                          {customer.address.city}, {customer.address.state}
                          <br />
                          {customer.address['postal_code']}
                        </span>
                      )}
                      <div>
                        <a onClick={() => setAddressChangeVisible(true)}>
                          Change
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* Shipping Info */}
                  <div
                    className={[
                      billingScss.profileBillingText,
                      billingScss.flexRow,
                      billingScss.flexCenter,
                      billingScss.flexJustify,
                    ].join(' ')}
                  >
                    <span>Shipping Information</span>
                    <div
                      className={[
                        billingScss.flexRow,
                        billingScss.flexCenter,
                      ].join(' ')}
                    >
                      {customer.shipping.address.line1 !== '' &&
                        customer.shipping.address !== null && (
                          <span>
                            {customer.shipping.address.line1}
                            <br />
                            {customer.shipping.address.line2
                              ? customer.shipping.address.line2
                              : null}
                            <br />
                            {customer.shipping.address.city},{' '}
                            {customer.shipping.address.state}
                            <br />
                            {customer.shipping.address['postal_code']}
                          </span>
                        )}
                      <div>
                        <a
                          onClick={() => setShippingAddressChangeVisible(true)}
                        >
                          Change
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* Data Usage */}
                  {/* ----------------------------- COMING SOON ----------------------------- */}
                  {/* <div
                  className={[
                    billingScss.profileBillingText,
                    billingScss.flexColumn,
                  ].join(' ')}
                >
                  <div
                    className={[
                      billingScss.flexRow,
                      billingScss.flexCenter,
                      billingScss.flexJustify,
                    ].join(' ')}
                  >
                    <span>Data Usage</span>
                    <span>
                      Using{' '}
                      <span className={billingScss.profileBillingBold}>5GB</span>{' '}
                      of 40GB
                    </span>
                  </div>
                  <div className={billingScss.profileBillingBar}></div>
                  <div className={billingScss.profileBillingBarFill}></div>
                </div> */}

                  <div
                    className={billingScss.flexColumn}
                    style={{ marginTop: '44px' }}
                  >
                    <span>Billing History</span>
                    <span className={billingScss.profileBillingSubText}>
                      View invoices and receipts for all payments made on your
                      My Asset Map account.
                    </span>
                  </div>

                  <div className={billingScss.profileBillingTableTop}>
                    <span>Date</span>
                    <span>Description</span>
                    <span></span>
                    <span>Amount</span>
                  </div>
                  <div className={billingScss.profileBillingTable}>
                    {invoices.map(obj => {
                      const lineItems = obj.lines.data
                      const receipt = obj['hosted_invoice_url']
                      const invoice = obj['invoice_pdf']
                      return (
                        <div
                          className={billingScss.profileBillingTableRows}
                          key={obj.id}
                        >
                          <span>{getDate(obj.date)}</span>
                          <span>
                            {lineItems.map(item => (
                              <>
                                <span>{item.description}</span>
                                <br />
                              </>
                            ))}
                          </span>
                          <span className={scss.profileTableLinks}>
                            <a href={invoice} target='_blank'>
                              Invoice
                            </a>{' '}
                            |{' '}
                            <a href={receipt} target='_blank'>
                              Receipt
                            </a>
                          </span>
                          <span>${obj['amount_due'] / 100}</span>
                        </div>
                      )
                    })}
                  </div>
                  <div className={billingScss.profileBillingTableBottom}>
                    {!invoiceFetching ? (
                      stripeHasMore ? (
                        <a
                          onClick={() =>
                            loadInvoices(invoices[invoices.length - 1])
                          }
                          className={scss.profileTableText}
                        >
                          Show more...
                        </a>
                      ) : null
                    ) : (
                      'Loading...'
                    )}
                  </div>
                </div>
              ) : (
                <div className={billingScss.noResults}>
                  <FontAwesomeIcon icon={['fal', 'search']} />
                  <FontAwesomeIcon icon={'question'} />
                  <span className={billingScss.noResultsHeading}>
                    An Error Occured
                  </span>
                  <span className={billingScss.noResultsParagraph}>
                    Try refreshing your page
                  </span>
                </div>
              )}

              {profilePaymentVisible && (
                <ProfilePayment
                  organizationName={user.profile.payment}
                  visible={setProfilePaymentVisible}
                />
              )}

              {addressChangeVisible && (
                <ProfileAddress
                  customerID={account.stripeCustomerID}
                  type='billing'
                  onClose={() => setAddressChangeVisible(false)}
                  updateProfile={newCustomerObj => setCustomer(newCustomerObj)}
                />
              )}

              {shippingAddressChangeVisible && (
                <ProfileAddress
                  customerID={account.stripeCustomerID}
                  type='shipping'
                  onClose={() => setAddressChangeVisible(false)}
                  updateProfile={newCustomerObj => setCustomer(newCustomerObj)}
                />
              )}

              {profileBillingVisible && (
                <ProfileBilling
                  organizationName={user.profile.billing}
                  organizationID={account.id}
                  visible={setProfileBillingVisible}
                />
              )}

              {profilePeriodVisible && (
                <ProfilePeriod
                  organizationName={user.profile.billing}
                  organizationID={account.id}
                  visible={setProfilePeriodVisible}
                />
              )}
            </TabPanel>
          </>
        )}
      </Tabs>
    </>
  )
}
