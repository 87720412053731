import React, { useState, useEffect, useCallback } from 'react'

const MouseTooltip = ({
  visible = true,
  offsetX = 0,
  offsetY = 0,
  className,
  style,
  children,
}) => {
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [mouseMoved, setMouseMoved] = useState(false)
  const [listenerActive, setListenerActive] = useState(false)

  const getTooltipPosition = useCallback(({ clientX, clientY }) => {
    setPosition({ x: clientX, y: clientY })
    setMouseMoved(true)
  }, [])

  const addListener = useCallback(() => {
    window.addEventListener('mousemove', getTooltipPosition)
    setListenerActive(true)
  }, [getTooltipPosition])

  const removeListener = useCallback(() => {
    window.removeEventListener('mousemove', getTooltipPosition)
    setListenerActive(false)
  }, [getTooltipPosition])

  useEffect(() => {
    if (visible && !listenerActive) {
      addListener()
    } else if (!visible && listenerActive) {
      removeListener()
    }

    return () => {
      if (listenerActive) {
        removeListener()
      }
    }
  }, [visible, listenerActive, addListener, removeListener])

  return (
    <div
      id='mouse-tool-tip'
      className={className}
      style={{
        display: visible && mouseMoved ? 'block' : 'none',
        position: 'fixed',
        top: position.y + offsetY,
        left: position.x + offsetX,
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export default MouseTooltip
