import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  toggleMapLayer,
  toggleMapLayersOn,
  layerClicked,
} from '../../../../actions/index'
import ZoomToBounds from '../../../../components/ZoomToBounds/ZoomToBounds'
import ZoomToPoint from '../../../../components/ZoomToPoint/ZoomToPoint'
import LayerZoomLevel from './LayerZoomLevel'
import logoSimplified from '../../../../assets/logo/logo-simplified.svg'
import organizationLayerGroup from '../../../../assets/icons/organizationLayerGroup.svg'
import sharedLayerGroup from '../../../../assets/icons/sharedLayerGroup.svg'
import userLayerGroup from '../../../../assets/icons/userLayerGroup.svg'
import appScss from '../../../App/App.scss'
import scss from '../../Toc.scss'
import { getBBox } from '../../../../utilities/geospatial'
import useClickOrDoubleClick from '../../../../utilities/Hooks/useClickOrDoubleClick'
import * as geoUtils from '../../../../utilities/geospatial'

const LayerButton = React.memo(({ layerIds, layer, filtered, labeled }) => {
  const dispatch = useDispatch()
  const visibleLayers = useSelector(state => state.visibleLayers)
  const handleClickRef = useRef()

  const [activeClass, setActiveClass] = useState('')
  const [activeIcon, setActiveIcon] = useState('eye-slash')
  const [zoomToBounds, setZoomToBounds] = useState(null)
  const [zoomToPoint, setZoomToPoint] = useState(null)
  const [layerShare, setLayerShare] = useState('')
  const [layerStyle, setLayerStyle] = useState({ width: '30px' })
  const [disabled, setDisabled] = useState(false)

  useClickOrDoubleClick({
    /** A callback function for single click events */
    onSingleClick: e => {
      let visibleTocLayers = [...visibleLayers]
      let ids = [].concat(...layerIds)
      ids.forEach(id => {
        if (visibleLayers.includes(id)) {
          visibleTocLayers = visibleTocLayers.filter(layerId => layerId !== id)
        } else {
          visibleTocLayers.push(id)
        }
      })
      dispatch(toggleMapLayer(ids))
      dispatch(layerClicked())
    },
    /** A callback function for double click events */
    onDoubleClick: e => {
      let bounds = layer.layersArray[0]
        ? layer.layersArray[0].layer.metadata.bounds
        : null

      if (bounds === 'Invalid JSON on Box') {
        // bounds was not calculated on the server (this happens on raster layers)
        // check if the layer source is type image
        // if true, create bounds from image coordinates
        // Note:  This following was cahnged on TOC refactor
        // to reduce over rendering, mapStyle was removed from this component
        // the souce used is now coming from the layer object (from the data config)
        // this should work fine, but if we allow image editing in the future this will need to be updated
        let source = layer.sourcesArray[0]

        if (source && source.type === 'image') {
          let coords = source.coordinates
          coords.push(source.coordinates[0])
          bounds = [coords]
        }
      }

      if (!bounds) return
      let visibleTocLayers = [...visibleLayers]
      let ids = [].concat(...layerIds)
      ids.forEach(id => {
        if (!visibleTocLayers.includes(id)) {
          visibleTocLayers.push(id)
        }
      })

      if (Array.isArray(bounds)) {
        let boundsPoly = {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [],
          },
        }
        if (bounds.length === 2) {
          if (!Array.isArray(bounds[0]) && !Array.isArray(bounds[1])) {
            // single point
            doZoomToPoint(bounds)
          } else {
            // two points, need to create poly
            const pointA = geoUtils.getPoint(bounds[0])
            const pointB = geoUtils.getPoint(bounds[1])
            const featureCollection = geoUtils.getFeatureCollection([
              pointA,
              pointB,
            ])
            const bbox = geoUtils.getBBox(featureCollection)
            const bboxPoly = geoUtils.getBBoxPoly(bbox)
            doZoomToBounds(bboxPoly)
          }
        }
        if (bounds.length === 1) {
          if (Array.isArray(bounds[0])) {
            // bounds is polygon
            boundsPoly.geometry.coordinates = bounds
            doZoomToBounds(boundsPoly)
          }
        }
      }
      dispatch(toggleMapLayersOn(ids))
      dispatch(layerClicked())
    },
    /** (Required) Dom node to watch for double clicks */
    ref: handleClickRef,
    /**
     * The amount of time (in milliseconds) to wait
     * before differentiating a single from a double click
     */
    latency: 250,
  })

  const doZoomToPoint = coordinates => {
    const zoomToPointParams = {
      coordinates: coordinates,
      zoom: 16.5,
    }
    setZoomToPoint(zoomToPointParams)
  }

  const doZoomToBounds = feature => {
    const bounds = getBBox(feature)
    const layerBounds = [
      [bounds[0], bounds[1]],
      [bounds[2], bounds[3]],
    ]
    setZoomToBounds(layerBounds)
  }

  useEffect(() => {
    if (zoomToPoint) {
      setZoomToPoint(null)
    }
    if (zoomToBounds) {
      setZoomToBounds(null)
    }
  }, [zoomToBounds, zoomToPoint])

  useEffect(() => {
    let sharedType = layer.toc.type

    if (sharedType === 'user') {
      setLayerShare(userLayerGroup)
      setLayerStyle({ width: '25px', marginLeft: '-6px' })
    } else if (sharedType === 'shared') {
      setLayerShare(sharedLayerGroup)
      setLayerStyle({ width: '20px' })
    } else if (sharedType === 'org') {
      setLayerShare(organizationLayerGroup)
      setLayerStyle({ width: '23px', marginLeft: '-4.5px' })
    } else if (sharedType === 'mam') {
      setLayerShare(logoSimplified)
      setLayerStyle({ width: '14px', marginRight: '4px' })
    } else {
      setLayerShare(logoSimplified)
      setLayerStyle({ width: '14px', marginRight: '4px' })
    }
  }, [])

  let showLoadingIcon = false
  // Note if the TOC layer is of type raster (but not an imagery layer), it is an image layer
  // Image layers need to be loaded from S3 before they are useable
  // check if the layer is loaded, if not show the loading icon
  /*
  if (layer.layersArray[0] && layer.layersArray[0].layer.type === "raster") {
    if (sourceLoaded && !sourceLoaded.includes(layer.toc.id)) {
      showLoadingIcon = true;
    }
  }
  */

  useEffect(() => {
    let isVisible = false
    visibleLayers.forEach(visibleLayerObj => {
      if (visibleLayerObj.layersArray)
        visibleLayerObj.layersArray.forEach(visibleLayer => {
          if (layerIds.includes(visibleLayer.layer.id)) {
            isVisible = true
          }
        })
    })
    setActiveClass(isVisible ? 'active' : '')
    setActiveIcon(isVisible ? 'eye' : 'eye-slash')
  }, [visibleLayers])

  return (
    <>
      {zoomToPoint && (
        <ZoomToPoint
          coordinates={zoomToPoint.coordinates}
          zoom={zoomToPoint.zoom}
        />
      )}
      {zoomToBounds && <ZoomToBounds bounds={zoomToBounds} />}
      <LayerZoomLevel layer={layer} setDisabled={setDisabled} />
      <div
        key={layerIds}
        value={layerIds}
        ref={handleClickRef}
        className={scss.layerBtnWrapper}
      >
        <button
          className={[
            scss[disabled ? '' : activeClass],
            scss[disabled ? 'disabled' : ''],
          ].join(' ')}
          style={{ margin: '0.2px 0px' }}
        >
          <div
            data-tooltip-id={layerIds.join(',') + '_tocLabelIcon'}
            data-tooltip-content={layer.toc.label}
            className={
              activeClass == 'active'
                ? scss.tocLabelIcon
                : [scss.tocLabelIcon, scss.tocLabelIconActive].join(' ')
            }
          >
            <FontAwesomeIcon
              className={scss.toggleIcon}
              icon={disabled ? 'ban' : activeIcon}
              size='xs'
              fixedWidth
            />
          </div>
          {disabled && (
            <div className={appScss.reactToolTip}>
              <Tooltip
                id={layerIds.join(',') + '_tocLabelIcon'}
                getContent={() => `Layer not available at current zoom`}
                place='right'
              />
            </div>
          )}
        </button>

        <div
          className={[
            scss.tocButton,
            scss[disabled ? '' : activeClass],
            scss[disabled ? 'disabled' : ''],
            scss.titleWrapper,
          ].join(' ')}
          style={{ margin: '0.2px 0px' }}
        >
          <div className={scss.tocLabelText}>
            <img src={layerShare} style={layerStyle}></img>
            <span>{layer.toc.label}</span>
            {labeled && (
              <span>
                &nbsp;
                <FontAwesomeIcon icon={['fal', 'tag']} size='xs' />
              </span>
            )}
            {filtered && (
              <span>
                &nbsp;
                <FontAwesomeIcon icon={['fal', 'filter']} size='xs' />
              </span>
            )}
            {showLoadingIcon && (
              <span>
                &nbsp;
                <FontAwesomeIcon
                  icon={['fa', 'spinner']}
                  spin={true}
                  size='xs'
                />
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  )
})

export default LayerButton
