import React, { 
  useState, 
  useEffect, 
  useCallback, 
  useTransition, 
  Suspense, 
} from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import Sidebar from 'react-sidebar'
import LogRocket from 'logrocket'
import { clarity } from 'react-microsoft-clarity'
import TagManager from 'react-gtm-module'
import * as Sentry from '@sentry/react'
import { LicenseManager } from 'ag-grid-enterprise'
import environment from '_environments'
import { Auth } from 'aws-amplify'
import { useDispatch, useSelector } from 'react-redux'

// Lazy load route components
import Map from '../Map/Map'
import Debug from '../Profile/Debug'
import Profile from '../Profile/Profile'
import Layers from '../Profile/Layers'
import Maps from '../Profile/Maps'
import Users from '../Profile/Users'
import Accounts from '../Profile/Accounts'
import Toc from '../Toc/Toc.jsx'

// Import non-lazy components
import Footer from '../Footer/Footer'
import Login from '../Login/Login'
import Reset from '../Login/Reset'
import Verify from '../Login/Verify'
import Loading from './Loading'
import ErrorBoundary from './ErrorBoundary.jsx'
import Error404 from '../Error404'
import MakeDataConfigComponent from '../../utilities/dataConfig'
import { createCredentials, loginInitialize } from '../../utilities/user'
import ProfileSidebar from '../Profile/Sidebar'
import Alert from '../../components/Alert'
import scss from './App.scss'

// Action Handlers
import {
  Login as _Login,
  Logout as _Logout,
  updateDataConfig as _updateDataConfig,
  setToc as _setToc,
  Alert as _Alert,
} from '../../actions'

import { keys } from '../../config/keyConfig'
import authConfig from '../../config/authConfig'
import * as utils from '../../utilities/util'

const App = () => {
  const [isPending, startTransition] = useTransition()
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const dataConfig = useSelector(state => state.updateDataConfig)
  const tocCollapsed = useSelector(state => state.tocCollapsed)
  const accountKey = useSelector(state => state.accountKey)

  const [state, setState] = useState({
    isAuthenticating: true,
    fetchingDataConfig: false,
    mountMakeDataConfig: false,
    sidebarOpen: false,
    sidebarTransitions: true,
    helpWidgetActive: false,
  })

  useEffect(() => {
    const script = document.createElement('script')
    script.src = `https://maps.googleapis.com/maps/api/js?key=${keys.google}&libraries=places`
    document.head.append(script)
  
    // Set environment tag once
    Sentry.setTag('env', environment.env)
  
    Auth.currentSession()
      .then(result => {
        createCredentials().then(async () => {
          try {
            const user = await loginInitialize(result.idToken.payload)
            dispatch(_Login(user))
            
            if (!environment.isDev) {
              // LogRocket initialization
              LogRocket.init('myassetmap/myassetmap')
              LogRocket.identify(user.UUID, {
                name: user.profile.displayName,
                email: user.profile.emailAddress,
                accountID: user.accountID,
                mapID: user.mapID,
              })

              // Sentry user configuration
              Sentry.setUser({
                name: user.profile.displayName,
                email: user.profile.emailAddress,
              })
              Sentry.setTag('accountID', user.accountID)
              Sentry.setTag('mapID', user.mapID)
            }
            
            setState(prev => ({ ...prev, isAuthenticating: false }))
          } catch (error) {
            console.error('Login', error)
            dispatch(_Alert({ error: error.message }))
          }
        })
      })
      .catch(e => {
        setState(prev => ({ ...prev, isAuthenticating: false }))
        if (e !== 'No current user') {
          utils.showMessage('Session Auth Error', e.message)
        }
      })
  
    dispatch(_setToc(false))
    LicenseManager.setLicenseKey(keys.agGrid)
  }, [dispatch])
  

  // Batch state updates for data config
  useEffect(() => {
    if (!state.fetchingDataConfig && user && user.isLoggedIn) {
      setState(prev => ({ 
        ...prev, 
        fetchingDataConfig: true, 
        mountMakeDataConfig: true 
      }))
      setTimeout(() => {
        setState(prev => ({ ...prev, mountMakeDataConfig: false }))
      }, 0)
    }
  }, [user, state.fetchingDataConfig])

  const handleLogout = useCallback(() => {
    Auth.signOut()
      .then(() => {
        console.log('Logged out')
        dispatch(_Logout())
      })
      .catch(e => {
        console.error('Logout Fail: ' + e.message)
        utils.showMessage('Logout Failed', e.message)
      })
  }, [dispatch])

  const handleLoginAttempt = useCallback(
    async (success = false, userPayload = '') => {
      if (success) {
        try {
          const user = await loginInitialize(userPayload)
          dispatch(_Login(user))
        } catch (error) {
          console.error('Login', error)
          dispatch(_Alert({ error: error.message }))
        }
      } else {
        dispatch(_Logout())
      }
    },
    [dispatch]
  )
  

  const setSidebarOpen = useCallback((open) => {
    setState(prev => ({ ...prev, sidebarOpen: open }))
  }, [])

  const setSidebarTransitions = useCallback((trans) => {
    setState(prev => ({ ...prev, sidebarTransitions: trans }))
  }, [])

  const getMapUi = useCallback((config) => {
    let showMapLoading = true
    let showMap = false
    if (config) {
      showMap = true
      showMapLoading = false
    }

    const tocWidth = tocCollapsed ? '72px' : 'auto'
    const containerStyle = {
      gridTemplateColumns: `${tocWidth} 1fr`,
    }

    return (
      <Suspense fallback={<Loading />}>
        <>
          {isPending && <Loading />}
          {state.mountMakeDataConfig && (
            <MakeDataConfigComponent
              onFinish={() => console.log('dataConfig done')}
              updateStyle={false}
            />
          )}

          {showMap && (
            <div className={scss.sidebarHolder}>
              <Sidebar
                sidebar={
                  <ProfileSidebar
                    setToc={(collapsed) => dispatch(_setToc(collapsed))}
                    setSidebarOpen={setSidebarOpen}
                    setSidebarTransitions={setSidebarTransitions}
                    logout={handleLogout}
                  />
                }
                transitions={state.sidebarTransitions}
                open={state.sidebarOpen}
                onSetOpen={setSidebarOpen}
                styles={{
                  root: { zIndex: 2 },
                  sidebar: {
                    background: '#1A2937',
                    borderRadius: '0px 10px 10px 0px',
                  },
                }}
              >
                <div style={containerStyle} className={scss.container}>
                  <Suspense fallback={<Loading />}>
                    <Switch>
                      <Route
                        exact
                        path='/(debug|profile|layers|maps|teams|users|accounts)/'
                        render={() => <ProfileSidebar logout={handleLogout} />}
                      />
                      <Route
                        exact
                        path='/'
                        render={props => (
                          <Toc
                            {...props}
                            setSidebarOpen={setSidebarOpen}
                            dataConfig={config}
                          />
                        )}
                      />
                      <Route render={props => <Error404 {...props} type='map' />} />
                    </Switch>

                    <main id='main' className={scss.main}>
                      <Suspense fallback={<Loading />}>
                        <Switch>
                          <Route exact path='/debug' render={() => <Debug />} />
                          <Route exact path='/profile' render={() => <Profile />} />
                          <Route exact path='/layers' render={() => <Layers />} />
                          <Route exact path='/maps' render={() => <Maps />} />
                          <Route exact path='/users' render={() => <Users />} />
                          <Route exact path='/accounts' render={() => <Accounts />} />
                          <Route
                            path='/'
                            render={props => (
                              <>
                                <Map
                                  key={accountKey}
                                  {...props}
                                  dataConfig={config}
                                  noPreferences='noPreferences'
                                />
                              </>
                            )}
                          />
                        </Switch>
                      </Suspense>
                    </main>
                    <footer id='footer' className={scss.footer}>
                      <Footer />
                    </footer>
                  </Suspense>
                </div>
              </Sidebar>
            </div>
          )}

          {showMapLoading && <Loading />}
        </>
      </Suspense>
    )
  }, [state.mountMakeDataConfig, state.sidebarOpen, state.sidebarTransitions, tocCollapsed, user, handleLogout, dispatch, isPending])

  const getLoginUi = useCallback(() => {
    if (state.isAuthenticating) return <Loading />

    return (
      <Switch>
        <div style={{ width: '100%', margin: '15% 0' }}>
          <Route
            exact
            path='/'
            render={props => (
              <Login {...props} handleLoginAttempt={handleLoginAttempt} />
            )}
          />
          <Route
            path='/login'
            render={props => (
              <Login {...props} handleLoginAttempt={handleLoginAttempt} />
            )}
          />
          <Route
            path='/logout'
            render={props => (
              <Login {...props} handleLoginAttempt={handleLoginAttempt} />
            )}
          />
          <Route
            path='/reset'
            render={props => (
              <Reset {...props} handleLoginAttempt={handleLoginAttempt} />
            )}
          />
          <Route
            path='/verify'
            render={props => (
              <Verify {...props} handleLoginAttempt={handleLoginAttempt} />
            )}
          />
          <Route
            path='/(debug|profile|layers|maps|teams|users|accounts)/'
            render={props => (
              <Login {...props} handleLoginAttempt={handleLoginAttempt} />
            )}
          />
          <Route render={props => <Error404 {...props} type='login' />} />
        </div>
      </Switch>
    )
  }, [state.isAuthenticating, handleLoginAttempt])

  const build = useCallback(() => {
    const isLocalEnv = window.location.hostname === 'localhost' || 
                      window.location.hostname === '127.0.0.1'

    if (!isLocalEnv) {
      clarity.init(authConfig.clarity.projectId)
      TagManager.initialize({ gtmId: 'GTM-WQNXNHRQ' })
    } else {
      console.warn('Disabling Clarity & GTM due to localhost env.')
    }

    if (!user) {
      return getLoginUi()
    }

    if (user && user.isLoggedIn) {
      if (!isLocalEnv) {
        clarity.identify(user.UUID, {
          name: user.profile.displayName,
          email: user.profile.emailAddress,
          accountID: user.accountID,
          mapID: user.mapID,
        })
        clarity.setTag('env', environment.env)
      }

      if (window.location.pathname === '/verify') {
        return window.location.replace('/')
      }

      if (user.accounts && user.accounts.length > 0) {
        return getMapUi(dataConfig)
      }

      return (
        <div className='text-center pad-lg'>
          <p>It looks like you are not a part of any accounts.</p>
          <p>
            Please talk to your account administrator and have them add you to
            an account.
          </p>
        </div>
      )
    }
    return getLoginUi()
  }, [user, dataConfig, getLoginUi, getMapUi])

  const isLocalEnv = window.location.hostname === 'localhost' || 
                    window.location.hostname === '127.0.0.1'

  return (
    <ErrorBoundary refreshPage={!isLocalEnv}>
      <Suspense fallback={<Loading />}>
        {isPending && <Loading />}
        {build()}
      </Suspense>
    </ErrorBoundary>
  )
}

App.propTypes = {
  user: PropTypes.shape({
    accounts: PropTypes.arrayOf(PropTypes.shape({})),
    isLoggedIn: PropTypes.bool.isRequired,
    accountID: PropTypes.number,
    mapID: PropTypes.number,
    UUID: PropTypes.string.isRequired,
    profile: PropTypes.shape({
      displayName: PropTypes.string,
      emailAddress: PropTypes.string,
    }).isRequired,
  }),
  SetToc: PropTypes.func.isRequired,
  Login: PropTypes.func.isRequired,
  Logout: PropTypes.func.isRequired,
  tocCollapsed: PropTypes.bool.isRequired,
  accountKey: PropTypes.string.isRequired,
  dataConfig: PropTypes.shape({}),
}

export default App
