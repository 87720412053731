import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Tooltip } from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Storage } from 'aws-amplify'
import AsyncFetch from '../../../utilities/AsyncFetch'
import appScss from '../../App/App.scss'
import { apis } from '../../../config/apiConfig'

const ExportSelectedFeatures = ({
  targetLayer,
  exportAs = '.shp',
  disabled = false,
}) => {
  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)

  const mapStyle = useSelector(state => state.mapStyle)
  const mapRef = useSelector(state => state.mapRef)
  const exportBounds = useSelector(state => state.exportSettings.bounds)

  const handleDownload = sourcePath => {
    sourcePath = sourcePath.replace('public/', '')

    Storage.get(`${sourcePath}`, { expires: 60 })
      .then(result => {
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        a.download = true
        a.href = result
        a.target = '_blank'
        a.click()
        document.body.removeChild(a)
      })
      .catch(err => console.log('Download File Error:', err))
  }

  const exportLayer = () => {
    const style = mapStyle.toJS()
    const mamIds = style.sources.selected_features.data.features.map(
      feature => feature.properties.mamid
    )

    // Build bounds
    const map = mapRef.getMap()
    const bounds = map.getBounds()

    const method = 'POST'
    const url = apis.apiDatabase.uri + 'layer/export'
    const body = {
      layerID: targetLayer.value,
      layerName: targetLayer.label,
      featureIDs: mamIds,
      exportAs,
    }

    if (exportBounds) {
      body.bounds = [
        bounds._sw.lng,
        bounds._sw.lat,
        bounds._ne.lng,
        bounds._ne.lat,
      ]
    }
    setFetching(true)
    setFetchObjects([{ url, method, body }])
  }

  const fetchFinished = results => {
    handleDownload(results[0].data)
    setFetchObjects(null)
    setFetching(false)
  }

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <button
        onClick={fetching || disabled ? null : exportLayer}
        className={disabled ? appScss['disabled-button'] : null}
        data-tooltip-content='Export Selected Features to SHP file'
        data-tooltip-id='export-selected-features'
        data-effect='solid'
        data-arrow-color='rgba(0, 0, 0, 0.68)'
        data-place='top'
      >
        {fetching ? (
          <FontAwesomeIcon icon='spinner' size='1x' spin />
        ) : (
          <FontAwesomeIcon icon={['fa', 'download']} size='1x' />
        )}
      </button>
      <div className={appScss.reactToolTip}>
        <Tooltip
          id='export-selected-features'
          getContent={dataTip => `${dataTip}`}
        />
      </div>
    </>
  )
}

export default ExportSelectedFeatures
