import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Auth } from 'aws-amplify'
import { Tabs } from 'react-tabs'
import { Tooltip } from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
// utilities
import * as utils from '../../utilities/util'
import AsyncFetch from '../../utilities/AsyncFetch'
import ProfileDate from './ProfileDate'
import { apis } from '../../config/apiConfig'
import { Login as _Login, Logout as _Logout, Alert } from '../../actions'

// components
import UserDeleteModal from './UserDeleteModal'
import UsersAddUserModal from './Popups/UsersAddUserModal'
import AddUserModal from './Popups/addUserModal'
import ProfileImage from './Popups/profileImage'

// scss files
import scss from './Users.scss'
import appScss from '../App/App.scss'
import profileScss from './Profile.scss'
import dropdownScss from '../CSSModules/Dropdown.scss'

export default function Users() {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)

  const account = utils.objectKeyVal(user.accountID, user.accounts, 'id')

  const [userLoading, setUserLoading] = useState(false)
  const [userFetchObjects, setUserFetchObjects] = useState(null)
  const [users, setUsers] = useState([])

  const [showDropdown, setDropdown] = useState(false)

  const [roleLoading, setRoleLoading] = useState(false)
  const [roleFetchObjects, setRoleFetchObjects] = useState(null)
  const [roles, setRoles] = useState([])

  const [addUserVisible, setAddUserVisible] = useState(false)
  const [editUserVisible, setEditUserVisible] = useState(false)
  const [profileImageVisible, setProfileImageVisible] = useState(false)

  const [userUpdateObjects, setUserUpdateObjects] = useState(null)
  const [subtractUserVisible, setSubtractUserVisible] = useState(false)
  const [deleteUserModalVisibleKey, setDeleteUserModalVisibleKey] =
    useState(null)

  const [userImageURLs, setUserImageURLs] = useState([])

  //USER FETCH
  const updateUsers = () => {
    const method = 'POST'
    let url = apis['apiDatabase'].uri + 'account/users'
    let body = {
      accountID: user.accountID,
    }

    setUserFetchObjects([{ url, method, body }])
    setUserLoading(true)
  }

  const userFetchFinished = async results => {
    return results.map(result => {
      if (utils.verifyResult(result)) {
        setUsers(utils.resultReturn(result))

        getUserURLS(utils.resultReturn(result))
        setUserLoading(false)
      }

      setUserFetchObjects(null)
    })
  }

  //ROLE FETCH
  const updateRoles = () => {
    const method = 'GET'
    let url = apis.apiDatabase.uri + 'roles'
    let body = {}

    setRoleFetchObjects([{ url, method, body }])
    setRoleLoading(true)
  }

  const roleFetchFinished = results => {
    return results.map(result => {
      if (utils.verifyResult(result)) {
        setRoles(utils.resultReturn(result))

        setRoleLoading(false)
      }

      setRoleFetchObjects(null)
    })
  }

  //USER URL LOGIC
  const getUserURLS = users => {
    getUserImages(users).then(userURLs => {
      setUserImageURLs(userURLs)
    })
  }

  const handleUserModal = user => {
    setDeleteUserModalVisibleKey(user)
  }

  const handleUserDelete = (id = null) => {
    if (id) updateUsers()

    setUserLoading(false)
  }

  const roleUpdate = u => {
    var roleBtn
    if (user.isAdmin) {
      roleBtn = (
        <button
          className={scss['user-role-button']}
          onClick={() => toggleDropdown(u)}
        >
          {u.role.name + ' '}
          <FontAwesomeIcon
            icon={
              showDropdown == u && user.isAdmin ? 'chevron-up' : 'chevron-down'
            }
            size='1x'
            pull='right'
          />
        </button>
      )
    } else {
      roleBtn = <span className={scss.role}>{u.role.name}</span>
    }
    return (
      <div style={{ position: 'relative' }} className={scss.dropdown}>
        {roleBtn}

        {/* ------------------------- START OF DROPDOWN ITEMS ------------------------ */}
        {showDropdown == u && user.isAdmin && (
          <>
            <div
              style={{ zIndex: 2 }}
              className={dropdownScss.dropdownCover}
              onClick={() => toggleAllDropdowns()}
            />
            <div
              style={{
                position: 'absolute',
                left: '0',
                top: '25px',
                zIndex: 3,
              }}
              className={scss.dropdownContent}
            >
              {roles.map(role => (
                <div
                  key={u.id + '_role_' + role.id}
                  className={scss.dropdownItem}
                  onClick={() => dropdownClicked(u, role.slug)}
                >
                  <div className={scss.dropdownText}>
                    <h4>{role.name}</h4>
                    <p>{role.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {/* -------------------------------------------------------------------------- */}
      </div>
    )
  }

  useEffect(() => {
    updateUsers()
    updateRoles()
  }, [user])

  //User Images
  const getUserImages = async userList => {
    return Promise.all(
      userList.map(async user => {
        const imgURL = await utils.getImage(user.profileImg, 'user.png')

        return {
          id: user.id,
          name: user.displayName,
          image: imgURL,
        }
      })
    )
  }

  const toggleDropdown = u => {
    if (!user.isAdmin) return

    if (showDropdown !== u) {
      setDropdown(u)
    } else setDropdown(false)
  }

  const toggleAllDropdowns = () => {
    setDropdown(false)
  }

  const dropdownClicked = (u, role) => {
    const userID = u.id
    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'account/user/role'
    const body = {
      accountID: user.accountID,
      userID,
      role,
    }

    setUserUpdateObjects([{ url, method, body }])
  }

  const userUpdateFinished = results => {
    results.forEach(res => {
      if (res.success && res.data) {
        const target = users.filter(user => user.id === res.data.userID)
        const { id, slug, name, description, permissions } = res.data.role
        target[0].role = { id, slug, name, description }
        target[0].permissions = permissions

        setUsers(users)
      }
    })

    setDropdown(false)
    setUserUpdateObjects(null)
  }

  const deleteUserButton = user => (
    <FontAwesomeIcon
      onClick={() => handleUserModal(user)}
      icon='trash'
      size='1x'
    />
  )

  const mapOptions = account.maps.map(item => ({
    label: item.name,
    value: item.id,
  }))

  const handleResendConf = user => {
    const { emailAddress } = user
    Auth.resendSignUp(emailAddress)
      .then(e => {
        console.log(e)
        if (e && e.CodeDeliveryDetails) {
          dispatch(
            Alert({
              success: 'Email verification code has been sent!',
            })
          )
        }
      })
      .catch(e => {
        dispatch(Alert({ error: 'Email Verification Failed' }))
      })
  }

  const getUser = u => {
    const profileImgObj = utils.objectKeyVal(u.id, userImageURLs, 'id')
    const isSuperAdmin = utils.isSuperAdmin(u)

    if (
      !utils.isSuperAdmin(user) && // Current user is not super admin
      isSuperAdmin // User is super admin
    )
      return null

    return (
      <>
        {editUserVisible == u.id && (
          <AddUserModal
            visible={setEditUserVisible}
            userID={u.id}
            firstName={u.firstName}
            lastName={u.lastName}
            title={u.title}
            emailAddress={u.emailAddress}
            userRole={u.isAdmin}
          />
        )}
        {profileImageVisible == u.id && (
          <ProfileImage
            visible={setProfileImageVisible}
            userID={u.id}
            userName={u.name}
          />
        )}

        <div className={scss['user-container-item']} key={u.id}>
          <img
            src={profileImgObj ? profileImgObj.image : ''}
            className={scss['user-container-image']}
            onClick={user.isAdmin ? () => setProfileImageVisible(u.id) : null}
          />
          {user.isAdmin && (
            <div className={scss['user-container-image-cover']}>
              <FontAwesomeIcon icon='pencil' />
            </div>
          )}
          <div className={scss['user-container-info']}>
            <div className={scss['user-container-info-top']}>
              <div className={scss['user-container-info-top-header']}>
                <div className={scss['user-container-name']}>
                  {u.displayName ? u.displayName : ''}
                  {u.title != '' ? (
                    <>
                      {' '}
                      |{' '}
                      <span className={scss['user-container-title']}>
                        {u.title}
                      </span>
                    </>
                  ) : (
                    ''
                  )}
                  {isSuperAdmin && (
                    <>
                      <FontAwesomeIcon
                        icon='star'
                        data-tooltip-content='MyAssetMap Team'
                        data-tooltip-id='super-admin'
                        data-effect='solid'
                        data-arrow-color='rgba(0, 0, 0, 0.68)'
                      />
                      <div className={appScss.reactToolTip}>
                        <Tooltip
                          id='super-admin'
                          getContent={dataTip => `${dataTip}`}
                        />
                      </div>
                    </>
                  )}
                </div>
                {user.isAdmin && (
                  <div className={scss['user-container-edit']}>
                    <FontAwesomeIcon
                      icon='edit'
                      size='1x'
                      onClick={() => setEditUserVisible(u.id)}
                    />
                    {user.isAdmin && deleteUserButton(u)}
                  </div>
                )}
              </div>
              <span className={scss['user-container-email']}>
                {u.firstName !== u.emailAddress ? u.emailAddress : <br />}
              </span>
            </div>
            <div className={scss['user-container-info-footer']}>
              {u.lastLogin === null ? (
                <span className={scss['user-container-info-footer-invite']}>
                  Invite sent.{' '}
                  <a onClick={() => handleResendConf(u)}>Re-send</a>
                </span>
              ) : (
                roleUpdate(u)
              )}
              <span className={scss['user-container-info-footer-login']}>
                Last Login:{' '}
                {u.lastLogin ? (
                  <ProfileDate date={u.lastLogin} time={u.lastLogin} />
                ) : (
                  'N/A'
                )}
              </span>
            </div>
          </div>
        </div>
      </>
    )
  }

  var usersArea
  if (!users.length || !userImageURLs.length) {
    usersArea = <div>No users found for this account</div>
  } else {
    users.sort(utils.objectSort('displayName'))
    usersArea = users.map(u => getUser(u))
  }

  return (
    <Tabs className={profileScss.userProfile}>
      {userFetchObjects && (
        <AsyncFetch
          fetchObjects={userFetchObjects}
          fetchFinished={userFetchFinished}
        />
      )}
      {roleFetchObjects && (
        <AsyncFetch
          fetchObjects={roleFetchObjects}
          fetchFinished={roleFetchFinished}
        />
      )}
      {userUpdateObjects && (
        <AsyncFetch
          fetchObjects={userUpdateObjects}
          fetchFinished={userUpdateFinished}
        />
      )}
      {deleteUserModalVisibleKey && (
        <UserDeleteModal
          user={deleteUserModalVisibleKey}
          respond={handleUserDelete}
        />
      )}
      {addUserVisible && (
        <UsersAddUserModal
          visible={setAddUserVisible}
          updateUsers={updateUsers}
        />
      )}

      <div className={scss['users-header']}>
        <span>All Users</span>
        {user.isAdmin && (
          <a
            onClick={() => setAddUserVisible(true)}
            style={{ textDecoration: 'none' }}
          >
            <span>Add New User </span>
            <FontAwesomeIcon icon='user-plus' />
          </a>
        )}
      </div>
      {userLoading || roleLoading ? (
        <div className={scss['users-container']}>
          <SkeletonTheme color='#e4e5e7' highlightColor='#e8ebf2'>
            <Skeleton height={130} count={6} />
          </SkeletonTheme>
          <SkeletonTheme color='#e4e5e7' highlightColor='#e8ebf2'>
            <Skeleton height={130} count={6} />
          </SkeletonTheme>
        </div>
      ) : (
        <div className={scss['users-container']}>{usersArea}</div>
      )}
    </Tabs>
  )
}
