import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AsyncFetch from '../../../../utilities/AsyncFetch'
import { apis } from '../../../../config/apiConfig'
import { setToolConfig, updateMapStyle } from '../../../../actions/index'
import { getLayerConfigUsingTocId } from '../../../../utilities/dataConfig'
import * as utils from '../../../../utilities/util'
import { fromJS } from 'immutable'
import { Tooltip } from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ParentGroupSelect from '../../ParentGroupSelect/ParentGroupSelect'

import scss from './LayerInfo.scss'
import appScss from '../../../App/App.scss'

import MakeDataConfigComponent from '../../../../utilities/dataConfig'

import mainPanelTheme from '../../../../utilities/componentConstants/mainPanelTheme'
import { ThemeProvider } from '@material-ui/core/styles'

import { Alert } from '../../../../actions'
import ProfileDate from '../../../Profile/ProfileDate'

const LayerInfo = React.memo(
  ({ closeTool, config, setDisplayManageAttributesMenu }) => {
    if (!config || !config.layer) return null

    const dispatch = useDispatch()
    const toolConfig = useSelector(state => state.toolConfig)
    const user = useSelector(state => state.user)
    // eslint-disable-next-line

    const [tocIdForDisplay, setTocForDisplay] = useState('')

    const [layerType, setLayerType] = useState(
      config.layer.layersArray[0].layer.type
    )

    const [layerId, setLayerId] = useState(config.layer.layersArray[0].layer.id)

    const [layerTypeForDisplay, setLayerTypeForDisplay] = useState(
      layerType.split('_')
    )
    const [layerIdForDisplay, setLayerIdForDisplay] = useState('')

    useEffect(() => {
      setDisplayManageAttributesMenu(false)
      let layerIdVal = layerId.split('_')
      layerIdVal = layerIdVal[layerIdVal.length - 1]

      let tocIdForDisplayVal = config.layer.toc.id
      tocIdForDisplayVal = !isNaN(tocIdForDisplayVal.split('_').pop())
        ? tocIdForDisplayVal.split('_').pop()
        : tocIdForDisplayVal

      setTocForDisplay(tocIdForDisplayVal)

      setLayerIdForDisplay(layerIdVal)
    }, [])

    var owner = config.layer.toc.owner
    var role = config.layer.toc.role
    var defaultRole = 'Viewer'

    const canEdit =
      config.layer.toc.type === 'mam' ? null : config.layer.toc.canEdit

    const dateUpdated = config.layer.toc.dateUpdated

    const [fetching, setFetching] = useState(false)
    const [fetchObjects, setFetchObjects] = useState(null)
    const [layerLabel, setLayerLabel] = useState(config.layer.toc.label)
    const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)
    const [layerDescription, setLayerDescription] = useState(
      config.layer.toc.description
    )
    const [layerMinZoom, setLayerMinZoom] = useState(
      config.layer.layersArray[0].layer.minzoom
    )

    const mapStyle = useSelector(state => state.mapStyle)

    const onLabelChange = e => {
      setLayerLabel(e.target.value)
    }
    const onDescriptionChange = e => {
      setLayerDescription(e.target.value)
    }
    const onMinZoomChange = e => {
      const { value } = e.target

      setLayerMinZoom(Math.max(1, Math.min(24, Number(value))))
    }

    const buildFetchParams = id => {
      const layerId = config.layer.toc.id
      let method
      let url
      let body = {}
      let params = []

      const sublayer = config.layer.layersArray[0].outline

      if (id === 'archive') {
        method = 'POST'
        url = apis['apiDatabase'].uri + 'layers/user/delete'
        body = {
          mapID: user.mapID,
          layerID: layerId,
        }

        params.push({ url, method, body })
      } else if (id === 'save') {
        method = 'POST'
        url = apis['apiDatabase'].uri + 'layer/update'
        body = {
          layerID: layerId,
          label: layerLabel,
          description: layerDescription,
          minzoom: layerMinZoom,
        }
        params.push({ url, method, body })

        if (sublayer) {
          let subUrl = apis['apiDatabase'].uri + 'layer/update/sublayer'
          let subBody = {
            layerID: config.layer.layersArray[0].layer.id,
            key: 'outline',
            minzoom: layerMinZoom,
          }
          params.push({ url: subUrl, method, body: subBody })
        }
      }
      setFetchObjects(params)
      setFetching(true)
    }

    const fetchFinished = results => {
      setFetching(false)

      // archive response
      return results.map(result => {
        if (utils.verifyResult(result)) {
          setMountMakeDataConfig(true)
          closeTool(config.name)
        }
        setFetchObjects(null)
      })
    }

    const doSave = () => {
      buildFetchParams('save')
    }

    useEffect(
      function () {
        setMountMakeDataConfig(false)
      },
      [mountMakeDataConfig]
    )

    useEffect(
      function () {
        if (
          layerMinZoom !== 0 &&
          layerMinZoom !== 'undefined' &&
          layerMinZoom !== null
        ) {
          let style = mapStyle.toJS()
          const targetLayer = style.layers.filter(layer => layer.id === layerId)
          const targetSubLayer = style.layers.filter(
            layer => layer.id === layerId + '_outline'
          )

          if (targetLayer[0]) {
            targetLayer[0].minzoom = layerMinZoom
            targetSubLayer.length
              ? (targetSubLayer[0].minzoom = layerMinZoom)
              : null

            dispatch(updateMapStyle(fromJS(style)))
          }
        }
      },
      [layerMinZoom]
    )

    const handleOnFinish = dataConfig => {
      setFetching(false)
      updateToolConfig(dataConfig)
    }

    const updateToolConfig = dataConfig => {
      // the data for the layer has changed (a new description or layer name)
      // the purpose of this function is to update app state data config with the new layer information
      // and remount the component to preserve the changes
      const updatedLayer = getLayerConfigUsingTocId(
        dataConfig,
        config.layer.toc.id
      )

      // add the newly updated layer to the toolConfig
      if (updatedLayer) {
        toolConfig.forEach(config => {
          if (config.name === 'LayerSettings') {
            config.layer = updatedLayer
          }
        })
        dispatch(setToolConfig(toolConfig))
      }
    }

    return (
      <ThemeProvider theme={mainPanelTheme}>
        {fetchObjects && (
          <AsyncFetch
            fetchObjects={fetchObjects}
            fetchFinished={fetchFinished}
          />
        )}
        {mountMakeDataConfig && (
          <MakeDataConfigComponent onFinish={handleOnFinish} />
        )}
        <div
          className={[
            scss.container,
            scss['panel-column'],
            scss['panel-justify-space-between'],
            scss['padding-zero'],
            scss['full-height'],
          ].join(' ')}
        >
          <div
            className={[scss['panel-column'], scss['panel-share']].join(' ')}
          >
            {/* <img src={layerShare} style={layerStyle}></img> */}
            <div style={{ fontWeight: '700' }}>
              Layer Information
              <FontAwesomeIcon
                icon='info-circle'
                size='1x'
                data-html
                data-tooltip-content={[
                  'TOC ID: ',
                  tocIdForDisplay,
                  '<br />',
                  'Layer ID: ',
                  layerIdForDisplay,
                  '<br />',
                ].join(' ')}
                data-tooltip-id='layerid'
                data-effect='solid'
                data-arrow-color='rgba(0, 0, 0, 0.68)'
                data-place='bottom'
                style={{ margin: '0 15px' }}
              />
            </div>
            <div className={appScss.reactToolTip}>
              <Tooltip id='layerid' getContent={dataTip => `${dataTip}`} />
            </div>
            <span className={scss['panel-colon-label']}>
              Shared by:{' '}
              <span className={scss['panel-colon-text']}>{owner}</span>
            </span>
            <span className={scss['panel-colon-label']}>
              Your Permissions:{' '}
              <span className={scss['panel-colon-text']}>
                {utils.getRole(role, defaultRole)}
              </span>
            </span>
            <span className={scss['panel-colon-label']}>
              Last Updated:{' '}
              <span className={scss['panel-colon-text']}>
                <ProfileDate date={dateUpdated} time />
              </span>
            </span>
          </div>

          {!canEdit && (
            <div
              className={[scss['panel-column'], scss['panel-description']].join(
                ' '
              )}
            >
              <span className={scss['panel-colon-label']}>
                Name:{' '}
                <span className={scss['panel-colon-text']}>{layerLabel}</span>
              </span>
              <span className={scss['panel-colon-label']}>
                Description:{' '}
                <span className={scss['panel-colon-text']}>
                  {layerDescription}
                </span>
              </span>
              <span className={scss['panel-colon-label']}>
                Min Zoom:{' '}
                <span className={scss['panel-colon-text']}>{layerMinZoom}</span>
              </span>
            </div>
          )}
          {canEdit && (
            <div>
              <div
                className={[
                  scss['panel-column'],
                  scss['panel-edit-description'],
                ].join(' ')}
              >
                <label
                  htmlFor='layer-settings-layer-name'
                  className={appScss['input-notched']}
                >
                  <span>Layer Name</span>
                  <input
                    id='layer-settings-layer-name'
                    onChange={onLabelChange}
                    value={layerLabel}
                    placeholder='Input text'
                    style={{ textAlign: 'left' }}
                  />
                </label>

                <label
                  htmlFor='layer-settings-layer-description'
                  className={appScss['input-notched']}
                >
                  <span>Description</span>
                  <textarea
                    id='layer-settings-layer-description'
                    onChange={onDescriptionChange}
                    value={layerDescription}
                    placeholder='Input text'
                  />
                </label>

                <label
                  htmlFor='layer-settings-layer-zoom'
                  className={appScss['input-notched']}
                >
                  <span>Min Zoom</span>
                  <input
                    id='layer-settings-layer-zoom'
                    onChange={onMinZoomChange}
                    value={layerMinZoom}
                    placeholder='Min Zoom must be between 1 and 24'
                    type='number'
                    min='1'
                    max='24'
                  />
                </label>
              </div>

              <div className={scss['panel-group-select']}>
                <span className={scss['panel-colon-label']}>Group:</span>
                <ParentGroupSelect key={'_pgs'} config={config} />
              </div>
            </div>
          )}

          <button
            className={[
              appScss.fullWidthButton,
              scss.layerInfoSave,
              scss[!canEdit ? 'layerInfoSaveInactive' : ''],
            ].join(' ')}
            onClick={canEdit ? () => doSave() : null}
          >
            {fetching ? (
              <FontAwesomeIcon icon={'spinner'} size='1x' spin />
            ) : (
              'Save'
            )}
          </button>
        </div>
      </ThemeProvider>
    )
  }
)

export default LayerInfo
