import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import scss from '../../Map/Map.scss'

function SearchBtn(props) {
  return (
    <button
      className={scss['map-button']}
      onClick={props.toggleSearch}
      data-tooltip-content='Search'
      data-tooltip-id='search'
      data-effect='solid'
      data-arrow-color='rgba(0, 0, 0, 0.68)'
      data-place='left'
    >
      <FontAwesomeIcon icon={['fas', 'search-location']} size='1x' />
    </button>
  )
}
export default SearchBtn
