import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateMapStyle, setMapToolTip } from '../../../../../actions/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fromJS } from 'immutable'
import * as mapUtils from '../../../../../utilities/mapStyle'
import { DRAW_SOURCES } from '../../Constants'

const Clear = ({ geometry }) => {
  const dispatch = useDispatch()
  const mapStyle = useSelector(state => state.mapStyle)
  const [mode, setMode] = useState('')
  let sources = DRAW_SOURCES[geometry]
  let deleteSources = ['delete_line', 'delete_circle']
  const emptyHotLayerSources = style => {
    sources.forEach(sourceId => {
      style.sources[sourceId] = emptyLayerSource(style, sourceId)
    })
    return style
  }

  const emptyDeleteLayerSources = style => {
    deleteSources.forEach(sourceId => {
      style.sources[sourceId] = emptyLayerSource(style, sourceId)
    })
    return style
  }

  const emptyLayerSource = (style, sourceId) => {
    let source = mapUtils.getSource(style, sourceId)
    source = mapUtils.emptySource(source)
    return source
  }

  const handleClick = () => {
    setMode('CLEAR')
  }

  useEffect(() => {
    if (mode === 'CLEAR') {
      let style = mapStyle.toJS()
      style = emptyHotLayerSources(style)
      style = emptyDeleteLayerSources(style)
      setMode('')
      dispatch(
        setMapToolTip({
          show: false,
          tipHtml: null,
        })
      )
      dispatch(updateMapStyle(fromJS(style)))
    }
  }, [mode])

  return (
    <div
      style={{
        fontSize: mode === 'CLEAR' ? '15px' : '13px',
        color: mode === 'CLEAR' ? '#f1cb57' : 'white',
      }}
      onClick={() => handleClick('CLEAR')}
      data-tooltip-content='Clear Pending Edits'
    >
      <FontAwesomeIcon icon={'sync'} size='1x' />
    </div>
  )
}

export default Clear
