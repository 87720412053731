import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AWS from 'aws-sdk'
import { addQueryVar, signRequest } from './api'
import { Alert } from '../actions'
import authConfig from '../config/authConfig'

// ========================================================================
// DOCUMENTATION
// ========================================================================
//
// Receives an array of 'fetchObjects' that house parameters
// ie ([{ url, method, body }]), or ([{ url, method, body }, { url, method, body}])
//
// Returns an array of responses that then need to be mapped through
//
// EXAMPLE RESPONSE
// const fetchFinished = responses => {
//   return responses.map(response => {
//     console.log(response)
//   })
// }

export default function AsyncFetch({ fetchObjects, fetchFinished }) {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  useEffect(() => {
    const getAuth = async url => {
      const { UUID, mapID } = user

      let userURL = addQueryVar(url, 'userID', UUID)
      userURL = addQueryVar(userURL, 'mapID', mapID)

      return userURL
    }

    const doFetch = async (userUrl, params) => {
      const contentType = 'application/json'

      if (params.body.mapId) {
        params.body.mapID = user.mapID
      }
      let options = null

      if (params.method === 'GET') {
        options = {
          method: params.method,
          headers: signRequest(userUrl).headers,
        }
      } else if (params.method === 'POST') {
        options = {
          method: params.method,
          body: JSON.stringify(params.body),
          headers: signRequest(
            userUrl,
            params.method,
            JSON.stringify(params.body),
            contentType
          ).headers,
        }
      }

      const response = await fetch(userUrl, options).catch(err => {
        console.error('fetch error', err)
      })

      // if (!response) return {}

      const json = await response.json()
      // for testing purposes
      if (json.success) {
        const { message } = json
        if (message !== null && message !== '')
          dispatch(Alert({ success: message }))
      }

      if (!json.success) {
        const { message } = json
        if (message !== null && message !== '')
          dispatch(Alert({ error: message }))
      }

      // Check for Hydration
      if (json.data && json.data.hydrate) {
        // Hydration Needed
        // console.log('json.data.hydrate :>>', json.data.hydrate)

        const s3 = new AWS.S3()

        const s3Params = {
          Bucket: authConfig.s3Config.bucketName, // your bucket name,
          Key: json.data.hydrate, // path to the object you're looking for
        }

        const result = await s3
          .getObject(s3Params)
          .promise()
          .catch(err => {
            console.error('err', err)
          })

        const object = result.Body.toString('utf-8')

        // Delete the key
        await s3.deleteObject(s3Params).promise()

        try {
          json.data = JSON.parse(object)
          return json
        } catch (err) {
          json.data = object
          return json
        }
      } else return json
    }

    const processFetchObjects = async fetchObjs => {
      try {
        const promises = fetchObjs.map(async obj => {
          if (obj.paginate) {
            let responseData = []
            let keepGoing = true
            let start = 0

            while (keepGoing) {
              // eslint-disable-next-line no-await-in-loop
              let userUrl = await getAuth(obj.url)
              userUrl += `&limit=${obj.paginate}&start=${start}`
              // eslint-disable-next-line no-await-in-loop
              const response = await doFetch(userUrl, obj).catch(err =>
                console.error(err)
              )

              if (
                typeof response.data !== 'undefined' &&
                Array.isArray(response.data)
              ) {
                responseData = [...responseData, ...response.data]
              } else {
                keepGoing = false
                return response
              }

              start += obj.paginate
              if (response.data.length < obj.paginate) {
                keepGoing = false
                // build and return object
                return Promise.resolve({
                  success: response.success,
                  message: response.message,
                  data: responseData,
                })
              }
              if (!response.success) keepGoing = false
              if (!response) keepGoing = false
            }
          } else {
            const userUrl = await getAuth(obj.url)
            return doFetch(userUrl, obj).catch(err => console.error(err))
          }
        })
        return Promise.all(promises)
      } catch (err) {
        console.error(`error fetching:::, ${err}`)
        return Promise.reject(err)
      }
    }

    processFetchObjects(fetchObjects).then(results => {
      fetchFinished(results)
    })
  }, [])

  return null
}
