import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { initiateTracking } from '../../../actions/actions_geolocate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import mapScss from '../../Map/Map.scss'
import { setViewPort } from '../../../actions'
import { FlyToInterpolator } from 'react-map-gl'

const UserLocation = () => {
  const dispatch = useDispatch()
  const viewport = useSelector(state => state.viewport)
  const isTrackingLocation = useSelector(
    state => state.geolocation.isTrackingLocation
  )
  const currentLocation = useSelector(
    state => state.geolocation.currentLocation
  )
  return (
    <button
      className={mapScss['map-button']}
      onClick={() => {
        if (!isTrackingLocation) {
          dispatch(initiateTracking())
        } else {
          if (currentLocation === null) return
          const { latitude, longitude } = currentLocation
          if (latitude === null || longitude === null) return
          let newViewport = {
            ...viewport,
            latitude,
            longitude,
            zoom: 15,
            transitionDuration: 500,
            transitionInterpolator: new FlyToInterpolator(),
          }
          dispatch(setViewPort(newViewport))
        }
      }}
      title='Current Location'
      data-tooltip-content='Current Location'
      data-tooltip-id='user-location'
      data-effect='solid'
      data-arrow-color='rgba(0, 0, 0, 0.68)'
      data-place='left'
      style={{
        borderTop: 'solid 0.5px #314d68',
        borderRadius: '0 0 10px 10px',
      }}
    >
      <FontAwesomeIcon style={{ fontSize: '20px' }} icon='fas fa-location' />
    </button>
  )
}

export default UserLocation
