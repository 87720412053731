import React, { useState, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { ThemeProvider } from '@material-ui/core/styles'

import scss from './ConfirmDelete.scss'
import modalScss from '../../../../CSSModules/Modal.scss'
import appScss from '../../../../App/App.scss'

import mainModalTheme from '../../../../../utilities/componentConstants/mainModalTheme'

const ConfirmDelete = ({ modalOpen, closeModal, message, doDeleteLayout }) => {
  const [doingDelete, setDoingDelete] = useState(false)

  const deleteConfirmed = useCallback(() => {
    setDoingDelete(true)
    doDeleteLayout()
  }, [doDeleteLayout])

  return (
    <ThemeProvider theme={mainModalTheme}>
      <Dialog onClose={closeModal} open={modalOpen}>
        <DialogTitle id='delete-layout-dialog'>
          Delete Layout
          <FontAwesomeIcon
            onClick={closeModal}
            icon='times'
            size='sm'
            pull='right'
          />
        </DialogTitle>

        <DialogContent className={modalScss.MuiDialogBoxes}>
          <div>{message}</div>
        </DialogContent>

        <DialogActions>
          <div className={scss.modalSaveButton}>
            <button
              className={appScss.blueButton}
              onClick={doingDelete ? null : deleteConfirmed}
            >
              {doingDelete ? (
                <FontAwesomeIcon icon='spinner' size='1x' fixedWidth spin />
              ) : (
                'Confirm'
              )}
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default ConfirmDelete
