import React from 'react'
import { useSelector } from 'react-redux'
import {
  getExpressionType,
  getZoomBasedValue,
  getDataBasedValue,
  getSwatchLabel,
} from './Utilities'
import { hexToRgba } from '../../../../utilities/formatters'
import scss from './LegendSwatch.scss'
import appScss from '../../../App/App.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'react-tooltip'

const LineSwatch = ({ layer, singleItemTitles, hideLayerHeader = null }) => {
  const viewport = useSelector(state => state.viewport)
  const getLineProperty = lineProp => {
    if (Array.isArray(lineProp)) {
      const expressionType = getExpressionType(lineProp)
      if (expressionType === 'zoomBased') {
        return getZoomBasedValue(lineProp, viewport)
      }
      if (expressionType === 'dataBased') {
        return getDataBasedValue(lineProp)
      }
    } else {
      return lineProp
    }
  }

  const getLineStyle = (width, color, opacity) => {
    const rgbaColor = hexToRgba(color, opacity)
    if (width > 20) width = 20
    return {
      width: '20px',
      borderBottom: `${width}px solid ${rgbaColor}`,
    }
  }

  const getLineSwatch = (width, color, opacity, label) => {
    let expressionValues = []
    let widthObject = {}
    let colorObject = {}
    let opacityObject = {}
    if (Array.isArray(width)) {
      width.forEach(arr => {
        widthObject[arr[0]] = arr[1]
      })
      let widthValues = width.map(arr => arr[0])
      expressionValues = [...expressionValues, ...widthValues]
    }
    if (Array.isArray(color)) {
      color.forEach(arr => {
        colorObject[arr[0]] = arr[1]
      })
      let colorValues = color.map(arr => arr[0])
      expressionValues = [...expressionValues, ...colorValues]
    }
    if (Array.isArray(opacity)) {
      opacity.forEach(arr => {
        opacityObject[arr[0]] = arr[1]
      })
      let opacityValues = opacity.map(arr => arr[0])
      expressionValues = [...expressionValues, ...opacityValues]
    }

    let uniqueExpressionValues = [...new Set(expressionValues)]

    if (
      uniqueExpressionValues.includes('default') &&
      uniqueExpressionValues.length &&
      uniqueExpressionValues.length > 1
    ) {
      const currentDefaultIndex = uniqueExpressionValues.indexOf('default')

      const arrayMove = (arr, oldIndex, newIndex) => {
        if (newIndex >= arr.length) {
          let k = newIndex - arr.length + 1
          // eslint-disable-next-line no-plusplus
          while (k--) {
            arr.push(undefined)
          }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
        return arr
      }

      uniqueExpressionValues = arrayMove(
        uniqueExpressionValues,
        currentDefaultIndex,
        0
      )
    }

    let swatchItems = []
    if (uniqueExpressionValues.length) {
      // the legend contains databased style
      const items = uniqueExpressionValues.map(value => {
        let itemWidth = widthObject[value] || widthObject['default'] || width
        let itemColor = colorObject[value] || colorObject['default'] || color
        let itemOpacity =
          opacityObject[value] || opacityObject['default'] || opacity
        const style = getLineStyle(itemWidth, itemColor, itemOpacity)
        return (
          <li key={value}>
            <div className={scss.expressionItem}>
              {/* <div>{value}</div> */}
              <div>{value === 'default' ? 'Default' : value}</div>
              {itemOpacity !== 'default' && itemOpacity < 0.15 ? (
                <>
                  <div className={scss.expressionItemCaution}>
                    <FontAwesomeIcon
                      icon='exclamation-triangle'
                      size='1x'
                      data-tooltip-content={
                        'Fill is below 15% opacity, style may not be visible'
                      }
                      data-tooltip-id='opacity-warning'
                      data-effect='solid'
                      data-arrow-color='rgba(0, 0, 0, 0.68)'
                    />
                    <div style={style}></div>
                    <div className={appScss.reactToolTip}>
                      <Tooltip
                        id='opacity-warning'
                        getContent={dataTip => `${dataTip}`}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div style={style}></div>
              )}
            </div>
          </li>
        )
      })
      swatchItems.push(
        <ul key={uniqueExpressionValues.join(',')} className={scss.swatchList}>
          {hideLayerHeader ? null : (
            <div className={scss.expressionItemTitle}>
              <span>{label}</span>
              <span>Style</span>
            </div>
          )}
          {items}
        </ul>
      )
      // swatchItems.push(<ul key={uniqueExpressionValues.join(",")} className={scss.swatchList}><div className={scss.expressionItemTitle}><span>Attribute</span><span>Style</span></div>{items}</ul>);
    } else {
      const style = getLineStyle(width, color, opacity)

      let defaultOpacity = 1
      if (!opacity || opacity < 0.15) defaultOpacity = undefined

      if (singleItemTitles) {
        swatchItems.push(
          <ul key={layer.id + '_legendItem'} className={scss.swatchList}>
            {/* <div className={scss.expressionItemTitle}><span>Attribute</span><span>Style</span></div> */}
            <li>
              <div className={scss.expressionItem}>
                <div> {singleItemTitles && label} </div>
                {!defaultOpacity ? (
                  <>
                    <div className={scss.expressionItemCaution}>
                      <FontAwesomeIcon
                        icon='exclamation-triangle'
                        size='1x'
                        data-tooltip-content={
                          'Fill is below 15% opacity, style may not be visible'
                        }
                        data-tooltip-id='opacity-warning'
                        data-effect='solid'
                        data-arrow-color='rgba(0, 0, 0, 0.68)'
                      />
                      <div
                        className={scss.expressionItemCircle}
                        style={style}
                      ></div>
                      {/* <div className={appScss.reactToolTip}>
                      <Tooltip id="opacity-warning" getContent={(dataTip) => `${dataTip}`} />
                    </div> */}
                    </div>
                  </>
                ) : (
                  <div
                    className={scss.expressionItemCircle}
                    style={style}
                  ></div>
                )}
              </div>
            </li>
          </ul>
        )
      } else {
        swatchItems.push(
          <ul key={layer.id + '_legendItem'} className={scss.swatchList}>
            <li>
              <div className={scss.expressionItem}>
                {/* <div>Standard Style</div> */}
                <div>{label}</div>
                {!defaultOpacity ? (
                  <>
                    <div className={scss.expressionItemCaution}>
                      <FontAwesomeIcon
                        icon='exclamation-triangle'
                        size='1x'
                        data-tooltip-content={
                          'Fill is below 15% opacity, style may not be visible'
                        }
                        data-tooltip-id='opacity-warning'
                        data-effect='solid'
                        data-arrow-color='rgba(0, 0, 0, 0.68)'
                      />
                      <div
                        className={scss.expressionItemCircle}
                        style={style}
                      ></div>
                      <div className={appScss.reactToolTip}>
                        <Tooltip
                          id='opacity-warning'
                          getContent={dataTip => `${dataTip}`}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    className={scss.expressionItemCircle}
                    style={style}
                  ></div>
                )}
              </div>
            </li>
          </ul>
        )
      }
    }

    return swatchItems
  }
  let label = getSwatchLabel(layer)
  let width = getLineProperty(layer.paint['line-width'])
  let color = getLineProperty(layer.paint['line-color'])
  let opacity = getLineProperty(layer.paint['line-opacity'])
  let swatch = getLineSwatch(width, color, opacity, label)

  return <>{swatch}</>
}

export default LineSwatch
