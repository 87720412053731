import axios from 'axios'
import { Auth } from 'aws-amplify'
import { apiFetch } from './api'
import { apis } from '../config/apiConfig'
// Action Handlers
import { defaultUser } from '../actions'

import * as utils from './util'

const DEFAULT_LATITUDE = 39.742043
const DEFAULT_LONGITUDE = -104.991531
const DEFAULT_ZOOM = 5

export async function createLog(user) {
  try {
    const res = await axios.get('https://geolocation-db.com/json/')

    const url = apis.apiDatabase.uri + 'logs'
    const method = 'POST'
    const bodyParams = {
      latitude: res.data.longitude,
      longitude: res.data.latitude,
      state: res.data.state,
      userID: user.UUID,
      city: res.data.city,
      ip: res.data.IPv4,
    }

    if (user.mapID) bodyParams.mapID = user.mapID

    await apiFetch(url, method, bodyParams, result => {
      console.log('Log created successfully')
    })
  } catch (error) {
    console.warn('Unable to create log')
  }
}

// USER LOGIN
export const loginInitialize = () =>
  // eslint-disable-next-line no-async-promise-executor, consistent-return
  new Promise(async (resolve, reject) => {
    const user = defaultUser()

    const url = apis.apiDatabase.uri + 'user/init'
    const method = 'GET'

    user.isLoggedIn = true

    apiFetch(url, method, null, result => {
      if (utils.verifyResult(result)) {
        if (
          typeof result.data !== 'undefined' &&
          typeof result.data.profile !== 'undefined'
        ) {
          const {
            profile,
            isSuperAdmin,
            isAdmin,
            isActive,
            role,
            permissions,
            adminAccounts,
            accounts,
            preferences,
            accountID,
          } = result.data

          user.UUID = result.userUUID
          user.isSuperAdmin = isSuperAdmin
          user.isAdmin = isAdmin
          user.isActive = isActive
          user.role = role
          user.permissions = permissions

          user.adminAccounts = adminAccounts

          user.profile = { ...user.profile, ...profile }
          user.accounts = accounts
          user.preferences = preferences

          let account
          if (accountID !== null) {
            user.accountID = accountID

            account = utils.objectKeyVal(user.accountID, user.accounts, 'id')

            if (!account || (Array.isArray(account) && !account.length)) {
              user.accountID = accounts.length > 0 ? accounts[0].id : null

              // Set Account to matching account based on ID
              account = utils.objectKeyVal(user.accountID, user.accounts, 'id')
            }
          } else {
            // ========================================================================================== //
            // ======== If accountID is not specified, build the top level user on the front-end ======== //
            // ========================================================================================== //

            user.accountID =
              accountID == null
                ? accounts.length > 0
                  ? accounts[0].id
                  : null
                : accountID

            // Set Account to matching account based on ID
            account = utils.objectKeyVal(user.accountID, user.accounts, 'id')

            if (!accounts.length) {
              return reject(
                Error(
                  'You are not a part of any accounts. Please contact support@myassetmap.com.'
                )
              )
            }

            // Continue Overwriting settings
            user.isAdmin = result.data.adminAccounts.includes(user.accountID)
            user.isActive = !result.data.disabledAccounts.includes(
              user.accountID
            )
            user.role = account.role
            user.permissions = account.permissions
          }

          if (!account || (Array.isArray(account) && !account.length)) {
            return reject(
              Error(
                'No active accounts have been found. Please have your admin add to you an account. If you need additional support, please contact support@myassetmap.com.'
              )
            )
          }

          // Set Map based on Account and Data
          user.mapID =
            result.data.mapID == null
              ? result.data.accounts.length > 0
                ? typeof account === 'object'
                  ? account.maps && account.maps.length > 0
                    ? account.maps[0].id
                    : null
                  : null
                : null
              : result.data.mapID
        }
      } else {
        // Error Handling
        reject(
          Error(
            result.message ??
              'There was an error loading your profile. Refresh the page and contact support@myassetmap.com if the problem persists.'
          )
        )
      }
      console.log('init user ', user)
      createLog(user)
      return resolve(user)
    })
  })

// USER LOGIN
export async function refreshDetails(userPayload, callback) {
  const { UUID, profile } = userPayload

  userPayload.sub = UUID
  userPayload.email = profile.emailAddress
  console.log('Refreshing User Details')

  try {
    const user = await loginInitialize()
    return callback(user)
  } catch (error) {
    return console.error('Unable to refresh user details: ' + error.message)
  }
}

export async function createCredentials() {
  const session = await Auth.currentSession()
  const credentials = await Auth.currentCredentials()
  const essential = await Auth.essentialCredentials(credentials)
  localStorage.setItem('accessKeyId', essential.accessKeyId)
  localStorage.setItem('secretAccessKey', essential.secretAccessKey)
  localStorage.setItem('sessionToken', essential.sessionToken)
  localStorage.setItem('token', session.idToken.jwtToken)
  return essential
}

export const getMapHomeLocation = user => {
  const accountId = user.accountID
  const mapId = user.mapID
  const activeAccount = user.accounts.filter(
    account => account.id === accountId
  )
  const location = activeAccount[0].maps
    .filter(map => map.id === mapId)
    .map(map => map.location)
  if (!location[0]) {
    location[0] = {}
    location[0].x = DEFAULT_LATITUDE
    location[0].y = DEFAULT_LONGITUDE
    location[0].z = DEFAULT_ZOOM
  }
  return location[0]
}

export const getActiveMap = user => {
  const accountId = user.accountID
  const mapId = user.mapID
  const activeAccount = user.accounts.filter(
    account => account.id === accountId
  )
  const activeMap = activeAccount[0].maps.filter(map => map.id === mapId)

  return activeMap[0]
}
