import aws4 from 'aws4'
import { Auth } from 'aws-amplify'
import AWS from 'aws-sdk'
import authConfig from '../config/authConfig'
import * as utils from './util'
import { apis } from '../config/apiConfig'

const mamApis = [
  new RegExp('https://(dv|qa|pd)-fot-tiles.s3.amazonaws.com/'),
  new RegExp('https://my-asset-map-data.s3.amazonaws.com/'),
  new RegExp('https://hp29salvwe.execute-api.us-east-1.amazonaws.com/'), // alpha
  new RegExp('https://rg91b1juf3.execute-api.us-east-1.amazonaws.com/'), // dev
  new RegExp('https://egd4avkav9.execute-api.us-east-1.amazonaws.com/'), // production
  new RegExp('https://99qya3ubuj.execute-api.us-east-1.amazonaws.com/'), // qa
]

function apiCheck(url) {
  return mamApis.map(mamApi => mamApi.test(url)).includes(true)
}

/**
 * Lookup a query param in the url
 * @param {*} key the string key to lookup
 * @returns string | null
 */
function getQueryParam(key) {
  const url = new URL(window.location.href)

  return url.searchParams.get(key)
}

/**
 *
 * @param string key The key to lookup in local storage
 * @returns string | null
 */
function getLocalStorage(key) {
  return localStorage.getItem(key)
}

function setLocalStorage(key, value) {
  localStorage.setItem(key, value)
}

function lookupInBrowserByKey(key) {
  const query = getQueryParam(key)
  if (query !== null) {
    if (query === '') {
      setLocalStorage(key, null)
      return null
    }
    setLocalStorage(key, query)
    return query
  }

  const local = getLocalStorage(key)
  if (local !== null) return local
  return null
}

function signRequest(
  url,
  method = 'GET',
  body = null,
  contentType = null,
  resourceType = null
) {
  const urlObject = new URL(url)
  const opts = {
    host: urlObject.host,
    path: urlObject.pathname + urlObject.search,
  }

  if (body !== null && method !== 'GET') {
    opts.body = body
    opts.method = method
    opts.headers = { 'Content-Type': contentType }
  }
  if (method === 'DELETE' || method === 'GET') {
    opts.method = method
  }

  if (apiCheck(url)) {
    opts.region = 'us-east-1'
    if (new RegExp('.*.?s3.amazonaws.com').test(opts.host)) {
      opts.service = 's3'
    } else {
      opts.service = 'execute-api'
    }

    // credentials set in createCredentials function in user.js
    const accessKeyId = localStorage.getItem('accessKeyId')
    const secretAccessKey = localStorage.getItem('secretAccessKey')
    const sessionToken = localStorage.getItem('sessionToken')

    aws4.sign(opts, {
      secretAccessKey,
      accessKeyId,
      sessionToken,
    })
  }
  const headers = opts.headers || {}

  if ('Host' in headers) {
    delete headers.Host // consider an unsafe header by browsers
  }
  if ('Content-Length' in headers) {
    delete headers['Content-Length'] // consider an unsafe header by browsers
  }
  return { url, headers, opts }
}

function addQueryVar(url, key, value) {
  key = encodeURI(key)
  value = encodeURI(value)

  const querySplit = url.split('?')
  const urlRoot = querySplit[0]
  let query = ''
  if (querySplit.length === 2) query = querySplit[1]

  const kvp = query.split('&')

  let i = kvp.length
  let x

  // eslint-disable-next-line no-plusplus
  while (i--) {
    x = kvp[i].split('=')

    // eslint-disable-next-line eqeqeq
    if (x[0] == key) {
      x[1] = value
      kvp[i] = x.join('=')
      break
    }
  }

  if (i < 0) {
    kvp[kvp.length] = [key, value].join('=')
  }

  return (urlRoot + '?' + kvp.join('&')).replace('?&', '?')
}

/**
 * Make request to url.
 * @param {string} url - url for request
 * @param {string} method - http method type
 * @param {object} body - object of key value pairs for post body
 * @callback requestCallback
 */
const apiFetch = (url, method = 'GET', body = null, callback) => {
  const contentType = 'application/json'

  const sendFetch = userUrl => {
    let options = null
    if (method === 'GET') {
      // If GET, append QUERY params to URL instead
      if (body != null && typeof body === 'object') {
        Object.keys(body).forEach(key => {
          userUrl = addQueryVar(userUrl, key, body[key])
        })
      }

      options = {
        method,
        headers: signRequest(userUrl).headers,
      }
    }
    if (method === 'POST') {
      options = {
        method,
        body: JSON.stringify(body),
        headers: signRequest(userUrl, method, JSON.stringify(body), contentType)
          .headers,
      }
    }

    return fetch(userUrl, options)
      .then(response => {
        if (response.status >= 200 && response.status <= 299) {
          return Promise.resolve(response.json())
        }
        throw Error(response.statusText)
      })
      .then(
        json =>
          new Promise(resolve => {
            const { data } = json
            // check for hydration
            if (typeof data !== 'undefined' && data.hydrate) {
              const s3 = new AWS.S3()

              const params = {
                Bucket: authConfig.s3Config.bucketName, // your bucket name,
                Key: data.hydrate, // path to the object you're looking for
              }

              s3.getObject(params, (objectErr, objectRes) => {
                if (objectErr) resolve(json)

                let object = objectRes.Body.toString('utf-8')
                s3.deleteObject(params, (delErr, delRes) => {
                  if (delErr) {
                    console.error(delRes)
                    resolve(json)
                  }
                  try {
                    object = JSON.parse(object)
                    resolve(object)
                  } catch (err) {
                    resolve(json)
                  }
                })
              })
            } else resolve(json)
          })
      )
  }

  Auth.currentSession()
    .then(result => {
      const cogID =
        lookupInBrowserByKey('userImpersonation') ?? result.idToken.payload.sub

      return sendFetch(addQueryVar(url, 'userID', cogID))
        .then(async json => {
          // if this is the user/init route, confirm if the current user can impersonate.
          if (url.endsWith('/user/init')) {
            if (cogID !== result.idToken.payload.sub) {
              const validateUserJson = await sendFetch(
                addQueryVar(url, 'userID', result.idToken.payload.sub)
              )
              if (
                !utils.verifyResult(validateUserJson) ||
                validateUserJson?.data?.isSuperAdmin !== true
              ) {
                throw Error(
                  'You are not allowed to impersonate accounts. Please contact support@myassetmap.com if you need assistance.'
                )
              }
            }
          }

          // Append userUUID to response
          json.userUUID = cogID
          callback(json)
        })
        .catch(e => {
          // Network Error
          console.error(e)
          callback({ success: false, message: e.message, error: e })
        })
    })
    .catch(e => {
      if (e !== 'No current user') console.log('SESSION ERROR', e)
      utils.showMessage('Session Fetch Error', e.message)
    })
}

export {
  apiCheck,
  getQueryParam,
  getLocalStorage,
  lookupInBrowserByKey,
  signRequest,
  apiFetch,
  addQueryVar,
}
