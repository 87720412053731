import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import { transitions, positions, Provider as AlertProvider } from 'react-alert'

import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import reducers from './reducers'
import throttledMiddleware from './middleware/throttle'

// eslint-disable-next-line import/no-cycle
import App from './containers/App/App.jsx'

import {
  configureAmplify,
  configureFontAwesome,
  configureS3,
  configureSentry,
} from './services'

import AlertTemplate from './react-alert-template'

import nearmapUsageTracker from './utilities/nearmapUsageTracker'
import Alert from './components/Alert.js'

configureAmplify()
configureFontAwesome()
configureS3()
configureSentry()

const store = createStore(
  reducers,
  composeWithDevTools(
    applyMiddleware(throttledMiddleware)
    // other store enhancers if any
  )
)

store.subscribe(nearmapUsageTracker.loginListener)

const options = {
  position: positions.BOTTOM_RIGHT,
  timeout: 1000,
  offset: '12px 0 0 0',
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 1301,
    bottom: '100px',
    left: 'unset',
    right: '32px',
  },
}

const container = document.getElementById('root') || document.createElement('div')
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <div>
        <AlertProvider template={AlertTemplate} {...options}>
          <Alert />
          <App />
        </AlertProvider>
      </div>
    </BrowserRouter>
  </Provider>
)

export { store }
