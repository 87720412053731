import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// utils
import {
  setToolConfig,
  setOverPanel,
  setScrollZoom,
  setDragPan,
  setDoubleClickZoom,
} from '../../../actions/index'
import { deepCopy } from '../../../utilities/util'

// components
import Button from '../../../components/Button/Button'
import Tabs from '../../../components/Tabs/Tabs'
import LayerInfo from './LayerInfo/LayerInfo'
import LayerStyle from './LayerStyle/LayerStyle.jsx'
import ManageDocuments from './ManageDocuments/ManageDocuments'
import ManageAttributes from './ManageAttributes/ManageAttributes'
import LayerDocumentsV2 from './LayerAttributes/DocumentsV2'
import LayerLabel from './LayerLabel/LayerLabel'
import LayerFooter from './LayerFooter/LayerFooter'
import CloseTool from '../CloseTool'
import ManageAttributeContextMenu from './ManageAttributeContextMenu/ManageAttributeContextMenu.jsx'

// scss files
import scss from './LayerSettingsV2.scss'
import appScss from '../../App/App.scss'
import AttributeSettings from './AttributeSettings.jsx'

const LayerSettingsV2 = ({ config, onCollapse, dataConfig }) => {
  const dispatch = useDispatch()
  const toolConfig = useSelector(state => state.toolConfig)
  const mapStyle = useSelector(state => state.mapStyle)
  const user = useSelector(state => state.user)

  const [isCommonLayer, setIsCommonLayer] = useState(false)

  useEffect(() => {
    if (
      typeof config.layer.layersArray[0].layer.metadata.commonLayer !==
        'undefined' &&
      config.layer.layersArray[0].layer.metadata.commonLayer
    ) {
      setIsCommonLayer(true)
    }
  }, [])

  const [closeToolKey, setCloseToolKey] = useState(null)

  const onClose = () => {
    dispatch(setOverPanel(false))
    dispatch(setScrollZoom(true))
    dispatch(setDragPan(true))
    dispatch(setDoubleClickZoom(true))
  }

  const closeTool = () => {
    const newConfig = deepCopy(toolConfig)
    newConfig.forEach(tool => {
      if (tool.name === 'LayerSettings') {
        tool.visible = false
        tool.layer = null
      }
    })
    // to maintain immutable redux state we need to clone toolConfig and send cloned to reducer
    dispatch(setToolConfig(newConfig))
    setCloseToolKey('closeToolKey')
    // call on close method to enable all map interactions
    onClose()
  }

  useEffect(() => {
    return () => {
      onClose()
    }
  }, [])

  const getCollapsedUI = () => {
    return (
      <div className={[scss.toolQuickActionHeader].join(' ')}>
        <div className={[scss.handleContainer, 'handle'].join(' ')}>
          <FontAwesomeIcon icon={['fas', 'cog']} size='1x' />
        </div>
        <div className={scss.toolHeaderButtonWrapper}>
          <div className={scss.toolQuickActionButtonWrapper}>
            <Button
              onClick={() => onCollapse(config.name, false)}
              leadingIcon='expand-alt'
              leadingIconSize='lg'
              buttonType='toolHeaderButton'
              title='Expand'
            />
          </div>
        </div>
      </div>
    )
  }

  const getFullUI = () => {
    if (!config.layer) return null
    const displayStyles = true
    const displayProperties = true

    const label = config.layer.toc.label || ''

    const tocLayer = config.layer.toc

    const [displayManageDocuments, setDisplayManageDocuments] = useState(false)

    const [displayManageAttributes, setDisplayManageAttributes] =
      useState(false)

    const [displayManageAttributesMenu, setDisplayManageAttributesMenu] =
      useState(false)

    const [contextMenuY, setContextMenuY] = useState(0)

    return (
      <>
        {closeToolKey && (
          <CloseTool key={closeToolKey} toolName='LayerSettings' />
        )}

        <div className={scss.toolExpanded}>
          <div className={[scss.toolHeader, 'handle'].join(' ')}>
            <div className={scss.toolAlign}>
              <div className={[scss.toolTitle].join(' ')}>{label}</div>
            </div>
            <div
              className={[
                scss.toolHeaderButtons,
                scss.toolHeaderButtonWrapper,
              ].join(' ')}
            >
              <Button
                onClick={() => onCollapse(config.name, true)}
                leadingIcon='minus'
                leadingIconSize='lg'
                buttonType='toolHeaderButton'
                title='Minimize'
              />
              <Button
                onClick={() => closeTool(config.name)}
                leadingIcon='times'
                leadingIconSize='lg'
                buttonType='toolHeaderButton'
                title='Close'
              />
            </div>
          </div>
          {displayManageAttributesMenu ? (
            <ManageAttributeContextMenu
              y={contextMenuY}
              setDisplayManageAttributesMenu={setDisplayManageAttributesMenu}
              setDisplayManageDocuments={setDisplayManageDocuments}
              setDisplayManageAttributes={setDisplayManageAttributes}
            />
          ) : null}
          <div className={[scss.toolBody, scss.layerSettingsTabs].join(' ')}>
            <Tabs className={scss.toolBodyTabGroup} customClass='leftTabs'>
              <div className={scss.toolBodyTab} icon='info' label='Settings'>
                <LayerInfo
                  className={scss.toolBodyTab}
                  key={'layerInfo'}
                  config={config}
                  closeTool={closeTool}
                  setDisplayManageAttributesMenu={
                    setDisplayManageAttributesMenu
                  }
                />
              </div>
              {!isCommonLayer ? (
                <div
                  // useManageAttributesIcon
                  className={scss.toolBodyTab}
                  icon={['far', 'list-tree']}
                  iconClass='far'
                  label='Attributes'
                >
                  <AttributeSettings config={config} />
                </div>
              ) : // <div
              //   className={scss.toolBodyTab}
              //   label='Attributes'

              //   displayManageAttributesMenu={displayManageAttributesMenu}
              //   setDisplayManageAttributesMenu={
              //     setDisplayManageAttributesMenu
              //   }
              //   setContextMenuY={setContextMenuY}
              // >
              // </div>
              null}
              {displayStyles ? (
                <div className={scss.toolBodyTab} icon='palette' label='Styles'>
                  <LayerStyle
                    tocLayer={tocLayer}
                    className={scss.toolBodyTab}
                    key={'layerStyle'}
                    config={config}
                    mapStyle={mapStyle}
                    setDisplayManageAttributesMenu={
                      setDisplayManageAttributesMenu
                    }
                  />
                </div>
              ) : null}
              {displayProperties ? (
                <div
                  className={scss.toolBodyTab}
                  icon='file-alt'
                  label='Documents '
                >
                  <LayerDocumentsV2
                    className={scss.toolBodyTab}
                    key={'layerDocuments'}
                    config={config}
                    mapStyle={mapStyle}
                    setDisplayManageAttributesMenu={
                      setDisplayManageAttributesMenu
                    }
                  />
                </div>
              ) : null}

              <div className={scss.toolBodyTab} icon='tag' label='Labels'>
                <LayerLabel
                  className={scss.toolBodyTab}
                  key={'layerLabel'}
                  config={config}
                  mapStyle={mapStyle}
                  setDisplayManageAttributesMenu={
                    setDisplayManageAttributesMenu
                  }
                />
              </div>
            </Tabs>
          </div>
          <LayerFooter
            key='layerInfo'
            config={config}
            closeTool={closeTool}
            dataConfig={dataConfig}
          />
        </div>
      </>
    )
  }

  return config.collapsed ? getCollapsedUI() : getFullUI()
}

export default LayerSettingsV2
