import { useEffect, useCallback } from 'react'
import { FlyToInterpolator } from 'react-map-gl'
import { useDispatch, useSelector } from 'react-redux'
import { setViewPort } from '../../actions/index'

const ZoomToPoint = ({ coordinates, zoom = 14 }) => {
  const dispatch = useDispatch()
  const viewport = useSelector(state => state.viewport)

  const updateViewport = useCallback(() => {
    if (!viewport?.height || !viewport?.width || !coordinates) return
    
    const [longitude, latitude] = coordinates
    requestAnimationFrame(() => {
      dispatch(setViewPort({
        ...viewport,
        latitude,
        longitude,
        zoom,
        bearing: 0,
        pitch: 0,
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator(),
      }))
    })
  }, [viewport, coordinates, zoom, dispatch])

  useEffect(() => {
    updateViewport()
  }, [updateViewport])

  return null
}

export default ZoomToPoint
