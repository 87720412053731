import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import scss from './Button.scss'

const Button = ({
  value,
  onClick,
  active,
  leadingIcon,
  leadingIconSize,
  leadingPull,
  trailingIcon,
  trailingIconSize,
  trailingPull,
  buttonText,
  activateSpin,
  buttonType,
  title,
  stacked,
}) => {
  const renderLeadingIcon = () => {
    const faSize = leadingIconSize || 'xs'
    const faLeadingPull = leadingPull || null

    if (!leadingIcon) return null

    if (stacked) {
      return (
        <span className='fa-layers fa-fw'>
          <FontAwesomeIcon
            icon={leadingIcon[0]}
            size={faSize}
            pull={faLeadingPull}
            className={scss.faIcon}
            fixedWidth
          />
          <FontAwesomeIcon
            icon={leadingIcon[1]}
            size={faSize}
            pull={faLeadingPull}
            className={scss.faIcon}
            fixedWidth
          />
        </span>
      )
    }

    if (activateSpin === 'true') {
      return (
        <FontAwesomeIcon
          icon={leadingIcon}
          size={faSize}
          pull={faLeadingPull}
          className={scss.faIcon}
          fixedWidth
          spin
        />
      )
    }

    return (
      <FontAwesomeIcon
        icon={leadingIcon}
        size={faSize}
        pull={faLeadingPull}
        className={scss.faIcon}
        fixedWidth
      />
    )
  }

  const renderTrailingIcon = () => {
    if (!trailingIcon) return null

    const faSize = trailingIconSize || 'xs'
    const faTrailingPull = trailingPull || null

    return (
      <FontAwesomeIcon
        icon={trailingIcon}
        size={faSize}
        pull={faTrailingPull}
        className={scss.faIcon}
        fixedWidth
      />
    )
  }

  const faLeading = renderLeadingIcon()
  const faTrailing = renderTrailingIcon()

  return (
    <button
      value={value}
      onClick={onClick}
      className={[scss.button, scss[buttonType], scss[active]].join(' ')}
      title={title}
      type='button'
    >
      {faLeading && (
        <div className={scss['LeadingIcon']} value={value}>
          {' '}
          {faLeading}{' '}
        </div>
      )}
      {buttonText && <div className={scss['btnText']}> {buttonText} </div>}
      {faTrailing && (
        <div className={scss[buttonType + 'TrailingIcon']} value={value}>
          {' '}
          {faTrailing}{' '}
        </div>
      )}
    </button>
  )
}

export default Button
