/* eslint-disable react/button-has-type */
/* eslint-disable consistent-return */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { Tooltip } from 'react-tooltip'
import { fromJS } from 'immutable'
import { apiFetch } from '../../../../utilities/api'
import { apis } from '../../../../config/apiConfig'
import {
  setToolConfig,
  mountLayerSettings,
  updateMapStyle,
} from '../../../../actions/index'
import MakeDataConfigComponent, {
  getLayerConfigUsingTocId,
} from '../../../../utilities/dataConfig'

// utilities
import * as utils from '../../../../utilities/util'
import { processDefaultValue } from '../../../MapPopup/Utilities'

// components
import ConfirmDelete from '../ConfirmDelete/ConfirmDelete'

import EditAttribute from './EditAttribute'

// scss files
import scss from './Attributes.scss'
import appScss from '../../../App/App.scss'
import checkboxScss from '../../../CSSModules/Checkbox.scss'

class LayerAttributes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmDelete: false,
      deleteTarget: null,
      modalOpen: false,
      doingDelete: false,
      editing: null,
      targetProperty: null,
      layerProperties: null,
      addNewUi: false,
      addNewError: null,
      deleteUi: false,
      saving: null,
      error: null,
      mountMakeDataConfig: false,
    }
  }

  componentDidMount = () => this.getAttributes()

  componentDidUpdate = prevState => {
    if (this.state.deleteUi !== prevState.deleteUi) {
      this.getPropertyList()
    }
  }

  getAttributes = () => {
    const { config } = this.props
    const layerId = config.layer.layersArray[0].layer.id
    const method = 'POST'
    const url = apis.apiDatabase.uri + 'layer/properties/get'
    const bodyParams = {
      layerID: layerId,
    }
    apiFetch(url, method, bodyParams, result => {
      if (result.success) {
        this.setState({ layerProperties: result.data })
      } else {
        this.setState({ layerProperties: [] })
      }
    })
  }

  deleteAttribute = arr => {
    const { config } = this.props
    const layerId = config.layer.layersArray[0].layer.id
    const method = 'POST'
    const url = apis.apiDatabase.uri + 'layer/properties/delete'
    const bodyParams = {
      layerID: layerId,
      key: arr,
    }

    this.setState({ saving: true })
    apiFetch(url, method, bodyParams, result => {
      this.getAttributes()
      this.finishUpdate()
    })
  }

  finishUpdate = () => {
    this.setState(
      {
        editing: null,
        layerProperties: null,
        targetProperty: null,
        saving: false,
        addNewUi: false,
        deleteUi: false,
        deleteTarget: null,
        error: null,
        selectedOption: { label: 'Text', value: 'text' },
        mountMakeDataConfig: true,
      },
      () => {
        this.setState({ mountMakeDataConfig: false })
      }
    )
  }

  dataConfigUpdated = result => {
    const { config } = this.props
    if (!config.layer) return
    const updatedLayer = getLayerConfigUsingTocId(result, config.layer.toc.id)
    const sourceId = updatedLayer.layersArray[0].layer.source
    const newSource = updatedLayer.sourcesArray.filter(
      source => source.id === sourceId
    )
    const style = this.props.mapStyle.toJS()
    style.sources[sourceId] = newSource[0]
    this.props.updateMapStyle(fromJS(style))
  }

  cancelEdit = () => this.setState({ editing: null, error: null })

  cancelAddNew = () => this.setState({ addNewUi: false, error: null })

  deleteModal = () => this.setState({ deleteUi: true })

  cancelDelete = () => {
    this.setState({
      deleteUi: false,
      deleting: {},
    })
  }

  closeModal = () => this.setState({ modalOpen: false, deleteTarget: null })

  confirmDelete = () => {
    const { deleting } = this.state
    const keys = Object.keys(deleting)
    const arr = []

    if (keys.length) {
      keys.forEach(key => arr.push(key))
    }

    if (arr.length) {
      this.deleteAttribute(arr)
    }
  }

  handleDropDownListChange = e => {
    const { targetProperty } = this.state
    const type = e.target.name
    const { value } = e.target
    targetProperty[type] = value
    this.setState({ targetProperty })
  }

  getConfirmDelete = () => {
    const { modalOpen, saving, layerProperties, deleteTarget } = this.state
    if (!layerProperties) return
    const targetProperty = layerProperties.filter(
      layerProp => layerProp.key === deleteTarget
    )

    const targetLabel = targetProperty[0].name
    const title = 'Delete Property'
    const message = (
      <>
        <p>
          You are about to delete the property:
          <br /> {targetLabel}
        </p>
      </>
    )
    return (
      <ConfirmDelete
        modalOpen={modalOpen}
        closeModal={this.closeModal}
        doingFunction={saving}
        message={message}
        title={title}
        confirmFunction={this.deleteAttribute}
      />
    )
  }

  deletePropertyList = () => {
    this.setState({ editing: key, targetProperty: targetProperty[0] })
  }

  editProperty = key => {
    const { layerProperties } = this.state
    const targetProperty = layerProperties.filter(
      layerProp => layerProp.key === key
    )

    this.setState({ editing: key, targetProperty: targetProperty[0] })
  }

  addNewProperty = () => {
    const targetProperty = {
      name: '',
      default: '',
      type: 'text',
      value: [],
    }
    this.setState({ addNewUi: true, targetProperty })
  }

  handleCheckboxChange = e => {
    const { target } = e
    const value = target.checked
    const { name } = target
    const { deleting } = this.state

    if (deleting) {
      this.setState({
        deleting: {
          [name]: value,
          ...deleting,
        },
      })
    } else {
      this.setState({
        deleting: {
          [name]: value,
        },
      })
    }
  }

  getPropertyList = () => {
    const {
      layerProperties,
      editing,
      targetProperty,
      addNewUi,
      saving,
      deleteTarget,
      deleteUi,
    } = this.state
    if (!layerProperties) return
    const { user } = this.props
    const { canEdit } = this.props.config.layer.toc
    let list = null
    let editPanel = null
    // layerProperties.sort((a, b) => (a.name > b.name ? 1 : -1))

    if (editing) {
      editPanel = layerProperties.map(property => {
        if (property.key === editing) {
          console.log('PROP', targetProperty)
          return (
            <EditAttribute
              key={property.key}
              config={this.props.config}
              targetProperty={targetProperty}
              getDropDownOptionList={this.getDropDownOptionList}
              cancelEdit={this.cancelEdit}
              getProperties={this.getAttributes}
              finishUpdate={this.finishUpdate}
            />
          )
        }
      })
    } else if (layerProperties) {
      list = layerProperties.map((property, index) => {
        // if (property.type === 'document') return
        const defaultValue = property.default
        const defaultValueJSX = processDefaultValue(defaultValue, property.type)

        let fieldType = utils.unToSlug(property.type)
        if (property.type) {
          let newFieldTypes = [
            ...fieldTypes,
            {
              label: 'Document (Layer)',
              value: 'document (Layer)',
            },
            {
              label: 'Document (Feature)',
              value: 'document (Feature)',
            },
          ]

          let fieldTypeObj = utils.objectKeyVal(
            property.type,
            fieldTypes,
            'value'
          )

          fieldTypeObj = utils.objectKeyVal(
            property.type,
            newFieldTypes,
            'value'
          )

          if (fieldTypeObj) fieldType = fieldTypeObj.label
        }

        return (
          <div key={property.key} className={scss.layerPropertiesGridRow}>
            {deleteUi ? (
              <div
                className={[
                  scss.flexRow,
                  scss.flexCenter,
                  scss.layerPropertiesCheckbox,
                ].join(' ')}
              >
                {/* CUSTOM CHECKBOX BELOW */}
                <label className={[checkboxScss.checkboxContainer].join(' ')}>
                  <input
                    className={checkboxScss.checkbox}
                    name={property.key}
                    type='checkbox'
                    checked={this.state[property.key]}
                    onChange={this.handleCheckboxChange}
                  />
                  <span
                    className={[checkboxScss.checkboxMark].join(' ')}
                  ></span>
                </label>
                <span>{property.name}</span>
              </div>
            ) : (
              <span>{property.name}</span>
            )}
            <span className={scss.layerPropertiesDefault}>
              {property.type !== 'document' && defaultValue ? (
                <>
                  <FontAwesomeIcon
                    className={scss.layerPropertiesDefaultIcon}
                    icon='check'
                    data-tooltip-content
                    data-tooltip-id={'property-default-' + index}
                    data-effect='solid'
                    data-arrow-color='rgba(0, 0, 0, 0.68)'
                  />

                  <div className={appScss.reactToolTip}>
                    <Tooltip
                      id={'property-default-' + index}
                      getContent={content => defaultValueJSX}
                    />
                  </div>
                </>
              ) : (
                <FontAwesomeIcon
                  className={scss.layerPropertiesDefaultNone}
                  icon='times'
                />
              )}
            </span>
            <span>{fieldType}</span>
            <div className={[scss.flexRow, scss.flexCenter].join(' ')}>
              {canEdit && (
                <button
                  name={property.key}
                  className={scss.layerPropertiesEdit}
                  onClick={() => this.editProperty(property.key)}
                >
                  <FontAwesomeIcon icon='pencil' />
                </button>
              )}
            </div>
          </div>
        )
      })
    }

    const addBtn =
      addNewUi || !canEdit ? null : (
        <button
          className={[scss.layerPropertiesButton].join(' ')}
          onClick={saving ? null : this.addNewProperty}
        >
          {saving ? (
            <FontAwesomeIcon icon='spinner' size='1x' spin />
          ) : (
            <>
              <FontAwesomeIcon icon={['fal', 'plus']} size='1x' /> Add Attribute
            </>
          )}
        </button>
      )
    const deleteBtn =
      addNewUi || !canEdit ? null : (
        <button
          className={[scss.layerPropertiesButton].join(' ')}
          onClick={deleteUi ? null : this.deleteModal}
        >
          {deleteTarget ? (
            <FontAwesomeIcon icon='spinner' size='1x' spin />
          ) : (
            <>
              <FontAwesomeIcon icon={['fal', 'trash-alt']} size='1x' /> Delete
              Attributes
            </>
          )}
        </button>
      )

    return (
      <>
        {/* Tertiary for displaying Edit Panel or Property List */}
        {editPanel || (
          <div className={scss.layerPropertiesGrid}>
            <div className={scss.layerPropertiesGridHeader}>
              <span>Name</span>
              <span className={scss.layerPropertiesDefault}>Default</span>
              <span>Type</span>
            </div>

            <div className={scss.layerPropertiesGridBody}>{list}</div>

            <div
              className={[
                scss.flexRow,
                scss.flexCenter,
                scss.flexJustify,
                scss.layerPropertiesGridFooter,
              ].join(' ')}
            >
              {/* Tertiary for displaying Add and Delete buttons or Confirm Delete and Cancel buttons */}
              {deleteUi ? (
                <>
                  <button
                    className={[scss.layerPropertiesButton].join(' ')}
                    onClick={this.confirmDelete}
                  >
                    <FontAwesomeIcon icon={['fal', 'trash-alt']} size='1x' />{' '}
                    Confirm Delete
                  </button>
                  <button
                    className={[scss.layerPropertiesButton].join(' ')}
                    onClick={this.cancelDelete}
                  >
                    <FontAwesomeIcon icon={['fal', 'times']} size='1x' /> Cancel
                  </button>
                </>
              ) : (
                <>
                  <span>{addBtn}</span>
                  <span>{deleteBtn}</span>
                </>
              )}
            </div>
          </div>
        )}
      </>
    )
  }

  getLayerAttributes = () => {
    const { layerProperties } = this.state
    if (!layerProperties) {
      return (
        <SkeletonTheme color='#e4e5e7' highlightColor='#e8ebf2'>
          <div className={scss.layerPropertiesGridRow}>
            <span>
              <Skeleton height={14} count={1} />
            </span>
            <span>
              <Skeleton height={14} count={1} />
            </span>
            <span>
              <Skeleton height={14} count={1} />
            </span>
          </div>
        </SkeletonTheme>
      )
    }
    const propertyList = this.getPropertyList()

    return <>{propertyList}</>
  }

  getDropDownOptionList = () => {
    const { targetProperty } = this.state
    console.log('B', targetProperty)
    let { value } = targetProperty
    if (Array.isArray(value)) {
      value = targetProperty.value.join('\n')
    }
    return (
      <div className={scss.propertyDropdownList}>
        <div>Option List (One per line)</div>
        <textarea
          name='value'
          className={scss.dropDownTextArea}
          value={value}
          onChange={this.handleDropDownListChange}
          placeholder='Enter Dropdown Items (one per line)...'
        ></textarea>
      </div>
    )
  }

  render() {
    const { addNewUi, mountMakeDataConfig } = this.state

    return (
      <>
        {mountMakeDataConfig && (
          <MakeDataConfigComponent onFinish={this.dataConfigUpdated} />
        )}
        {addNewUi ? (
          <EditAttribute
            config={this.props.config}
            targetProperty={this.state.targetProperty}
            getDropDownOptionList={this.getDropDownOptionList}
            cancelEdit={this.cancelAddNew}
            getProperties={this.getAttributes}
            finishUpdate={this.finishUpdate}
          />
        ) : (
          this.getLayerAttributes()
        )}
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setToolConfig,
      mountLayerSettings,
      updateMapStyle,
    },
    dispatch
  )
}

function mapStateToProps(state) {
  return {
    user: state.user,
    toolConfig: state.toolConfig,
    mountKey: state.mountLayerSettings,
    mapStyle: state.mapStyle,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LayerAttributes)

export const fieldTypes = [
  { label: 'Text', value: 'text' },
  { label: 'Multiline Text', value: 'longtext' },
  { label: 'Number', value: 'number' },
  { label: 'Date', value: 'date' },
  { label: 'Dropdown', value: 'dropdown' },
  { label: 'Currency', value: 'currency' },
  { label: 'Document', value: 'document' },
]
