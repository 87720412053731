import React from 'react'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'react-tooltip'

import {
  getExpressionType,
  getZoomBasedValue,
  getDataBasedValue,
  getSwatchLabel,
} from './Utilities'
import { hexToRgba } from '../../../../utilities/formatters'
import scss from './LegendSwatch.scss'
import appScss from '../../../App/App.scss'

const FillSwatch = ({
  layer,
  outline,
  singleItemTitles,
  hideLayerHeader = null,
}) => {
  const viewport = useSelector(state => state.viewport)
  const dataConfig = useSelector(state => state.updateDataConfig)
  const getProperty = lineProp => {
    if (Array.isArray(lineProp)) {
      const expressionType = getExpressionType(lineProp)
      if (expressionType === 'zoomBased') {
        return getZoomBasedValue(lineProp, viewport)
      }
      if (expressionType === 'dataBased') {
        // console.log("lineProp ", lineProp)
        const temp = getDataBasedValue(lineProp)
        // console.log("TEMP ", temp)
        return temp
      }
    } else {
      return lineProp
    }
  }

  const getFillStyle = (outlineWidth, outlineColor, fillColor, fillOpacity) => {
    const BORDER_SCALING_FACTOR = 0.3 // this scales the border width to fit the legend
    const outlineRgbaColor = hexToRgba(outlineColor || '#ffffff', 1)
    if (!fillColor) {
      fillColor = '#ffffff'
      fillOpacity = 0
    }
    const fillRgbaColor = hexToRgba(fillColor, fillOpacity)
    const borderColor = outlineRgbaColor
    let borderWidth = outlineWidth || 1
    borderWidth *= BORDER_SCALING_FACTOR
    const border = `${borderWidth}px solid ${borderColor}`
    return {
      width: '30px',
      height: '18px',
      outline: border,
      backgroundColor: fillRgbaColor,
    }
  }

  const getFillSwatch = (
    outlineWidth,
    outlineColor,
    fillColor,
    fillOpacity,
    label
  ) => {
    let expressionValues = []
    const outlineWidthObject = {}
    const outlineColorObject = {}
    const fillColorObject = {}
    const fillOpacityObject = {}
    if (Array.isArray(outlineWidth)) {
      outlineWidth.forEach(arr => {
        outlineWidthObject[arr[0]] = arr[1]
      })
      const widthValues = outlineWidth.map(arr => arr[0])
      expressionValues = [...expressionValues, ...widthValues]
    }
    if (Array.isArray(outlineColor)) {
      outlineColor.forEach(arr => {
        outlineColorObject[arr[0]] = arr[1]
      })
      const colorValues = outlineColor.map(arr => arr[0])
      expressionValues = [...expressionValues, ...colorValues]
    }
    if (Array.isArray(fillColor)) {
      fillColor.forEach(arr => {
        fillColorObject[arr[0]] = arr[1]
      })
      const colorValues = fillColor.map(arr => arr[0])
      expressionValues = [...expressionValues, ...colorValues]
    }
    if (Array.isArray(fillOpacity)) {
      fillOpacity.forEach(arr => {
        fillOpacityObject[arr[0]] = arr[1]
      })
      const opacityValues = fillOpacity.map(arr => arr[0])
      expressionValues = [...expressionValues, ...opacityValues]
    }

    let uniqueExpressionValues = [...new Set(expressionValues)]

    // If the expression contains the default value and the list has more than one element,
    // we push the default element to the beginning of the list
    if (
      uniqueExpressionValues.includes('default') &&
      uniqueExpressionValues.length &&
      uniqueExpressionValues.length > 1
    ) {
      const currentDefaultIndex = uniqueExpressionValues.indexOf('default')

      const arrayMove = (arr, oldIndex, newIndex) => {
        if (newIndex >= arr.length) {
          let k = newIndex - arr.length + 1
          // eslint-disable-next-line no-plusplus
          while (k--) {
            arr.push(undefined)
          }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
        return arr
      }

      uniqueExpressionValues = arrayMove(
        uniqueExpressionValues,
        currentDefaultIndex,
        0
      )
    }

    const swatchItems = []
    if (uniqueExpressionValues.length) {
      // the legend contains databased style
      const items = uniqueExpressionValues.map(value => {
        const itemOutlineWidth =
          outlineWidthObject[value] ||
          outlineWidthObject.default ||
          outlineWidth
        const itemOutlineColor =
          outlineColorObject[value] ||
          outlineColorObject.default ||
          outlineColor
        const itemFillColor =
          fillColorObject[value] || fillColorObject.default || fillColor
        const itemFillOpacity =
          fillOpacityObject[value] || fillOpacityObject.default || fillOpacity

        const style = getFillStyle(
          itemOutlineWidth,
          itemOutlineColor,
          itemFillColor,
          itemFillOpacity
        )
        return (
          <li key={value}>
            <div className={scss.expressionItem}>
              <div>{value === 'default' ? 'Default' : value}</div>
              {itemFillOpacity !== 'default' &&
              itemFillOpacity < 0.15 &&
              !itemOutlineColor ? (
                <>
                  <div className={scss.expressionItemCaution}>
                    <FontAwesomeIcon
                      icon='exclamation-triangle'
                      size='1x'
                      data-tooltip-content={
                        'Fill is below 15% opacity, style may not be visible'
                      }
                      data-tooltip-id='opacity-warning'
                      data-effect='solid'
                      data-arrow-color='rgba(0, 0, 0, 0.68)'
                    />
                    <div style={style}></div>
                    <div className={appScss.reactToolTip}>
                      <Tooltip
                        id='opacity-warning'
                        getContent={dataTip => `${dataTip}`}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div style={style}></div>
              )}
            </div>
          </li>
        )
      })
      swatchItems.push(
        <ul key={uniqueExpressionValues.join(',')} className={scss.swatchList}>
          {hideLayerHeader ? null : (
            <div className={scss.expressionItemTitle}>
              <span>{label}</span>
              <span>Style</span>
            </div>
          )}
          {items}
        </ul>
      )
    } else {
      const style = getFillStyle(
        outlineWidth,
        outlineColor,
        fillColor,
        fillOpacity
      )

      let defaultOpacity = 1
      if ((!fillOpacity || fillOpacity < 0.15) && !fillColor && !outlineColor)
        defaultOpacity = undefined

      if (singleItemTitles) {
        swatchItems.push(
          <ul key={layer.id + '_legendItem'} className={scss.swatchList}>
            {/* <div className={scss.expressionItemTitle}><span>Attribute</span><span>Style</span></div> */}
            <li>
              <div className={scss.expressionItem}>
                <div> {singleItemTitles && label} </div>
                {!defaultOpacity ? (
                  <>
                    <div className={scss.expressionItemCaution}>
                      <FontAwesomeIcon
                        icon='exclamation-triangle'
                        size='1x'
                        data-tooltip-content='Fill is below 15% opacity, style may not be visible'
                        data-tooltip-id='opacity-warning'
                        data-effect='solid'
                        data-arrow-color='rgba(0, 0, 0, 0.68)'
                      />
                      <div style={style}></div>
                      {/* <div className={appScss.reactToolTip}>
                        <Tooltip id="opacity-warning" getContent={(dataTip) => `${dataTip}`} />
                      </div> */}
                    </div>
                  </>
                ) : (
                  <div style={style}></div>
                )}
              </div>
            </li>
          </ul>
        )
      } else {
        swatchItems.push(
          <ul key={layer.id + '_legendItem'} className={scss.swatchList}>
            <li>
              <div className={scss.expressionItem}>
                {/* <div>Standard Style</div> */}
                <div>{label}</div>
                {!defaultOpacity ? (
                  <>
                    <div className={scss.expressionItemCaution}>
                      <FontAwesomeIcon
                        icon='exclamation-triangle'
                        size='1x'
                        data-tooltip-content='Fill is below 15% opacity, style may not be visible'
                        data-tooltip-id='opacity-warning'
                        data-effect='solid'
                        data-arrow-color='rgba(0, 0, 0, 0.68)'
                      />
                      <div style={style}></div>
                      <div className={appScss.reactToolTip}>
                        <Tooltip
                          id='opacity-warning'
                          getContent={dataTip => `${dataTip}`}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div style={style}></div>
                )}
              </div>
            </li>
          </ul>
        )
      }
    }

    return swatchItems
  }
  const label = getSwatchLabel(layer, dataConfig)

  const outlineWidth = getProperty(outline.paint['line-width'])
  const outlineColor = getProperty(outline.paint['line-color'])
  const fillColor = getProperty(layer.paint['fill-color'])
  const fillOpacity = getProperty(layer.paint['fill-opacity'])

  const swatch = getFillSwatch(
    outlineWidth,
    outlineColor,
    fillColor,
    fillOpacity,
    label
  )

  return <>{swatch}</>
}

export default FillSwatch
