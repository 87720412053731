import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import ZoomToBounds from '../../../../components/ZoomToBounds/ZoomToBounds'
import {
  createPointFeature,
  addFeatureToSource,
  getSource,
} from '../../../../utilities/mapStyle'
import { updateMapStyle } from '../../../../actions/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import scss from './GeoCode.scss'
import { fromJS } from 'immutable'

const GeoCode = ({
  mapStyle,
  viewport,
  updateMapStyle,
  enableMap,
  disableMap,
}) => {
  const [address, setAddress] = useState('')
  const [searching, setSearching] = useState(false)
  const [zoomToBoundsKey, setZoomToBoundsKey] = useState('bounds')
  const [zoomToBounds, setZoomToBounds] = useState(null)
  const [result, setResult] = useState(null)
  const [zoomToIndex, setZoomToIndex] = useState(0)

  const handleChange = useCallback(address => {
    setAddress(address)
  }, [])

  const handleSelect = useCallback(
    address => {
      enableMap()
      setAddress(address)
      setSearching(true)
      geocodeByAddress(address)
        .then(results => setResultToState(results))
        .then(results => doZoomToBounds(results))
        .then(results => getLatLng(results[0]))
        .then(latLng => updateGeoCodeLayer(latLng))
        .then(() => setSearching(false))
        .then(() => enableMap())
        .catch(error => console.error('Error', error))
    },
    [enableMap]
  )

  const setResultToState = useCallback(results => {
    setResult(results[0])
    return results
  }, [])

  const doZoomToBounds = useCallback(results => {
    let bounds = null
    if (results[0].geometry.bounds) {
      bounds = formatBounds(results[0].geometry.bounds)
    }
    if (results[0].geometry.viewport) {
      bounds = formatBounds(results[0].geometry.viewport)
    }

    setZoomToIndex(prevIndex => {
      const newIndex = prevIndex + 1
      const newZoomToBoundsKey = JSON.stringify(bounds) + newIndex
      setZoomToBoundsKey(newZoomToBoundsKey)
      setZoomToBounds(bounds)
      return newIndex
    })

    return results
  }, [])

  const formatBounds = useCallback(bounds => {
    let formatted = []
    let lats = []
    let lngs = []
    for (let key in bounds) {
      if (bounds.hasOwnProperty(key)) {
        Object.values(bounds[key]).forEach(value => {
          if (lats.length < 2) {
            lats.push(value)
          } else {
            lngs.push(value)
          }
        })
      }
    }
    let northEast = [
      parseFloat(lngs[0].toFixed(6)),
      parseFloat(lats[0].toFixed(6)),
    ]
    let southWest = [
      parseFloat(lngs[1].toFixed(6)),
      parseFloat(lats[1].toFixed(6)),
    ]
    formatted.push(northEast)
    formatted.push(southWest)
    return formatted
  }, [])

  const updateGeoCodeLayer = useCallback(
    latLng => {
      let style = mapStyle.toJS()
      let source = getSource(style, 'geocode_results')
      const lng = latLng.lng
      const lat = latLng.lat
      const coordinates = [lng, lat]
      const properties = {
        id: result.place_id,
        location: result.formatted_address,
      }

      let feature = createPointFeature(coordinates, properties)
      // check if feature exists in source, if it does dont add the feature (this would result in douplicates)
      let check = source.data.features.filter(
        sourceFeature => sourceFeature.properties.id === feature.properties.id
      )
      if (!check.length) {
        source = addFeatureToSource(source, feature)
        style.sources['geocode_results'] = source
        updateMapStyle(fromJS(style))
      }
    },
    [mapStyle, result, updateMapStyle]
  )

  const inputStyle = {
    border: 'none',
    outline: 'none',
    borderRadius: '4px',
    width: '100%',
    height: '40px',
    padding: '0 12px',
    fontSize: '16px',
    letterSpacing: '-0.43px',
    color: '#fff',
    textAlign: 'left',
  }

  const searchIcon = searching ? (
    <FontAwesomeIcon icon={'spinner'} size='1x' spin />
  ) : (
    <FontAwesomeIcon icon={['fas', 'search-location']} size='1x' />
  )

  return (
    <div>
      <ZoomToBounds
        key={zoomToBoundsKey}
        viewport={viewport}
        bounds={zoomToBounds}
      />
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className={scss.geoCodeWrapper}>
            <div className={scss.addOnGroup}>
              <input
                style={inputStyle}
                {...getInputProps({
                  placeholder: 'Enter address...',
                  className: 'location-search-input',
                })}
              />
              <button className={scss.geocodeBtn}> {searchIcon}</button>
            </div>

            <div
              className='autocomplete-dropdown-container'
              onMouseEnter={disableMap}
              onMouseLeave={enableMap}
            >
              {loading && (
                <div className={scss.autoCompleteLoading}>Loading...</div>
              )}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item'
                const style = suggestion.active
                  ? {
                      backgroundColor: '#fafafa',
                      cursor: 'pointer',
                      padding: '2px',
                    }
                  : {
                      backgroundColor: '#ffffff',
                      cursor: 'pointer',
                      padding: '2px',
                    }
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span className={scss.suggestionSpan}>
                      <FontAwesomeIcon icon={['fal', 'map-marker']} size='1x' />
                      {suggestion.description}
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    mapStyle: state.mapStyle,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMapStyle: updateMapStyle,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(GeoCode)
