import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Switch from '@material-ui/core/Switch'
import scss from './LegendBtn.scss'
import { setToolConfig } from '../../../actions/index'
import { clone } from '../../../utilities/geospatial'
import { useDispatch, useSelector } from 'react-redux'

export default function LegendBtn() {
  const dispatch = useDispatch()
  const toolConfig = useSelector(state => state.toolConfig)
  const [isActiveLegend, setIsActiveLegend] = useState(false)
  const handleLegend = () => {
    toggleTool('Legend')
    setIsActiveLegend(!isActiveLegend)
  }

  const toggleTool = name => {
    let config = clone(toolConfig)
    config.forEach(tool => {
      if (tool.name === name) {
        tool.visible = !tool.visible
      }
    })
    dispatch(setToolConfig(config))
  }

  // set switch in case the toggle
  // is closed by closing the window
  useEffect(() => {
    const config = clone(toolConfig)
    config.forEach(tool => {
      if (tool.name === 'Legend') setIsActiveLegend(tool.visible)
    })
  })

  return (
    <button
      onClick={() => handleLegend()}
      className={scss.legendBtn}
      title='Legend'
    >
      <FontAwesomeIcon icon='list-alt' />
      <Switch
        checked={isActiveLegend}
        name='legendStatus'
        inputProps={{
          'aria-label': 'checkbox with default color',
        }}
      />
    </button>
  )
}
