import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setToolConfig } from '../../../../actions/index'
import { clone } from '../../../../utilities/geospatial'
import scss from '../../Toc.scss'

const LayerSettingsButton = ({ layerIds, layer }) => {
  const dispatch = useDispatch()
  const toolConfig = useSelector(state => state.toolConfig)

  const toggleLayerSettings = layerObj => {
    const config = clone(toolConfig)
    config.forEach(tool => {
      if (tool.group) tool.group = null
      if (tool.name === 'LayerSettings') {
        if (!tool.layer) {
          // turn layerSettings on
          tool.visible = true
          tool.layer = layerObj
          return dispatch(setToolConfig(config))
        }
        if (tool.layer && tool.layer.toc.id === layerObj.toc.id) {
          // turn layer settings off
          tool.visible = false
          tool.layer = null
          return dispatch(setToolConfig(config))
        }
        if (tool.layer && tool.layer.toc.id !== layerObj.toc.id) {
          // turn layer settings off, then
          // switch layer settings target layer
          tool.visible = false
          tool.layer = null
          dispatch(setToolConfig(config))
        }
      }
      return null
    })
  }

  let settingsActive = ''
  if (toolConfig) {
    toolConfig.forEach(tool => {
      if (tool.name === 'LayerSettings') {
        if (tool.layer && tool.layer.toc.id === layer.toc.id) {
          settingsActive = 'active'
        }
      }
    })
  }

  return (
    <button
      type='button'
      value={layerIds + '_settings'}
      onClick={() => toggleLayerSettings(layer)}
      // onDoubleClick={() => tocBtnDoubleClick(bounds)}
      className={[scss.hoverBtn, scss[settingsActive]].join(' ')}
      style={{ margin: '0.2px 0px' }}
      data-tooltip-id={layerIds.join(',')}
      data-tooltip-content={layer.toc.label}
    >
      <FontAwesomeIcon icon='cog' size='xs' />
    </button>
  )
}

LayerSettingsButton.propTypes = {
  layerIds: PropTypes.arrayOf(PropTypes.string),
  layer: PropTypes.shape({
    toc: PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
    }),
  }).isRequired,
}

export default LayerSettingsButton
