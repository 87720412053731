import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setOverPanel,
  setScrollZoom,
  setDragPan,
  setDoubleClickZoom,
} from '../../../../actions'
import ReactSelect from '../../../../components/ReactSelect/ReactSelect'
import scss from './TargetLayerSelect.scss'
import UpdateMapStyle from './UpdateMapStyle'

const TargetLayerSelect = React.memo(
  ({ targetLayer, setTargetLayer, saveCount }) => {
    const dispatch = useDispatch()
    const toolConfig = useSelector(state => state.toolConfig)
    const dataConfig = useSelector(state => state.updateDataConfig)
    const targetLayers = []

    const processTocObject = tocObject => {
      if (tocObject && tocObject.toc) {
        if (tocObject.toc.userLayer && tocObject.layersArray) {
          targetLayers.push({
            label: tocObject.toc.label,
            value: tocObject.layersArray[0].layer.id,
            outline: tocObject.layersArray[0].outline
              ? tocObject.layersArray[0].outline.id
              : null,
            type: tocObject.layersArray[0].layer.type,
            paint: tocObject.layersArray[0].layer.paint,
            sources: tocObject.sourcesArray,
          })
        }
      } else {
        tocObject.children.items.forEach(tocObject => {
          processTocObject(tocObject)
        })
      }
    }

    dataConfig['tocLayers'].forEach(tocObject => {
      processTocObject(tocObject)
    })

    const handleChange = useCallback(obj => {
      setTargetLayer(obj)
      /*
     NOTE: 
     TargetLayerSelect is set up so whenever a user makes a selection
     his mouse is off of the tool.  Thus we need to re engage the map using 
     the below redux calls
    */
      dispatch(setOverPanel(false))
      dispatch(setScrollZoom(true))
      dispatch(setDragPan(true))
      dispatch(setDoubleClickZoom(true))
    })

    useEffect(() => {
      toolConfig
        .filter(tool => tool.name === 'Draw')
        .forEach(tool => {
          if (tool.targetLayer) {
            const targetlayerObj = {
              label: tool.targetLayer.toc.label,
              value: tool.targetLayer.layersArray[0].layer.id,
              outline: tool.targetLayer.layersArray[0].outline
                ? tool.targetLayer.layersArray[0].outline.id
                : null,
              type: tool.targetLayer.layersArray[0].layer.type,
              paint: tool.targetLayer.layersArray[0].layer.paint,
              sources: tool.targetLayer.sourcesArray,
            }
            setTargetLayer(targetlayerObj)
          }
        })
    }, [])

    return (
      <div className={scss.select}>
        <ReactSelect
          style={{ borderRadius: targetLayer ? '4px' : '0px' }}
          options={targetLayers}
          onChange={(values, obj) => handleChange(obj)}
          defaultValue={targetLayer}
          isFixed
          placeholder={'Select Draw Layer'}
        />
        {targetLayer && (
          <UpdateMapStyle
            key={`${targetLayer.value}_${saveCount}`}
            targetLayer={targetLayer}
          />
        )}
      </div>
    )
  }
)

export default TargetLayerSelect

/*
sources: tocObject.sourcesArray.map( source => {
  if(!source.id){
    source.id = tocObject.layersArray[0].layer.source
  } 
  return source
})

sources:  tool.targetLayer.sourcesArray.map( source => {
  if(!source.id){
    source.id = tool.targetLayer.layersArray[0].layer.source
  }
  return source
})
*/
