import React, { useState, useEffect } from 'react'
import { Tooltip } from 'react-tooltip'
import scss from './StyleInput.scss'
import appScss from '../../../../App/App.scss'

const NumberInput = (
  type,
  callback,
  id = 'fill',
  inputValue,
  min = 0,
  max = 100,
  step = 1
) => {
  const [value, setValue] = useState(inputValue)

  const sendToLayerStyle = changedVal => {
    let endValue
    if (type === 'opacity') {
      endValue = parseFloat(changedVal / 100)
    } else {
      endValue = parseFloat(changedVal)
    }

    callback(id, endValue, type)
  }

  const handleInputChange = e => {
    if (e.target.value === '') setValue('')
    else if (
      e.target.value !== null &&
      e.target.value !== 'undefined' &&
      e.target.value !== ''
    ) {
      if (e.target.value > max || e.target.value < min) return
      setValue(e.target.value)
    }
  }

  useEffect(() => {
    if (value !== '') sendToLayerStyle(value)
  }, [value])

  const input = (
    <div className={[scss.stylesColumn, scss.stylesColumnItem].join(' ')}>
      <input
        className={scss.numberSelector}
        type='number'
        value={value}
        onChange={handleInputChange}
        step={step}
        min={min}
        max={max}
        data-tooltip-content={type}
        data-tooltip-id='numberInput'
        data-effect='solid'
        data-arrow-color='rgba(0, 0, 0, 0)'
        placeholder={min + ' - ' + max}
      />
      <div
        className={[appScss.reactToolTip, appScss.reactToolTipFont].join(' ')}
      >
        <Tooltip id='numberInput' getContent={dataTip => `${dataTip}`} />
      </div>
    </div>
  )

  return input
}

export default NumberInput
